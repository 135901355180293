import React, { useEffect, useState } from "react";
import Input from "components/Input";
import Select from "components/Select";
import LoadingPopover from "components/LoadingPopover";
import TeamIcon from "@material-ui/icons/PeopleAltOutlined";
import { colors, phoneWidth } from "styles";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import { CentralWrapper, ExternalWrapper } from "components/Wrappers";
import { Button, Checkbox, Typography, useMediaQuery } from "@material-ui/core";
import { getUsersTeams } from "./__generated__/getUsersTeams";
import {
  CreateMeeting as CreateMeetingType,
  CreateMeetingVariables,
} from "./__generated__/CreateMeeting";
import { useAlert } from "../components/Alert";
import { UsersInvite } from "../components/UserSearch";
import { currentUser } from "../App";
import firebase from "../firebase";

const language = [
  { label: "English", value: "en" },
  { label: "Swedish", value: "sv" },
];
const commonStyle = { width: 300, marginTop: 40 };

const query = gql`
  query getUsersTeams {
    teams: getUsersTeams {
      Id
      Name
    }
  }
`;

const mutation = gql`
  mutation CreateMeeting(
    $name: String!
    $participants: [String!]!
    $timezone: String!
    $scheduleLater: Boolean!
    $teams: [String!]!
  ) {
    createMeeting(
      name: $name
      participants: $participants
      scheduleLater: $scheduleLater
      timezone: $timezone
      teams: $teams
    )
  }
`;

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: any;
  children: React.ReactNode;
}) =>
  condition ? (
    wrapper(children)
  ) : (
    <div style={{ width: "100%", overflow: "hidden" }}>{children}</div>
  );

// TODO: Add so people can add teams and get participant emails and pending emails from those teams and add them to the thingy
function CreateMeeting() {
  useEffect(
    () =>
      firebase
        .analytics()
        .logEvent("page_view", { page_title: "Create Meeting" }),
    []
  );
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [participants, setParticipants] = useState<string[]>([]);
  const phone = useMediaQuery(phoneWidth);
  const { data, loading, error } = useQuery<getUsersTeams>(query);
  const history = useHistory();
  const alert = useAlert();

  const [
    createMeeting,
    { data: mutationData, loading: mutationLoading },
  ] = useMutation<CreateMeetingType>(mutation);

  const teams = [...(data?.teams ?? []), { Name: "No Team", Id: "NA" }];

  if (mutationData != null) {
    if (schedule) {
      history.push(`/scheduled-meeting/${mutationData.createMeeting}`);
    } else {
      history.push(`/live/${mutationData.createMeeting}`);
    }
  }

  if (error != null) {
    alert({
      title: "Error giving feedback",
      message: `There was a error when giving feedback \n ${error}`,
    });
  }

  if (error) return <div>{error.message}</div>;

  return (
    <ExternalWrapper>
      <LoadingPopover
        open={mutationLoading}
        title="Creating Meeting"
        description="Please wait while we are creating your meeting"
      />
      <ConditionalWrapper
        condition={!phone}
        wrapper={(children: any) => (
          <div style={{ height: 400, marginTop: 20 }}>
            <CentralWrapper style={{ width: 400 }}>{children}</CentralWrapper>
          </div>
        )}
      >
        <Typography
          style={{
            marginTop: 10,
            textAlign: "center",
            fontWeight: 450,
            color: colors.primary.dark,
          }}
        >
          Meeting Details
        </Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Input
            style={commonStyle}
            required
            label="Name"
            error={name.length > 50 || (shortName && name.length < 3)}
            placeholder="Name of meeting"
            autoFocus={true}
            helperText={
              name.length > 50
                ? "Meeting name needs to be below 50 characters"
                : name.length < 3
                ? "Meeting name needs to be above 3 characters"
                : ""
            }
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <div style={commonStyle}>
            <UsersInvite
              label="Add Participants"
              variant="standard"
              placeholder="Type email or type to search and press Enter or Space to add."
              chosenUserEmails={[currentUser.email ?? ""]}
              onChange={setParticipants}
              selectedEmails={participants}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 320,
              marginTop: 20,
            }}
          >
            <Checkbox
              checked={schedule}
              onChange={() => setSchedule(!schedule)}
              color="primary"
            />
            <div style={{ marginTop: 20 }}>
              <Typography variant="subtitle1">Schedule For Later</Typography>
              <Typography variant="caption">
                Allows you to create a meeting for later instead of starting one
                directly.
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: 40,
          }}
        >
          <Button
            color="default"
            variant="contained"
            disabled={mutationLoading}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={mutationLoading}
            onClick={() => {
              if (name.length < 3 || name.length > 50) {
                setShortName(true);
                return;
              }
              const variables: CreateMeetingVariables = {
                name,
                participants,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                scheduleLater: schedule,
                teams: [],
              };
              createMeeting({ variables });
              if (participants.length > 0)
                firebase.analytics().logEvent("invited_users", {
                  count: participants.length,
                  page_title: "Create Meeting",
                });
              firebase.analytics().logEvent("create_meeting");
            }}
          >
            {schedule ? "Create Meeting" : "Start Meeting"}
          </Button>
        </div>
      </ConditionalWrapper>
    </ExternalWrapper>
  );
}

export default CreateMeeting;
