export function getUrl(): string {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000";
  }
  return `https://${
    process.env.REACT_APP_PROJECT_ID === "stage-allting"
      ? "stage-allting"
      : "prod-allting"
  }.appspot.com`;
}

export function getFrontEndUrl(): string {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  }
  if (process.env.REACT_APP_PROJECT_ID === "stage-allting")
    return "https://stage-allting.firebaseapp.com";
  return "https://alltinglabs.com";
}

export const getProjectId = () =>
  process.env.REACT_APP_PROJECT_ID === "stage-allting"
    ? "stage-allting"
    : "prod-allting";

export const accountLevel = {
  ANONYMOUS: 0,
  FREE: 1,
  BASIC: 2,
  ADMIN: 10,
};
