import React from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { IconButton } from "@material-ui/core";
import { LiveItem } from "./LiveItem";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import { colors, phoneWidth } from "styles";
import {
  EnergyVoteMutation,
  EnergyVoteMutationVariables,
} from "./__generated__/EnergyVoteMutation";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { currentContext } from "../../App";

const energyVoteMutation = gql`
  mutation EnergyVoteMutation(
    $meetingId: ID!
    $energy: Int!
    $timestamp: Float!
  ) {
    updateEnergyLevel(
      meetingId: $meetingId
      energy: $energy
      timestamp: $timestamp
    ) {
      Id
      EnergyLevels {
        Id
        Energy
        Timestamp
      }
    }
  }
`;

export default function EnergyVote({
  onUpdate,
  meetingId,
}: {
  onUpdate: () => void;
  meetingId: string;
}) {
  const [energyUpdate, { data, loading }] = useMutation<
    EnergyVoteMutation,
    EnergyVoteMutationVariables
  >(energyVoteMutation);
  const smallScreen = useMediaQuery(phoneWidth);
  const iconStyle = {
    fontSize: smallScreen ? (currentContext === "teams" ? 28 : 35) : 41,
    color: colors.darkMode.text,
  };

  async function update(energy: number) {
    onUpdate();
    await energyUpdate({
      variables: {
        energy,
        meetingId,
        timestamp: new Date().getTime(),
      },
    });
  }

  return (
    <LiveItem title="What is your energy level?" time={new Date().getTime()}>
      <div
        style={{
          width: "100%",
          maxWidth: 400,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={() => update(1)}>
          <MoodBadIcon style={iconStyle} />
        </IconButton>
        <IconButton onClick={() => update(2)}>
          <SentimentVeryDissatisfiedIcon style={iconStyle} />
        </IconButton>
        <IconButton onClick={() => update(3)}>
          <SentimentSatisfiedIcon style={iconStyle} />
        </IconButton>
        <IconButton onClick={() => update(4)}>
          <SentimentSatisfiedAltIcon style={iconStyle} />
        </IconButton>
        <IconButton onClick={() => update(5)}>
          <MoodIcon style={iconStyle} />
        </IconButton>
      </div>
    </LiveItem>
  );
}
