import React from "react";
import styled from "styled-components";
import { colors } from "styles";
import { animated, useSpring } from "react-spring";

const color = colors.primary.main;

type Variant = "tiny" | "small" | "normal";
interface Props {
  progress: number;
  variant?: Variant;
  dynamicColor?: boolean;
  style?: any;
}

const ProgressBar = styled.div<Props>`
  width: ${(props) => getVariantWidth(props.variant)}px;
  height: ${(props) => getVariantHeight(props.variant)}px;
  border-radius: 10px;
  border: 1px solid ${(props) => getColor(props.progress, props.dynamicColor)};
  background: linear-gradient(
    to right,
    ${(props) => getColor(props.progress, props.dynamicColor)},
    ${(props) => getColor(props.progress, props.dynamicColor)}
      ${(props) => props.progress}%,
    transparent ${(props) => props.progress}%,
    transparent 100%
  );
`;

function getColor(progress: number, dynamicColor?: boolean): string {
  if (!dynamicColor) {
    return colors.primary.main;
  }
  if (progress <= 25) {
    return colors.error.main;
  } else if (progress <= 50) {
    return colors.warning.main;
  } else if (progress <= 75) {
    return colors.info.main;
  } else {
    return colors.success.main;
  }
}

function getVariantHeight(variant?: Variant): number {
  switch (variant) {
    case "small":
      return 8;
    case "tiny":
      return 4;
    default:
      return 13;
  }
}

function getVariantWidth(variant?: Variant): number {
  switch (variant) {
    case "small":
      return 50;
    case "tiny":
      return 22;
    default:
      return 100;
  }
}

export default ProgressBar;
