import React from "react";

export type StackedData = {
  name: string;
  value: number;
  id: string | number;
  color: string;
};

export default function StackedBar({ data }: { data: StackedData[] }) {
  const totalCount = data.reduce(
    (sum, currentValue) => (sum += currentValue.value ?? 0),
    0
  );
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        borderRadius: 5,
        overflow: "hidden",
      }}
    >
      {data.map((v) => (
        <div
          key={v.id}
          style={{
            height: "100%",
            backgroundColor: v.color,
            width: `${((v.value ?? 0) / (totalCount ?? 1)) * 100}%`,
          }}
        />
      ))}
    </div>
  );
}
