import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import React from "react";

export function BarGraph({
  data,
}: {
  data: { name: string; value: number; id: string | number }[];
}) {
  return (
    <ResponsiveContainer width={"100%"} height={150}>
      <BarChart data={data}>
        <Bar dataKey="value" fill="white" />
        <XAxis
          dataKey="id"
          axisLine={{ stroke: "white" }}
          tickLine={{ stroke: "white" }}
          tick={{ fill: "white" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export type PieData = {
  name: string;
  value: number;
  id: string | number;
  color: string;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  value,
}: any) => {
  let radius = innerRadius - 5 + (outerRadius - innerRadius) * 0.5;
  if (percent === 1) radius = radius - 10;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percent > 0 && `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const renderCustomizedValueLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
  percent,
  value,
}: any) => {
  let radius = innerRadius - 5 + (outerRadius - innerRadius) * 0.5;
  if (percent === 1) radius = radius - 10;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fontSize={14}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percent > 0 && value}
    </text>
  );
};

export function PieGraph({
  data,
  showLegend,
  height,
  customisedLabel,
}: {
  data: PieData[];
  showLegend?: boolean;
  height?: number;
  customisedLabel?: any;
}) {
  return (
    <ResponsiveContainer width={"100%"} height={height ?? 170}>
      <PieChart>
        <Pie
          animationDuration={600}
          animationBegin={0}
          dataKey="value"
          nameKey="name"
          data={data}
          outerRadius={(height ?? 170) / 3}
          label={customisedLabel ?? renderCustomizedLabel}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {showLegend && (
          <Legend
            verticalAlign="bottom"
            iconType="circle"
            align="center"
            content={(val) => {
              return (
                <div
                  style={{
                    color: "inherit",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {val?.payload?.map((p) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        paddingTop: 15,
                      }}
                    >
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          backgroundColor: p.color,
                          marginLeft: 10,
                          marginRight: 5,
                        }}
                      />
                      <span>{p.value}</span>
                    </div>
                  ))}
                </div>
              );
            }}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}
