import gql from "graphql-tag";

export const meetingsQuery = gql`
  query MeetingsQuery($completed: Boolean) {
    meeting: getMeetings(completed: $completed) {
      Id
      Length
      Name
      Date
      StartTime
      Status
      Tags
      ProcessingProgress
      AvgOverallScore
      Participants {
        Id
      }
      ExternalParticipants
      Team {
        Id
        Name
      }
      Speakers {
        Tag
      }
    }
  }
`;
