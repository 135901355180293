import { gql } from "apollo-boost";

export const meetingFragment = gql`
  fragment MeetingFragment on Meeting {
    Id
    Summary
    Keywords
    Speakers {
      User {
        Id
        UserName
        ImageUrl
      }
      Name
      WordsSpoken
      Tag
    }
    Participants {
      Id
      UserName
      ImageUrl
      Email
    }
    FeedbackSeries {
      Deviation
      QuestionId
      CurrentVal
      Values
      Min
      Max
    }
    Length
    Name
    Team {
      Id
      Name
    }
    Date
  }
`;

export const meetingQuery = gql`
  query MeetingQuery($id: ID!) {
    meeting: getMeeting(id: $id) {
      Id
      ...MeetingFragment
      FileUploaded
      ExternalParticipants
      PendingUsers
      StartTime
      EndTime
      EnergyLevels {
        Id
        Timestamp
        Energy
        UserId
      }
      LiveFeed {
        Flag
        Id
        Time
        Title
        VoteOptions {
          Label
          Value
        }
        VoteResults
      }
    }
  }
  ${meetingFragment}
`;

export const sectionQuery = gql`
  query SectionQuery($meetingId: ID!, $cursor: Int!) {
    paginatedSection: getSection(meetingId: $meetingId, cursor: $cursor) {
      cursor
      offset
      Sections {
        Id
        SpeakerTag
        Words {
          StartTime
          Word
        }
      }
    }
  }
`;

export const speakersQuery = gql`
  query SpeakersQuery($id: ID!) {
    meeting: getMeeting(id: $id) {
      Id
      Name
      Summary
      Speakers {
        User {
          Id
          ImageUrl
          UserName
        }
        Name
        Tag
        WordsSpoken
      }
    }
  }
`;

export const participantsQuery = gql`
  query ParticipantsQuery($id: ID!) {
    meeting: getMeeting(id: $id) {
      Id
      Speakers {
        User {
          Id
          ImageUrl
          UserName
        }
      }
    }
  }
`;

export const usersQuery = gql`
  query usersQuery($ids: [ID!]!) {
    getUsers(ids: $ids) {
      Id
      UserName
      ImageUrl
    }
  }
`;
export const postMeetingQuery = gql`
  query PostMeetingQuery($id: ID!) {
    postMeeting(id: $id) {
      Id
      StartTime
      FileUploaded
      ProcessingProgress
      EndTime
      Length
      Name
      FeedbackGiven
      Status
      Summary
      NumParticipants
      SpeakerDistribution {
        Name
        Tag
        WordsSpoken
        User {
          Id
          UserName
          ImageUrl
        }
      }
      Questions {
        Id
        Scores
        Name
      }
      Participants {
        Id
        Email
        ImageUrl
        UserName
      }
      EngagementScore {
        Id
        Score
        SubScores {
          Id
          Name
          Score
        }
      }
      ProductivityScore {
        Id
        Score
        SubScores {
          Id
          Name
          Score
        }
      }
      OverallScore {
        Scores
      }
      Keywords
      EnergyLevels {
        Id
        Energy
        Timestamp
        UserId
      }
      LiveFeed {
        Id
        Logo
        Time
        Title
        UsersVoted
        VoteOptions {
          Label
          Value
        }
        VoteResults
        AlltingGenerated
        Completed
        Description
        Flag
      }
    }
  }
`;

export const feedbackQuestionsQuery = gql`
  query FeedbackQuestionsQuery {
    getFeedbackQuestions(questionsVersion: "v2") {
      Overall {
        Id
        Name
        Required
        Description
      }
      Questions {
        Id
        Name
        Description
      }
    }
  }
`;
export const giveFeedbackMutation = gql`
  mutation giveFeedbackMutation($meetingId: ID!, $input: FeedbackInput!) {
    giveFeedback(meetingId: $meetingId, input: $input)
  }
`;
export const giveExternalFeedbackMutation = gql`
  mutation GiveExternalFeedbackMutation(
    $meetingId: ID!
    $email: String!
    $input: FeedbackInput!
  ) {
    giveExternalFeedback(meetingId: $meetingId, email: $email, input: $input)
  }
`;

export const deleteMeetingMutation = gql`
  mutation deleteMeeting($id: ID!) {
    deleteMeeting(id: $id)
  }
`;

export const hasGivenFeedbackQuery = gql`
  query hasGivenFeedbackQuery($meetingId: ID!) {
    hasGivenFeedback(meetingId: $meetingId)
  }
`;

export const hasExternalGivenFeedbackQuery = gql`
  query hasExternalGivenFeedback($meetingId: ID!, $email: String!) {
    hasExternalGivenFeedback(meetingId: $meetingId, email: $email)
  }
`;

export const scheduledMeetingQuery = gql`
  query ScheduledMeetingQuery($id: ID!) {
    meeting: getMeeting(id: $id) {
      Id
      OrgId
      Name
      Status
      StartTime
      ExternalParticipants
      PendingUsers
      Tags
      Team {
        Id
        Name
      }
      Participants {
        Id
        Email
        UserName
        ImageUrl
      }
    }
  }
`;

export const updateMeeting = gql`
  mutation UpdateMeeting(
    $id: ID!
    $attribute: meetingAttributesEnum!
    $value: String!
    $delete: Boolean
  ) {
    updateMeeting(
      Id: $id
      attribute: $attribute
      value: $value
      delete: $delete
    ) {
      Id
    }
  }
`;
export const globalInsightsQuery = gql`
  query GlobalInsightsQuery {
    globalInsights {
      Insight
      Coaching
      Conditions {
        NormalizedValue
        QuestionId
        Type
        Question {
          Id
          Name
        }
      }
      Insight
    }
  }
`;
