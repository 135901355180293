import React, { useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { colors } from "../styles";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import validateEmail from "../utils/validateEmail";
import firebase from "../firebase";
import { RequestToJoinOrg } from "./__generated__/RequestToJoinOrg";
import { RequestToJoinOrgResponse } from "../__generated__/globalTypes";

const mutation = gql`
  mutation CreateOrg($name: String!) {
    createOrg(name: $name)
  }
`;

const requestMutation = gql`
  mutation RequestToJoinOrg($email: String!) {
    requestToJoinOrg(adminEmail: $email)
  }
`;

export default function CreateOrgDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [create, setCreate] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminRequestFailed, setAdminRequestFailed] = useState(false);
  const [createOrg, { data, error }] = useMutation(mutation);
  const [requestToJoin] = useMutation<RequestToJoinOrg>(requestMutation);

  function closeAndReset() {
    setCreate(false);
    setFormError(false);
    setOrgName("");
    setLoading(false);
    setAdminEmail("");
    onClose();
  }

  async function sendRequest() {
    setLoading(true);
    const resp = await requestToJoin({ variables: { email: adminEmail } });

    switch (resp.data?.requestToJoinOrg) {
      case RequestToJoinOrgResponse.NoOrg:
      case RequestToJoinOrgResponse.NotAdmin:
      case RequestToJoinOrgResponse.NoUser:
        setAdminRequestFailed(true);
        setLoading(false);
        break;
      case RequestToJoinOrgResponse.Success:
        closeAndReset();
        break;
    }
  }

  async function createOrganization() {
    // TODO: check org name, generate error or create organization and add user to it.
    if (
      /[^A-Za-z0-9\s]/.test(orgName) ||
      orgName === "" ||
      (orgName.length < 2 && orgName.length > 20)
    ) {
      setFormError(true);
      return;
    }
    setLoading(true);

    await createOrg({ variables: { name: orgName } });
    firebase
      .analytics()
      .logEvent("create_org", { page_title: "Create Org Dialog" });
    closeAndReset();
  }
  return (
    <ConfirmationDialog
      open={open}
      onClose={closeAndReset}
      title={create ? "Create Organization" : "Join or create an organization"}
      description={
        loading ? (
          <div>
            <p>Please wait while setting up</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CircularProgress
                style={{ marginTop: 30, width: 50, height: 50 }}
                color="primary"
              />
            </div>
          </div>
        ) : create ? (
          ""
        ) : (
          <p>
            You need to be part of an organization for Allting to give you a
            richer and more rewarding experience. Either ask the admin of your
            organization to invite you or create your own organization.
            <br />
            <br />
            Type the email of your admin and press “Send” and we’ll send a
            request for you.
          </p>
        )
      }
      onConfirm={() => {
        if (!loading) {
          if (create) {
            createOrganization();
          } else {
            setCreate(true);
          }
        }
      }}
      disabled={loading || (create && orgName === "")}
      confirmButtonText={loading ? "Loading" : "Create Organization"}
    >
      {!loading && (
        <>
          {create ? (
            <div style={{ width: 300 }}>
              {formError && (
                <Typography gutterBottom style={{ color: colors.error.main }}>
                  Name can only contain letters and numbers. And have to be at
                  least 3 letters and most 30.
                </Typography>
              )}
              <FormControl style={{ width: "100%" }} error={formError}>
                <FormLabel required>Organization Name</FormLabel>
                <TextField
                  required
                  value={orgName}
                  onChange={(event) => setOrgName(event.target.value)}
                />
              </FormControl>
            </div>
          ) : (
            <div>
              {formError && (
                <Typography gutterBottom style={{ color: colors.error.main }}>
                  Invalid Email
                </Typography>
              )}
              {adminRequestFailed && (
                <Typography gutterBottom style={{ color: colors.error.main }}>
                  No admin with that email was found.
                </Typography>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <TextField
                  style={{ width: 300, marginRight: 10 }}
                  placeholder="Admin Email"
                  error={adminRequestFailed}
                  required
                  value={adminEmail}
                  onChange={(event) => setAdminEmail(event.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={adminEmail === ""}
                  onClick={() => {
                    if (!validateEmail(adminEmail)) {
                      setFormError(true);
                    } else {
                      sendRequest();
                    }
                  }}
                >
                  Send Request
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </ConfirmationDialog>
  );
}
