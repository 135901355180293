import React, { useMemo } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { colors, phoneWidth } from "../../styles";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  MeetingQuery_meeting_EnergyLevels,
  MeetingQuery_meeting_LiveFeed,
} from "./__generated__/MeetingQuery";
import normalize from "../../utils/normalize";
import styled from "styled-components";
import { GetFlagIcon } from "../Live/Flag";
import { FlagEnum, VoteResultEnum } from "../../__generated__/globalTypes";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { formatMinutes } from "../../utils/formatTime";
import { PieData } from "./Graphs";
import { getColorHex } from "../Live/VoteContent";
import StackedBar from "components/StackedBar";

function calculateMinutes(start: number, current: number) {
  return Math.ceil((current - start) / 60000);
}

function energyLevelCuration(
  energyLevels: MeetingQuery_meeting_EnergyLevels[],
  startTime: number,
  endTime: number
) {
  const timeDistance = 5; // 5 minutes
  const filteredEnergy: Record<
    number,
    MeetingQuery_meeting_EnergyLevels[]
  > = {};
  // TODO: Create a key val for each time period
  // iterate trough and add items to each appropriate time period.
  // grab previous state and then update with adding new ones and removing previous ones
  // calculate new levels
  let currentKey = 0;
  const end = calculateMinutes(startTime, endTime);
  while (currentKey < end) {
    filteredEnergy[currentKey] = [];
    currentKey += timeDistance;
  }

  energyLevels.forEach((value) => {
    const minutes = calculateMinutes(startTime, value.Timestamp);
    const newKey = Math.floor(minutes / timeDistance) * timeDistance;
    if (filteredEnergy[newKey] != null) {
      filteredEnergy[newKey].push(value);
    } else {
      filteredEnergy[newKey] = [value];
    }
  });

  const refinedLevels: { Energy: number; Minutes: number }[] = [];
  Object.keys(filteredEnergy).forEach((strKey: string) => {
    const key = parseInt(strKey);
    if (key != 0 && filteredEnergy[key - timeDistance] != null) {
      filteredEnergy[key].push(...filteredEnergy[key - timeDistance]);
    }
    const filteredValues = filteredEnergy[key].reduce(
      (accumulator: MeetingQuery_meeting_EnergyLevels[], current) => {
        const item = accumulator.find(
          (value) => current.UserId === value.UserId
        );
        if (item == null || item.Timestamp < current.Timestamp) {
          return [
            ...accumulator.filter((value) => value.UserId !== current.UserId),
            current,
          ];
        }
        return accumulator;
      },
      []
    );

    const sumEnergy = filteredValues.reduce(
      (sum, current) => sum + current.Energy,
      0
    );
    refinedLevels.push({
      Energy: sumEnergy / filteredValues.length,
      Minutes: key,
    });
  });
  return refinedLevels;
}

export default function EnergyChart({
  energyLevels,
  liveFeed,
  startTime,
  endTime,
}: {
  energyLevels: MeetingQuery_meeting_EnergyLevels[];
  liveFeed: MeetingQuery_meeting_LiveFeed[];
  startTime: number;
  endTime: number;
}) {
  const largeScreen = !useMediaQuery(phoneWidth);
  const data = useMemo(
    () => energyLevelCuration(energyLevels, startTime, endTime),
    [energyLevels, startTime]
  );
  return (
    <div>
      <div style={{ marginLeft: 50 }}>
        <LiveFeedTimeline
          startTime={startTime}
          endTime={endTime}
          feed={liveFeed}
        />
      </div>
      <div style={{ display: "flex" }}>
        <ResponsiveContainer width={largeScreen ? 800 : 325} height={300}>
          <LineChart data={data}>
            <XAxis
              dataKey="Minutes"
              domain={[0, calculateMinutes(startTime, endTime)]}
            />
            <YAxis
              tickFormatter={(v) => `${normalize(v, 1, 5) * 100}%`}
              tickMargin={0}
              width={42}
              domain={[1, 5]}
            />
            <Line
              type="monotone"
              isAnimationActive={false}
              dataKey="Energy"
              stroke={colors.info.main}
              strokeWidth={2}
            />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

const TimelineWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
`;

const FlagTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 120,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function LiveFeedTimeline({
  feed,
  startTime,
  endTime,
}: {
  feed: MeetingQuery_meeting_LiveFeed[];
  startTime: number;
  endTime: number;
}) {
  return (
    <TimelineWrapper>
      {feed
        .filter((v) => v.Flag != null)
        .map((f, index) => (
          <div
            style={{
              width: `${
                normalize(
                  f.Time -
                    (feed.filter((v) => v.Flag != null)[index - 1]?.Time ??
                      startTime) +
                    startTime,
                  startTime,
                  endTime
                ) * 100
              }%`,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FlagTooltip
              title={
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>{f.Title}</Typography>
                    <Typography>
                      {formatMinutes(calculateMinutes(startTime, f.Time))}
                    </Typography>
                  </div>
                  {f.VoteResults && (
                    <div style={{ width: 155, marginTop: 5 }}>
                      <div style={{ marginTop: 10, height: 12, width: 155 }}>
                        <StackedBar data={getVoteData(f.VoteOptions, f.VoteResults)} />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        {getVoteData(f.VoteOptions, f.VoteResults).map((v) => (
                          <div
                            style={{
                              width: 110,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                color: v.color,
                              }}
                            >
                              {v.name}:
                            </Typography>
                            <Typography
                              style={{ textAlign: "right", color: v.color }}
                            >
                              {v.value ?? 0}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <div style={{ cursor: "pointer" }}>
                <GetFlagIcon darkMode={false} flag={f.Flag as FlagEnum} />
              </div>
            </FlagTooltip>
          </div>
        ))}
    </TimelineWrapper>
  );
}

function getVoteData(
  voteOptions: any,
  voteResults: VoteResultEnum[]
): PieData[] {
  const retData: {
    Count: number;
    Label: string;
    Value: VoteResultEnum;
  }[] = JSON.parse(JSON.stringify(voteOptions));
  voteResults.forEach((value) => {
    const item = retData.find((v) => value == v.Value);
    if (item == null) {
      throw Error("item not found should not be possible!");
    }
    if (item.Count != null) {
      item.Count += 1;
    } else {
      item.Count = 1;
    }
  });
  return retData.map((v) => ({
    value: v.Count,
    id: v.Value,
    name: v.Label,
    color: getColorHex(v.Value),
  }));
}
