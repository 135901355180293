/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FeedbackThumbEnum {
  down = "down",
  none = "none",
  up = "up",
}

export enum FlagEnum {
  decision = "decision",
  elaborate = "elaborate",
  feedback = "feedback",
  like = "like",
  move_on = "move_on",
  off_topic = "off_topic",
  park_discussion = "park_discussion",
}

export enum MeetingAccessEnum {
  organization = "organization",
  private = "private",
  public = "public",
}

export enum MembershipEnums {
  basic = "basic",
  none = "none",
  trial = "trial",
}

export enum RequestToJoinOrgResponse {
  NoOrg = "NoOrg",
  NoUser = "NoUser",
  NotAdmin = "NotAdmin",
  Success = "Success",
}

export enum RoleEnums {
  admin = "admin",
  none = "none",
}

export enum StatusEnum {
  done = "done",
  live = "live",
  processing = "processing",
}

export enum UserRequestType {
  JoinOrgRequest = "JoinOrgRequest",
  OrgInvite = "OrgInvite",
}

export enum VoteResultEnum {
  absent = "absent",
  dont_know = "dont_know",
  no = "no",
  yes = "yes",
}

export enum conditionTypesEnum {
  larger = "larger",
  smaller = "smaller",
  trendDecrease = "trendDecrease",
  trendIncrease = "trendIncrease",
}

export enum meetingAttributesEnum {
  Access = "Access",
  Completed = "Completed",
  Date = "Date",
  EndTime = "EndTime",
  ExternalParticipants = "ExternalParticipants",
  FileUploaded = "FileUploaded",
  Keywords = "Keywords",
  Length = "Length",
  Name = "Name",
  NumParticipants = "NumParticipants",
  Participants = "Participants",
  Speakers = "Speakers",
  StartTime = "StartTime",
  Status = "Status",
  Summary = "Summary",
  Tags = "Tags",
  Team = "Team",
  Timezone = "Timezone",
}

export enum userAttributesEnum {
  Gender = "Gender",
  ImageUrl = "ImageUrl",
  IntroTutorialCompleted = "IntroTutorialCompleted",
  LiveTutorialCompleted = "LiveTutorialCompleted",
  Name = "Name",
  PostMeetingTutorialCompleted = "PostMeetingTutorialCompleted",
  UserName = "UserName",
}

export interface ConditionInput {
  QuestionId: string;
  Type: string;
  NormalizedValue: number;
  And?: (ConditionInput | null)[] | null;
}

export interface FeedbackInput {
  QuestionVersion: string;
  Overall: number;
  Questions: ScoreInput[];
  Timestamp: number;
}

export interface GlobalInsightInput {
  Insight: string;
  Coaching: string[];
  Conditions: ConditionInput[];
}

export interface ScoreInput {
  Id: string;
  Score: FeedbackThumbEnum;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
