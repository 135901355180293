import styled from "styled-components";
import { colors, phoneWidth } from "../styles";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Avatar, Divider, IconButton, Typography } from "@material-ui/core";
import ProgressBar from "components/ProgressBar";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { TopBarContext } from "../components/TopBar";
import Top from "components/Top";
import { gql } from "apollo-boost";
import {
  TeamQuery,
  TeamQuery_teamOverview_Members,
  TeamQueryVariables,
} from "./__generated__/TeamQuery";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import LoadingTable from "../components/LoadingTable";
import { Skeleton } from "@material-ui/lab";
import { Add } from "@material-ui/icons";
import ConfirmationDialog from "../components/ConfirmationDialog";
// @ts-ignore
import { UsersInvite } from "../components/UserSearch";
import {
  addUsersToTeam,
  addUsersToTeamVariables,
} from "./__generated__/addUsersToTeam";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import validateEmail from "../utils/validateEmail";
import { formatMinutes } from "../utils/formatTime";
import firebase from "../firebase";

const TextIcon = styled.div`
  display: flex;
  justify-content: left;
  color: white;
  font-weight: 400;
  align-items: center;
`;

const GreyText = styled.div`
  color: grey;
  font-size: large;
  font-weight: 900;
  margin-left: 5px;
`;

const SubText = styled.div`
  font-size: small;
  margin-left: 10px;
`;

const LabelText = styled.div`
  color: white;
  font-weight: 300;
`;

const BottomText = styled.div`
  color: black;
  font-weight: 300;
  margin-left: 20px;
`;

const query = gql`
  query TeamQuery($id: ID!) {
    teamOverview(id: $id) {
      Id
      AvgMeetingTimeMinutes
      AvgMeetingScore
      MeetingsPerWeek
      TotalMinutesOfMeetings
      PendingInviteEmails
      Name
      Members {
        Id
        ImageUrl
        Email
        UserName
      }
    }
  }
`;

const mutation = gql`
  mutation addUsersToTeam($emails: [String!]!, $teamId: ID!) {
    inviteExternalToTeam(emails: $emails, teamId: $teamId) {
      Id
      Members
      Name
      OrgId
      PendingInviteEmails
    }
  }
`;

export default function Team(props: any) {
  useEffect(
    () =>
      firebase.analytics().logEvent("page_view", { page_title: "Team Page" }),
    []
  );
  const { setOnBackPath } = useContext(TopBarContext);
  const [teamDialog, setTeamDialog] = useState(false);
  const [
    addToTeam,
    { data: mutationData, loading: mutationLoading },
  ] = useMutation<addUsersToTeam, addUsersToTeamVariables>(mutation);
  const teamId = props.match.params.id;
  const { data, loading, refetch } = useQuery<TeamQuery, TeamQueryVariables>(
    query,
    {
      variables: { id: teamId },
    }
  );

  useEffect(() => {
    setOnBackPath("/teams");
    return () => setOnBackPath("");
  }, [setOnBackPath]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Top
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            paddingLeft: 20,
            maxWidth: 500,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div>
            <TextIcon>
              <HourglassEmptyIcon style={{ marginRight: 5 }} />
              {loading ? (
                <Skeleton variant="text" width={75} height={30} />
              ) : data?.teamOverview.AvgMeetingTimeMinutes != null ? (
                formatMinutes(data?.teamOverview.AvgMeetingTimeMinutes)
              ) : (
                ""
              )}
            </TextIcon>
            <LabelText>Average Meeting Time</LabelText>
            <TextIcon style={{ paddingTop: 30 }}>
              <SupervisorAccountIcon style={{ marginRight: 5 }} />
              {loading ? (
                <Skeleton variant="text" width={75} height={30} />
              ) : (
                data?.teamOverview.MeetingsPerWeek.toFixed(2)
              )}
            </TextIcon>
            <LabelText>Meetings/Week</LabelText>
            <TextIcon style={{ paddingTop: 30 }}>
              <AccessTimeIcon style={{ marginRight: 5 }} />
              {loading ? (
                <Skeleton variant="text" width={75} height={30} />
              ) : data?.teamOverview.TotalMinutesOfMeetings != null ? (
                formatMinutes(data?.teamOverview.TotalMinutesOfMeetings)
              ) : (
                ""
              )}
            </TextIcon>
            <LabelText>Total Meeting Time</LabelText>
          </div>
          <div
            style={{
              height: 220,
              width: 220,
              marginRight: -20,
              // marginTop: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              background: "white",
              borderRadius: 120,
            }}
          >
            <div
              style={{
                fontWeight: 500,
                fontSize: "500%",
                color: colors.primary.main,
              }}
            >
              {data?.teamOverview.AvgMeetingScore
                ? Math.round(data?.teamOverview.AvgMeetingScore)
                : "NA"}
            </div>
          </div>
        </div>
      </Top>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 600,
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Skeleton variant="text" height={60} width={150} />
              </div>
              <LoadingTable width={400} />
            </>
          ) : (
            <>
              <Typography
                style={{ textAlign: "center", flex: 1, marginTop: 15 }}
                variant="h5"
              >
                {data?.teamOverview.Name}
              </Typography>

              <div style={{ width: "100%", maxWidth: 400 }}>
                <List>
                  <ListItem
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <GreyText>
                      <ListItemText primary="Team Members" />
                    </GreyText>
                    <IconButton onClick={() => setTeamDialog(true)}>
                      <Add />
                    </IconButton>
                  </ListItem>
                  <Divider />
                  {data?.teamOverview.Members.map((member) => (
                    <ListItem>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={member?.ImageUrl ?? ""}
                          alt={member?.UserName ?? ""}
                        />
                        <SubText>
                          <ListItemText primary={member?.UserName} />
                        </SubText>
                      </div>
                    </ListItem>
                  ))}
                </List>
                {data?.teamOverview.PendingInviteEmails != null &&
                  data?.teamOverview.PendingInviteEmails.length > 0 && (
                    <List>
                      <ListItem
                        style={{
                          display: "flex",
                        }}
                      >
                        <GreyText>
                          <ListItemText primary="Pending Invites" />
                        </GreyText>
                      </ListItem>
                      <Divider />
                      {data?.teamOverview.PendingInviteEmails?.map((email) => (
                        <ListItem>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={""} alt={""} />
                            <SubText>
                              <ListItemText primary={email} />
                            </SubText>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  )}
              </div>
            </>
          )}
        </div>
      </div>
      <AddInvite
        onConfirm={async (emails) => {
          setTeamDialog(false);
          await addToTeam({ variables: { emails, teamId } });
          await refetch();
        }}
        open={teamDialog}
        onClose={() => setTeamDialog(false)}
        members={data?.teamOverview.Members ?? []}
      />
    </div>
  );
}

function AddInvite({
  onClose,
  members,
  open,
  onConfirm,
}: {
  onClose: () => void;
  open: boolean;
  members: TeamQuery_teamOverview_Members[];
  onConfirm: (emails: string[]) => void;
}) {
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
  const smallScreen = useMediaQuery(phoneWidth);
  return (
    <ConfirmationDialog
      confirmButtonText="Add"
      description="Add new members to your team! Press Enter to create if you can't find the user in the list"
      onClose={() => {
        onClose();
        setMembersToAdd([]);
      }}
      onConfirm={() => {
        onConfirm(membersToAdd);
        setMembersToAdd([]);
      }}
      open={open}
      title="Add Member"
      disabled={
        membersToAdd.length === 0 ||
        membersToAdd.filter(validateEmail).length < membersToAdd.length
      }
    >
      <div style={{ width: smallScreen ? 300 : 500 }}>
        {membersToAdd.filter(validateEmail).length < membersToAdd.length && (
          <Typography gutterBottom style={{ color: colors.error.main }}>
            One of the emails are invalid!
          </Typography>
        )}

        <UsersInvite
          chosenUserEmails={members.map((m) => m.Email)}
          onChange={setMembersToAdd}
          selectedEmails={membersToAdd}
        />
      </div>
    </ConfirmationDialog>
  );
}

export function ProgressComponent(props: { title: string; progress: number }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <BottomText>{props.title}</BottomText>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ProgressBar progress={props.progress} variant="small" />
        <EmojiEventsIcon
          style={{ marginLeft: 5, marginRight: 30, color: "gold" }}
        />
      </div>
    </div>
  );
}
