import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { colors } from "styles";

const AnimatedSvg = animated(SvgGauge);
// ...
export default function Gauge({ progress }: { progress: number }) {
  const calculatedProgress = progress / 2 + 50;
  const currentColor =
    progress < 33
      ? colors.error.main
      : progress < 66
      ? colors.info.main
      : colors.success.main;

  const props: any = useSpring({
    to: { progress: calculatedProgress, color: currentColor },
    from: { progress: 50, color: colors.primary.light },
    config: { duration: 400 },
  });

  return (
    <AnimatedSvg
      progress={props.progress}
      color={props.color}
      stroke={10}
      radius={80}
    />
  );
}

function SvgGauge({
  progress,
  radius,
  stroke,
  color,
}: {
  progress: number;
  radius: number;
  stroke: number;
  color: string;
}) {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div style={{ height: 150, overflow: "hidden", marginBottom:-65}}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <rect
          fill="white"
          width={radius * 2}
          height={radius + stroke}
          y={radius - 1}
        />
        <text
          fontFamily="Inter var"
          fontSize={radius / 4}
          textAnchor="middle"
          x={radius - 10}
          y={radius}
          fill={color}
        >
          {Math.round((progress - 50) / 5)}/
        </text>
        <text
          fontFamily="Inter var"
          fontSize={radius / 7}
          textAnchor="middle"
          x={radius + 13}
          y={radius}
          fill={color}
        >
          10
        </text>
      </svg>
    </div>
  );
}

// .circle {
//     position: relative;
//     margin: 7em auto;
//     width: 16em;
//     height: 16em;
//     border-radius: 50%;
//     background: lightblue;
//   }

// border-top-left-radius: $size * 2;
// border-top-right-radius: $size * 2;
