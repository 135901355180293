import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PeopleIcon from "@material-ui/icons/PeopleAltOutlined";
import PersonIcon from "@material-ui/icons/PersonOutline";
import PlusIcon from "@material-ui/icons/Add";
import MeetingsIcon from "@material-ui/icons/DescriptionOutlined";
import MeetingsFilledIcon from "@material-ui/icons/Description";
import JoinMeetingIcon from "@material-ui/icons/OpenInBrowser";
import useGetUser from "../views/useGetUser";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { TooltipWrapper } from "./TooltipWrapper";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 10000,
    boxShadow: "0px -2px 2px rgba(0, 0, 0, 0.2)",
  },
});

function getValue(path: string): number {
  switch (path) {
    case "teams":
      return 1;
    case "meetings":
    case "":
      return 2;
    case "profile":
      return 5;
    default:
      return -1;
  }
}
function getPath(value: number): string {
  switch (value) {
    case 1:
      return "/teams";
    case 2:
      return "/meetings";
    case 3:
      return "/create-meeting";
    case 4:
      return "/meetings/invite-to-org";
    case 5:
      return "/meetings/join";
    default:
      return "";
  }
}

export default function SimpleBottomNavigation({
  path,
  onChange,
}: {
  path: string;
  onChange: (newPath: string) => any;
}) {
  const classes = useStyles();
  const value = getValue(path);
  const { user } = useGetUser();
  const noOrg = user?.Org?.Id == null || user?.Org?.Id == "";

  if (value === -1) {
    return null;
  }

  if (noOrg) {
    return (
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          onChange(getPath(newValue));
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          value={2}
          label="Meetings"
          icon={value === 2 ? <MeetingsFilledIcon /> : <MeetingsIcon />}
        />
        <BottomNavigationAction
          value={5}
          label="Join"
          icon={<JoinMeetingIcon />}
        />
      </BottomNavigation>
    );
  }
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        onChange(getPath(newValue));
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        value={2}
        label="Meetings"
        icon={value === 2 ? <MeetingsFilledIcon /> : <MeetingsIcon />}
      />
      {/*<BottomNavigationAction*/}
      {/*  value={1}*/}
      {/*  disabled={noOrg}*/}
      {/*  label="Teams"*/}
      {/*  icon={<PeopleIcon />}*/}
      {/*/>*/}
      <BottomNavigationAction
        value={3}
        disabled={noOrg}
        label="Create"
        icon={<PlusIcon />}
      />
      <BottomNavigationAction
        value={4}
        disabled={noOrg}
        label="Add Users"
        icon={<PersonAddIcon />}
      />
      <BottomNavigationAction
        value={5}
        label="Join"
        icon={<JoinMeetingIcon />}
      />
    </BottomNavigation>
  );
}
