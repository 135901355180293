import React from "react";

export function LogoFont({ children }: { children: any }) {
  return (
    <span
      style={{
        fontFamily: "NexaBold",
        fontSize: 40,
        textAlign: "center",
      }}
    >
      {children}
    </span>
  );
}