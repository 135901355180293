import React, { ReactNode } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { colors } from "../styles";

export default function ActionButton({
  actions,
  open,
  onOpen,
  onClose,
}: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  actions: { name: string; icon: ReactNode; onClick: () => void}[];
}) {
  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      icon={<SpeedDialIcon />}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      direction="up"
    >
      {actions.map((action) => (
        <SpeedDialAction
          title={action.name}
          tooltipOpen={true}
          tooltipTitle={action.name}
          placeholder={action.name}
          key={action.name}
          icon={action.icon}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  );
}
