import React, { useState, useEffect, useRef } from "react";

import { getMedia } from "components/Recorder/audio";

import Recorder from "components/Recorder";
import { RouteComponentProps } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { AdaptiveWrapper, RowWrapper } from "components/Wrappers";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { stopRecording, startRecording } from "../utils/recordAudio";
import { GetMetingRecordData } from "./__generated__/GetMetingRecordData";
import { currentUser } from "../App";

export const meetingQuery = gql`
  query GetMetingRecordData($id: ID!) {
    meeting: getMeeting(id: $id) {
      Id
      Completed
      Participants {
        Id
      }
    }
  }
`;

export default function Record(props: RouteComponentProps) {
  const [recording, setRecording] = useState(false);
  //@ts-ignore type seems wrong
  const meetingId = props.match.params.id ?? null;

  const { loading, data, error } = useQuery<GetMetingRecordData>(meetingQuery, {
    variables: { id: meetingId }
  });

  let [count, setCount] = useState(0);

  useInterval(() => {
    if (recording) setCount(count + 1);
  }, 1000);

  const minutes = Math.floor(count / 60);
  const seconds = count - minutes * 60;
  console.log("check in Record", currentUser);
  if (minutes > 120) {
    stopRecording();
    alert(
      "Your meeting has been over 2 hours which is the maximum record time"
    );
  }

  if (data?.meeting?.Completed) {
    return (
      <div>Sorry you seem to already have a recording for this meeting.</div>
    );
  }
  if (loading) {
    return <div>Please wait while we are loading</div>;
  }

  return (
    <AdaptiveWrapper>
      <div style={{ maxWidth: 800, flex: 1 }}>
        <RowWrapper>
          <Button
            color="secondary"
            style={{ marginRight: 10 }}
            onClick={stopRecording}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            color="primary"
            onClick={() => {
              if (!meetingId) {
                throw Error("No meeting Id found!");
              }
              startRecording(
                meetingId,
                currentUser.id,
                data?.meeting?.Participants?.length ?? 4
              );
            }}
          >
            {recording ? "Done" : "Start"}
          </Button>
        </RowWrapper>
      </div>
    </AdaptiveWrapper>
  );
}

function useInterval(callback: any, delay: number) {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current != null) savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
