import React from "react";
import { Select, InputLabel, MenuItem } from "@material-ui/core";
import ArrowDown from "@material-ui/icons/ArrowDropDown";

type Props = {
  label: string;
  style?: Object;
  options: { label: string; value: string | number }[];
  iconComponent?: any;
  defaultValue?: string;
  multiple?: boolean;
  onChange: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  value: string | number | string[];
};

export default function sel(props: Props) {
  const {
    label,
    options,
    onChange,
    value,
    style,
    iconComponent,
    defaultValue,
    multiple,
  } = props;
  return (
    <div style={style}>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <Select
        multiple={multiple}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
        IconComponent={iconComponent ?? ArrowDown}
      >
        {options.map((opt) => (
          <MenuItem value={opt.value}>{opt.label}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
