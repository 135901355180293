import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Chip,
  Menu,
  TextField,
  Typography,
  Zoom,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import PeopleIcon from "@material-ui/icons/People";
import PlayIcon from "@material-ui/icons/PlayArrow";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { CascadingConsumer, CascadingList } from "components/AnimatedList";
import { sectionQuery, speakersQuery } from "./queries";
import {
  SectionQuery,
  SectionQuery_paginatedSection_Sections as SectionQuery_section,
  SectionQuery_paginatedSection_Sections_Words as SectionQuery_section_Words,
} from "./__generated__/SectionQuery";

import { useQuery } from "react-apollo";

import { CentralWrapper } from "components/Wrappers";
import { SpeechLoader } from "components/Skeletons";
import { SpeakersQuery } from "./__generated__/speakersQuery";
import { Skeleton } from "@material-ui/lab";
import UserChip from "../../components/UserChip";
import { colors } from "../../styles";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";

type Speaker = { ID: number; Name: string; WordsSpoken: number };

type EditProps = {
  history: any;
  match: any;
};

const speakerColorMap: Record<number, string> = {
  2: "#4A90E2",
  1: "#F5A623",
  0: "#AD5661",
};

function getElementByTag(
  users: any[],
  tag: number,
  element: string,
  fallback: any
) {
  const user = users.find((val) => val.Tag === tag);
  if (user == null) return fallback;
  return user[element];
}

export default function Transcription(): JSX.Element {
  const { id: meetingId } = useParams<{ id: string }>();
  const [cursor, setCursor] = useState(0);
  const [offset, setOffset] = useState(0);
  const history = useHistory();
  const [allText, setAllText] = useState<SectionQuery_section[]>([]);
  const myRef = useRef<any>();

  const Title = styled.div`
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    color: ${colors.grey.main};
  `;

  const { data: participantData } = useQuery<SpeakersQuery>(speakersQuery, {
    variables: {
      id: meetingId,
    },
  });
  const speakers = participantData?.meeting?.Speakers ?? [];

  const { loading, error, data, refetch } = useQuery<SectionQuery>(
    sectionQuery,
    {
      variables: {
        meetingId,
        cursor: 0,
      },
    }
  );

  useEffect(() => {
    if (data != null && !loading) {
      setCursor(data.paginatedSection.cursor);
      setOffset(data.paginatedSection.offset);
      setAllText(
        [...allText, ...data.paginatedSection.Sections].sort(
          (a: any, b: any) => a.Id - b.Id
        )
      );
    }
  }, [data, loading]);

  function fetchMore() {
    if (cursor < offset)
      refetch({
        meetingId,
        cursor,
      });
    setCursor(cursor + 1);
  }

  // Remove this
  const noEdit = true;

  if (error)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        <Typography
          variant="h6"
          style={{ color: colors.text, width: 300, textAlign: "center" }}
        >
          Can not get the transcription at this point. Please come back later.
        </Typography>
      </div>
    );
  if ((data == null || data.paginatedSection.Sections == null) && !loading)
    return <div>no Data</div>;
  // TODO: fix this up this code ugly
  const refWidth =
    myRef != null && myRef.current != null ? myRef.current.clientWidth : 800;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {participantData?.meeting.Name ? (
        <Typography style={{ margin: 25 }} variant="h5">
          {participantData.meeting.Name}
        </Typography>
      ) : (
        <Skeleton variant="text" width={75} height={30} />
      )}
      <CentralWrapper ref={myRef} style={{ padding: 8, paddingRight: 25 }}>
        <div style={{ width: "100%" }}>
          <Button
            onClick={() => history.push(`/meeting/${meetingId}`)}
            color="primary"
            style={{ float: "left", position: "relative" }}
          >
            <ChevronLeft /> Back
          </Button>
        </div>
        <Title>Transcription</Title>
        {loading ? (
          <SpeechLoader maxWidth={refWidth - 30} />
        ) : (
          <>
            {!noEdit && (
              <>
                {allText.map((sec, index) => (
                  <EditableText
                    key={index}
                    section={sec}
                    updateData={() => undefined}
                    onChanged={(editedText) => console.log(editedText)}
                  />
                ))}
              </>
            )}
            {noEdit && (
              <CascadingList list={allText}>
                <CascadingConsumer>
                  {({ item }: { item: SectionQuery_section }) => (
                    <div
                      key={item.Id}
                      style={{
                        marginBottom: 20,
                        width: "100%",
                        paddingRight: 20,
                        paddingLeft: 10,
                      }}
                    >
                      <UserChip
                        src={
                          speakers.find((s) => s.Tag === item.SpeakerTag)?.User
                            ?.ImageUrl ?? undefined
                        }
                        label={
                          speakers.find((s) => s.Tag === item.SpeakerTag)?.User
                            ?.UserName ?? `Speaker ${item.SpeakerTag}`
                        }
                      />
                      <div
                        style={{
                          marginLeft: 5,
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {item.Words.map((Word, index) => (
                          <Typography
                            key={index}
                            // TODO implement drag or between selections
                            style={{
                              whiteSpace: "pre-line",
                              marginRight: 4,
                            }}
                          >
                            {Word.Word}
                          </Typography>
                        ))}
                      </div>
                    </div>
                  )}
                </CascadingConsumer>
              </CascadingList>
            )}
          </>
        )}
        <Button disabled={loading} onClick={fetchMore}>
          Load More
        </Button>
      </CentralWrapper>
    </div>
  );
}

const timeout = 200;

function EditButtons(props: {
  onClose: () => void;
  onPlay: () => void;
  onEdit: () => void;
  onSpeaker: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete: () => void;
}): JSX.Element {
  const [open, setOpen] = useState(true);
  const { onClose, onEdit, onSpeaker, onPlay, onDelete } = props;

  return (
    <div
      style={{
        width: "100%",
        marginTop: 5,
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <Zoom in={open} timeout={timeout + 200} unmountOnExit>
        <Fab size="small" aria-label="people" onClick={onSpeaker}>
          <PeopleIcon />
        </Fab>
      </Zoom>
      <Zoom in={open} timeout={timeout + 150} unmountOnExit>
        <Fab size="small" aria-label="edit" onClick={onEdit}>
          <EditIcon />
        </Fab>
      </Zoom>
      <Zoom in={open} timeout={timeout + 100} unmountOnExit>
        <Fab size="small" aria-label="play" onClick={onPlay}>
          <PlayIcon />
        </Fab>
      </Zoom>
      <Zoom in={open} timeout={timeout + 50} unmountOnExit>
        <Fab size="small" aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </Fab>
      </Zoom>
      <Zoom in={open} timeout={timeout} unmountOnExit>
        <Fab
          size="small"
          aria-label="close"
          onClick={() => {
            setOpen(false);
            setTimeout(onClose, timeout);
          }}
        >
          <CloseIcon />
        </Fab>
      </Zoom>
    </div>
  );
}

// function Section(props: { children: JSX.Element }): JSX.Element {
//   return (
//     <div>
//       <Typography>
//         Title go here <span>+</span>
//       </Typography>
//       {props.children}
//     </div>
//   );
// }

function EditableText(props: {
  onChanged: (newText: string) => void;
  section: SectionQuery_section;
  updateData: (data: any) => void;
}): JSX.Element {
  const { onChanged, section, updateData } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { SpeakerTag } = section;

  function speakerClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setAnchorEl(event.currentTarget);
  }

  const [editMode, setEditMode] = useState(false);
  const [editTextMode, setEditTextMode] = useState(false);
  const [editText, setEditText] = useState("");
  const [selectedWords, setSelectedWords] = useState<
    SectionQuery_section_Words[]
  >([]);

  function speakerClose(speaker: Speaker | null) {
    setAnchorEl(null);
    if (SpeakerTag != null) {
      if (selectedWords.length === 0) console.log("this acctually happend...");
      setSelectedWords([]);
      setEditMode(false);
    }
  }

  function selectWord(word: SectionQuery_section_Words) {
    if (!selectedWords.includes(word))
      setSelectedWords([...selectedWords, word]);
    else setSelectedWords([...selectedWords.filter((el) => el !== word)]);
  }

  return (
    <div style={{ width: "100%", marginBottom: 20 }}>
      {editTextMode ? (
        <TextField
          style={{ width: "100%" }}
          value={editText}
          multiline
          autoFocus
          onBlur={() => setEditTextMode(false)}
          onChange={(event) => setEditText(event.target.value)}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            borderLeft: `3px solid ${speakerColorMap[SpeakerTag]}`,
            paddingLeft: 10,
          }}
        >
          {section.Words.map((word) =>
            editMode && selectedWords.includes(word) ? (
              <Chip
                style={{ marginRight: 4 }}
                variant="outlined"
                color="primary"
                onClick={() => selectWord(word)}
                label={word.Word}
                size="small"
              />
            ) : (
              <Typography
                // TODO implement drag or between selections
                style={{
                  whiteSpace: "pre-line",
                  marginRight: 4,
                }}
                onClick={() =>
                  editMode ? selectWord(word) : setEditMode(true)
                }
              >
                {word.Word}
              </Typography>
            )
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => speakerClose(null)}
          >
            {/* {newData.Speakers.map((speaker: Speaker) => (
              <MenuItem onClick={() => speakerClose(speaker)}>
                {speaker.Name}
              </MenuItem>
            ))} */}
          </Menu>
        </div>
      )}
      {editMode && (
        <EditButtons
          onClose={() => {
            setEditMode(false);
            setEditTextMode(false);
            setSelectedWords([]);
            speakerClose(null);
          }}
          onDelete={() => console.log("delete")}
          onEdit={() => setEditTextMode(true)}
          onSpeaker={speakerClick}
          onPlay={() => console.log("play")}
        />
      )}
    </div>
  );
}
