import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@material-ui/core";
import { colors } from "../styles";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStylesCostum = makeStyles({
  root: {
    position: "relative",
  },
  top: {
    color: (props: any) => props.color ?? colors.info.light,
    animationDuration: "550ms",
  },
  circle: {
    strokeLinecap: "round",
  },
});

export function CircularGraphWithLabel(
  props: CircularProgressProps & {
    progress: number;
    colorMode: "main" | "light" | "dark";
    max: number;
    small?: boolean;
  }
) {
  const { progress, colorMode, max } = props;
  const currentColor =
    progress < 33
      ? colors.error[colorMode]
      : progress < 66
      ? colors.info[colorMode]
      : colors.success[colorMode];
  const classes = useStylesCostum({ color: currentColor });
  return (
    <div>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={props.small ? 47 : 120}
          variant="static"
          value={progress}
          {...props}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            style={{
              color: colorMode === "light" ? "white" : colors.grey.main,
              fontSize: 12,
            }}
          >
            <span style={{ fontSize: props.small ? 14 : 20 }}>
              {Math.round((progress / 10) * max) / 10}
            </span>
            /{max}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
