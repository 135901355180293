import React, { ReactNode, useContext, useState } from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { v4 as uuidv4 } from "uuid";

export default function ({
  open,
  onClose,
  message,
}: {
  open: boolean;
  onClose: () => void;
  message: any;
}) {
  return (
    <Snackbar
      style={{ maxWidth: 330 }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={onClose}
      message={message}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return (
      // @ts-ignore
      <Slide direction="down" ref={ref} {...props} />
    );
  }
);

export const snackContext = React.createContext({
  setSnack: (options: SnackProps) => {},
});

export function useSnack(): (options: SnackProps) => void {
  const context = useContext(snackContext);
  return (options: SnackProps) => context.setSnack(options);
}

type SnackProps = {
  message: string;
};

export function SnackProvider({ children }: { children: ReactNode }) {
  const [snacks, setSnacks] = useState<
    { open: boolean; message: string; key: string }[]
  >([]);

  return (
    <snackContext.Provider
      value={{
        setSnack: ({ message }) => {
          const key = uuidv4();
          setSnacks([...snacks, { message, open: true, key }]);
          setTimeout(
            () => setSnacks(snacks.filter((val) => val.key !== key)),
            10000
          );
        },
      }}
    >
      {snacks.map((s, i) => (
        <SnackComp
          key={s.key}
          message={s.message}
          open={s.open}
          onClose={() => {
            const obj = snacks.find((v) => v.key === s.key);
            if (obj != null) {
              obj.open = false;
            }
          }}
        />
      ))}

      {children}
    </snackContext.Provider>
  );
}

function SnackComp({
  message,
  open,
  onClose,
}: {
  message: string;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Snackbar
      style={{ maxWidth: 230 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      onClose={onClose}
      message={message}
      autoHideDuration={4000}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
}
