import React from "react";

// export default function TopFeedbackScores({
//   questions,
// }: {
//   questions: { Id: string; Scores: number[]; Name: string }[];
// }) {
//   if (questions.length == 0 || questions[0].Scores.length == 0) {
//     return (
//       <div>
//         <Typography variant="subtitle2" color="textSecondary">
//           No feedback received.
//         </Typography>
//       </div>
//     );
//   }
//   return (
//     <div style={{ width: "100%" }}>
//       <Bar value={1} name="Collaborative Environment" />
//       <Bar value={0.5} name="Inclusive" />
//       <Bar value={-1} name="Relevant" />
//       <Bar value={-0.8} name="Clear Agenda" />
//     </div>
//   );
// }
//
// function Bar({ value, name }: { value: number; name: string }) {
//   return (
//     <div style={{ width: "100%" }}>
//       <Typography
//         gutterBottom
//         style={{ width: "100%", textAlign: "center", fontSize: 14 }}
//       >
//         {name}
//       </Typography>
//       <div style={{ width: "100%", display: "flex", justifyContent: "baseline"}}>
//         {value < 0 ? (
//           <>
//             <div
//               style={{
//                 width: 110 * value * -1,
//                 height: 20,
//                 float: "right",
//                 borderBottomLeftRadius: 5,
//                 borderTopLeftRadius: 5,
//                 backgroundColor: colors.error.light,
//               }}
//             />
//             <div
//               style={{
//                 width: 110,
//                 opacity: 0,
//               }}
//             />
//           </>
//         ) : (
//           <>
//             <div
//               style={{
//                 width: 110,
//                 opacity: 0,
//               }}
//             />
//             <div
//               style={{
//                 width: 110 * value,
//                 height: 20,
//                 float: "left",
//                 borderBottomRightRadius: 5,
//                 borderTopRightRadius: 5,
//                 backgroundColor: colors.success.light,
//               }}
//             />
//           </>
//         )}
//       </div>
//     </div>
//   );
// }
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from "recharts";
import { colors } from "../styles";

function getAverge(nums: number[]) {
  return nums.reduce((a, b) => a + b) / nums.length;
}

export default function ({
  questions,
  width,
}: {
  questions: { Id: string; Scores: number[]; Name: string }[];
  width?: number;
}) {
  const data = questions.map((v) => ({
    name: v.Name,
    value: v.Scores != null && v.Scores.length > 0 ? getAverge(v.Scores) : 0,
  }));
  return (
    <RadarChart
      width={width ?? 290}
      height={230}
      cx="50%"
      cy="50%"
      outerRadius="80%"
      data={data}
    >
      <PolarGrid />
      <PolarAngleAxis tick={customTick} dataKey="name" />
      <PolarRadiusAxis angle={30} domain={[-1, 1]} />
      <Radar
        name="Feedback"
        dataKey="value"
        stroke={colors.success.main}
        fill={colors.success.light}
        fillOpacity={0.6}
      />
    </RadarChart>
  );
}

function customTick({ payload, x, y, textAnchor, stroke, radius }: any) {
  const text: string[] = payload.value.split(" ");
  return (
    <g className="recharts-layer recharts-polar-angle-axis-tick">
      <text
        radius={radius}
        stroke={stroke}
        x={x}
        y={y}
        className="recharts-text recharts-polar-angle-axis-tick-value"
        fontSize={9}
        text-anchor={textAnchor}
      >
        {text.map((v, i) => (
          <tspan x={x} dy={`${i * 1.2}em`}>
            {v}
          </tspan>
        ))}
      </text>
    </g>
  );
}

// return (
//   <BarChart width={250} height={220} data={data}>
//     <XAxis dataKey="name" tick={false} width={0} height={0} axisLine={false} type="category" />
//     <YAxis type="number" domain={[-1, 1]} width={0} height={0} tick={false} axisLine={false} />
//     <Tooltip />
//     <ReferenceLine y={0} stroke="#000" />
//     <Bar dataKey="value" fill="#8884d8" />
//   </BarChart>
// );
