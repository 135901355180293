import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { useMutation, useQuery } from "react-apollo";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { gql } from "apollo-boost";
import { Profile, Profile_profile } from "./__generated__/Profile";
import { Avatar, Button, Checkbox, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { tutorialCompleteMutation } from "./Intro/Base";
import { currentContext } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    height: "100%",
    width: 360,
  },
}));

const query = gql`
  query Profile {
    profile {
      Id
      UserName
      Email
      ImageUrl
      Membership
      Name
      ZoomId
      IntroTutorialCompleted
      LiveTutorialCompleted
      PostMeetingTutorialCompleted
      Org {
        Id
        LogoUrl
        Name
      }
      Role
      Membership
    }
  }
`;

export default function ProfileComp(props: any) {
  useEffect(
    () => firebase.analytics().logEvent("page_view", { page_title: "Profile" }),
    []
  );
  const { data, loading, refetch } = useQuery<Profile>(query, {
    fetchPolicy: "cache-and-network",
  });
  const history = useHistory();

  return (
    <div style={{ width: "100%" }}>
      <Top>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Avatar
            style={{
              height: 175,
              width: 175,
              position: "relative",
              margin: "auto",
            }}
            src={data?.profile.ImageUrl ?? undefined}
            alt={data?.profile.UserName ?? ""}
          />
        </div>
      </Top>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <div
              style={{
                paddingTop: 75,
                fontWeight: 550,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {data?.profile.UserName}
            </div>
            <div style={{ width: 320, marginTop: -27 }}>
              <Button
                color="primary"
                style={{ float: "right" }}
                onClick={() => history.push("/edit")}
              >
                Edit
              </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {data?.profile != null ? (
                <ProfileList refetch={refetch} profile={data?.profile} />
              ) : null}
            </div>
            {/*{data?.profile.ZoomId == null && (*/}
            {/*  <Button*/}
            {/*    type="a"*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    style={{ marginTop: 50 }}*/}
            {/*    href="https://zoom.us/oauth/authorize?response_type=code&client_id=kVZfChYgQDyepy18Hh0a9A&redirect_uri=https://alltinglabs.com/zoom"*/}
            {/*  >*/}
            {/*    Connect to Zoom*/}
            {/*  </Button>*/}
            {/*)}*/}

            <Button
              color="inherit"
              style={{ marginTop: 50, color: colors.error.main }}
              onClick={() => firebase.auth().signOut()}
            >
              Sign out
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export function ProfileList({
  profile,
  refetch,
}: {
  profile: Profile_profile;
  refetch: () => void;
}) {
  const classes = useStyles();
  const { Email, Org, Membership, Name } = profile;
  const [updateUser] = useMutation(tutorialCompleteMutation);

  return (
    <div className={classes.root}>
      <List>
        <ListItem>
          <GreyText>
            <ListItemText primary="Full Name" />
          </GreyText>
        </ListItem>
        <ListItem>
          <SubText>
            <ListItemText primary={Name ?? ""} />
          </SubText>
        </ListItem>
        <Divider />
        <ListItem>
          <GreyText>
            <ListItemText primary="Organization" />
          </GreyText>
        </ListItem>
        <ListItem>
          <SubText>
            <ListItemText primary={Org?.Name ?? "No organization added"} />
          </SubText>
        </ListItem>
        <Divider />
        <ListItem>
          <GreyText>
            <ListItemText primary="Account Email" />
          </GreyText>
        </ListItem>
        <ListItem>
          <SubText>
            <ListItemText primary={Email} />
          </SubText>
        </ListItem>
        <Divider />
        {currentContext !== "teams" && (
          <>
            <ListItem>
              <GreyText>
                <ListItemText primary="Membership" />
              </GreyText>
            </ListItem>
            <ListItem>
              <SubText>
                <ListItemText primary="Free User" />
              </SubText>
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem>
          <GreyText>
            <ListItemText primary="Tutorial" />
          </GreyText>
        </ListItem>
        <ListItem>
          <div>
            <ProfileCheckbox
              checked={profile.IntroTutorialCompleted ?? false}
              updateChecked={async (val) => {
                await updateUser({
                  variables: {
                    attribute: "IntroTutorialCompleted",
                    value: val.toString(),
                  },
                });
                await refetch();
              }}
              label="Finished meetings tutorial."
            />
            <ProfileCheckbox
              checked={profile.LiveTutorialCompleted ?? false}
              updateChecked={async (val) => {
                await updateUser({
                  variables: {
                    attribute: "LiveTutorialCompleted",
                    value: val.toString(),
                  },
                });
                await refetch();
              }}
              label="Finished live tutorial."
            />
            <ProfileCheckbox
              checked={profile.PostMeetingTutorialCompleted ?? false}
              updateChecked={async (val) => {
                await updateUser({
                  variables: {
                    attribute: "PostMeetingTutorialCompleted",
                    value: val.toString(),
                  },
                });
                await refetch();
              }}
              label="Finished post-meeting tutorial."
            />
          </div>
        </ListItem>
      </List>
      <Divider />
    </div>
  );
}

function ProfileCheckbox({
  checked,
  label,
  updateChecked,
}: {
  checked: boolean;
  label: string;
  updateChecked: (val: boolean) => void;
}) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={checked}
        onChange={() => updateChecked(!checked)}
        color="primary"
      />
      <Typography variant="subtitle1">{label}</Typography>
    </div>
  );
}

function Loading() {
  const classes = useStyles();
  return (
    <div style={{ marginTop: 65 }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Skeleton variant="text" width={150} height={40} />
      </div>
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
    </div>
  );
}

function LoadingItem() {
  return (
    <div>
      <Skeleton variant="text" width={50} height={40} />
      <Skeleton variant="text" width={Math.random() * 305 + 40} height={40} />
    </div>
  );
}

const Top = styled.div`
  background-color: ${colors.primary.main};
  height: 150px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
`;

const GreyText = styled.div`
  color: grey;
  font-size: large;
  font-weight: 900;
  margin-left: 5px;
`;
const SubText = styled.div`
  font-size: small;
  margin-left: 5px;
  margin-top: -20px;
`;
