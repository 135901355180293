import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function LoadingTable({ width }: { width: number }) {
  const height = 50;
  const style = { marginTop: -12 };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Skeleton style={style} variant="text" width={120} height={70} />
      <Skeleton style={style} variant="text" height={height} width={width} />
      <Skeleton style={style} variant="text" height={height} width={width} />
      <Skeleton style={style} variant="text" height={height} width={width} />
      <Skeleton style={style} variant="text" height={height} width={width} />
      <Skeleton style={style} variant="text" height={height} width={width} />
    </div>
  );
}
