let extension = false;

// const EXTENSION_ID = "kigbfcndcpjimfppflcalapdgaojhekm";
const EXTENSION_ID = "eoijnapbifidmjechncaakpgkbpdihea";

try {
  // @ts-ignore
  chrome?.runtime.sendMessage(EXTENSION_ID, "version", (response) => {
    if (response.type === "success") {
      extension = true;
    }
  });
} catch (e) {
  console.log("Failed to get chrome version");
  console.error(e);
}

export function hasExtension() {
  return extension;
}

export function usingChrome() {
  return navigator.userAgent.indexOf("Chrome") > -1;
}

export function hasExtRelevantBrowserOrDevice() {
  // Opera 8.0+
  //@ts-ignore
  const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
  //@ts-ignore
  const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
  //@ts-ignore
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

// Internet Explorer 6-11
  //@ts-ignore
  const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
  //@ts-ignore
  const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
  //@ts-ignore
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime || navigator.userAgent.indexOf("Chrome") != -1 );

// Edge (based on chromium) detection
  //@ts-ignore
  const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

// Blink engine detection
  //@ts-ignore
  const isBlink = (isChrome || isOpera) && !!window.CSS;

  return  isBlink || isEdgeChromium || isEdge || isIE || isSafari || isFirefox || isOpera || isChrome
}

export function sendMeetingDone(meetingId: string) {
  try {
    // @ts-ignore
    chrome?.runtime.sendMessage(
      EXTENSION_ID,
      { type: "meeting", id: meetingId },
      (response: any) => {
        console.log(response);
      }
    );
  } catch (e) {
    console.log("Failed to send chrome message");
    console.error(e);
  }
}
