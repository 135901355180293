import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { colors } from "../styles";

const IconHolder = styled.div<{ color: string; iconColor: string }>`
  background-color: ${(props) => props.color}60;
  color: ${(props) => props.iconColor};
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const Title = styled.span`
  color: ${colors.grey.main};
  font-size: 14px;
  font-weight: 500;
`;

const SubTitle = styled.span`
  color: ${colors.grey.main};
  font-size: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default function Insight({
  text,
  color,
  icon,
  title,
  subTitle,
}: {
  title?: string;
  subTitle?: string;
  text?: string;
  color: "success" | "warning" | "info" | "error" | "grey";
  icon: React.ReactNode;
}) {
  const colorVal: { dark: string; main: string; light: string } = colors[color];

  return (
    <Wrapper>
      <div style={{ alignSelf: "start", marginTop: -5 }}>
        <IconHolder color={colorVal.main} iconColor={colorVal.main}>
          {icon}
        </IconHolder>
      </div>
      <div style={{ marginLeft: 8, flex: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </div>
        <div>
          {text?.split("@").map((section, idx) => (
            <Typography style={{ fontSize: 14 }} color="textSecondary">
              {section}
            </Typography>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}
