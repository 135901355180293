import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export function SpeechLoader({ maxWidth }: { maxWidth: number }) {
  return (
    <div>
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="text" width={maxWidth} />
      <Skeleton variant="text" width={maxWidth} />
      <Skeleton variant="text" width={maxWidth / 3} />
    </div>
  );
}
