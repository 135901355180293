import React, { useMemo, useRef } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo";
import { Autocomplete } from "@material-ui/lab";
import { Avatar, Chip, TextField } from "@material-ui/core";
import {
  GetOrgQuery,
  GetOrgQuery_getOrg_Members,
} from "./__generated__/GetOrgQuery";
import { Error } from "@material-ui/icons";
import validateEmail from "../utils/validateEmail";
import UserChip from "./UserChip";

export const getOrgQuery = gql`
  query GetOrgQuery {
    getOrg {
      Members {
        Id
        UserName
        ImageUrl
        Email
      }
    }
  }
`;
//
// export default function UserSearch({
//   onChange,
//   chosenUserIds,
//   label,
// }: {
//   onChange: (user: GetOrgQuery_getOrg_Members) => void;
//   chosenUserIds: string[];
//   label?: string;
// }) {
//   const { data, loading } = useQuery<GetOrgQuery>(query);
//
//   return (
//     <InputComp
//       label={label}
//       chosenUserIds={chosenUserIds}
//       onChange={onChange}
//       users={data?.getOrg.Members ?? []}
//     />
//   );
// }
//
// export function InputComp({
//   users,
//   onChange,
//   chosenUserIds,
//   label,
// }: {
//   users: GetOrgQuery_getOrg_Members[];
//   chosenUserIds: string[];
//   onChange: (user: GetOrgQuery_getOrg_Members) => void;
//   label?: string;
// }) {
//   return (
//     <Autocomplete
//       options={users.filter((u) => !chosenUserIds.includes(u.Id))}
//       getOptionLabel={(user) => user.UserName ?? ""}
//       value={null}
//       clearOnBlur
//       onChange={(_, value) => onChange(value as GetOrgQuery_getOrg_Members)}
//       renderOption={(user) => (
//         <>
//           <Avatar src={user.ImageUrl ?? undefined} />{" "}
//           <span style={{ marginLeft: 10 }}>{user.UserName}</span>
//         </>
//       )}
//       style={{ width: 300 }}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           placeholder="Type to search"
//           label={label ?? ""}
//         />
//       )}
//     />
//   );
// }

export function UsersInvite({
  onChange,
  chosenUserEmails,
  label,
  selectedEmails,
  placeholder,
  variant,
  hideSuggestions,
}: {
  chosenUserEmails: string[];
  onChange: (users: string[]) => void;
  selectedEmails: string[];
  label?: string;
  placeholder?: string;
  variant?: "filled" | "standard";
  hideSuggestions?: boolean;
}) {
  const { data } = useQuery<GetOrgQuery>(getOrgQuery);
  const ref = useRef();
  const users = useMemo(() => data?.getOrg.Members ?? [], [data]);

  // We want it to search on both user name and email and be able to add emails
  function findUser(userEmail: string): GetOrgQuery_getOrg_Members | undefined {
    return users.find((u) => u.Email === userEmail);
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      blurOnSelect
      ref={ref}
      options={
        hideSuggestions
          ? []
          : users
              .map((u) => u.Email)
              .filter(
                (e) => ![...chosenUserEmails, ...selectedEmails].includes(e)
              )
      }
      getOptionLabel={(email) => email}
      value={selectedEmails}
      renderTags={(value, getTagProps) =>
        value.map((email) => {
          function deleteUser() {
            onChange(selectedEmails.filter((v) => v !== email));
          }

          const user = findUser(email);
          if (user == null)
            return (
              <Chip
                variant="outlined"
                avatar={
                  !validateEmail(email) ? (
                    <Avatar>
                      <Error />
                    </Avatar>
                  ) : undefined
                }
                color={!validateEmail(email) ? "secondary" : undefined}
                label={email}
                onDelete={deleteUser}
              />
            );
          return (
            <UserChip
              alt={email}
              src={user.ImageUrl ?? undefined}
              label={findUser(email)?.UserName ?? email}
              onDelete={deleteUser}
            />
          );
        })
      }
      onChange={(_, values) => onChange(values)}
      onInputChange={(_, value) => {
        if (/\s/g.test(value)) {
          onChange([...selectedEmails, value.replace(/\s+/g, "")]);
        }
      }}
      renderOption={(email) => (
        <>
          <Avatar src={findUser(email)?.ImageUrl ?? undefined} alt={email} />
          <span style={{ marginLeft: 10 }}>
            {findUser(email)?.UserName ?? email}
          </span>
        </>
      )}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ width: "100%" }}
          variant={variant ?? "filled"}
          placeholder={placeholder ?? "Type to search, enter or space to add"}
          label={label ?? ""}
        />
      )}
    />
  );
}
