import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Space from "components/Space";

export function ItemLoadingCard() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Skeleton
        style={{ marginLeft: "auto", marginRight: "auto" }}
        variant="rect"
        height={85}
        width={170}
      />
      <Space height={15} />
      <Skeleton height={30} variant="text" />
      <Skeleton height={30} variant="text" />
    </div>
  );
}

export function ListLoadingCard() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Skeleton height={50} variant="text" />
      <Skeleton height={50} variant="text" />
      <Skeleton height={50} variant="text" />
    </div>
  );
}

export function LargeLoadingCard() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Skeleton height={60} variant="text" />
      <Skeleton
        style={{ marginLeft: "auto", marginRight: "auto" }}
        variant="rect"
        height={300}
        width={700}
      />
      <Skeleton height={40} variant="text" />
      <Skeleton height={40} variant="text" />
    </div>
  );
}
