export const phoneWidth = "(max-width:780px)";
export const laptopWidth = "(max-width:1024px)";
export const tabletWidth = "(max-width:1440px)";

export const colors = {
  primary: {
    dark: "#002043",
    main: "#27476e",
    light: "#56729d",
    // dark: "#02324a",
    // light: "#215682",
    // main: "#02324a",
  },
  secondary: {
    dark: "#b94500",
    main: "#f37419",
    light: "#ffa54c",
  },
  grey: {
    dark: "#454545",
    main: "#707070",
    light: "#9e9e9e",
  },
  greyHighlight: "#E7E7E7",
  greyBackground: "#F0F0F0",
  text: "#424242",
  info: {
    dark: "#006dc4",
    main: "#559bf7",
    light: "#8eccff",
  },
  error: {
    dark: "#ab3939",
    main: "#e26963",
    light: "#ff9b92",
  },
  success: {
    main: "#6cb280",
    light: "#9de4b0",
    dark: "#3d8253",
  },
  warning: {
    dark: "#c18314",
    main: "#f8b249",
    light: "#ffe479",
  },
  background: "#f6f8f9",
  darkMode: {
    dark: "#081223",
    primary: "#0E1829",
    light: "#1E2839",
    text: "#FFF",
  },
  darkModeTeams: {
    dark: "#1f1f1f",
    primary: "#2d2c2c",
    light: "#2d2c2c",
    text: "#FFF",
  },
};

export function getDarkMode(context?: null | "teams" | "zoom" | "chrome-ext") {
  if (context === "teams") {
    return colors.darkModeTeams;
  }
  return colors.darkMode;
}
