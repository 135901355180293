import React, { useState } from "react";
import { Step } from "react-joyride";
import useGetUser from "../useGetUser";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { phoneWidth } from "../../styles";
import Base, { tutorialCompleteMutation } from "./Base";
import { useMutation } from "react-apollo";

export default function ({ loadingDone }: { loadingDone: boolean }) {
  const { user, loading } = useGetUser();
  const smallScreen = useMediaQuery(phoneWidth);
  const [updateUser] = useMutation(tutorialCompleteMutation);
  const [steps] = useState<Step[]>([
    {
      target: "#meeting-details-card",
      content: "The meeting score gives an ariel overview of the entire meeting. This score is calculated based on user feedback.",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#engagement-card",
      content: "Engagement measures how involved participants were using statistical models. Increase this score to move away from sleepy, boring meetings!",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#productivity-card",
      content: "The productivity score uses statistical models to let you know whether this meeting was a good use of the team's time!",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#coaching-card",
      content: "Based on how the team is preforming, you'll receive customized coaching tips backed by science. Time to get fit for your meetings!",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#top-feedback-card",
      content: "See how well the team performed in different categories. Scores closer to the center means you need improvement while scores near the edges mean you're doing great!",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#speaker-dist-card",
      content: "Upload an audio file to see how much each person spoke during the meeting. You can see who's dominating the conversation and who might need encouragement to speak up.",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#full-width-tab-0",
      content: "This line represents the engergy level over time. The icons above indicate flags, and votes, that were raised during the meeting.",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#full-width-tab-1",
      content: "Get an overview of the feedback provided post-meeting. You can see wheather particpants rated the meeting as positive or negative on values such as inclusiveness, relevance, and more!",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#full-width-tab-2",
      content: "No need to dig through your notes - we've got you covered! Allting transcribes your meeting so you can relax and just focus on your call",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#full-width-tab-3",
      content: "See who attended your meeting using Allting!",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#full-width-tab-3",
      content: "If you want to go through the tutorial again, go to your profile and unclick the boxes for tutorial. If you don't want to see these tutorials again, just check in the boxes.",
      disableBeacon: true,
      disableOverlayClose: true,
    },
  ]);

  if (
    user == null ||
    user?.PostMeetingTutorialCompleted ||
    !loadingDone
  ) {
    return null;
  }

  return (
    <Base
      steps={steps}
      onDone={() =>
        updateUser({
          variables: {
            attribute: "PostMeetingTutorialCompleted",
            value: "true",
          },
        })
      }
    />
  );
}
