import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/PersonOutline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import BackArrow from "@material-ui/icons/ArrowBack";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { phoneWidth } from "styles";
import { colors } from "../styles";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as LogoSvg } from "../images/raccoon_orange.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export const TopBarContext = React.createContext<{
  onBackPath: string;
  setOnBackPath: (val: string) => void;
}>({ onBackPath: "", setOnBackPath: () => undefined });

export default function ButtonAppBar(props: {
  open: boolean;
  onOpen: () => void;
}) {
  const phone = useMediaQuery(phoneWidth);
  const { open, onOpen } = props;
  const classes = useStyles();
  const { onBackPath } = useContext(TopBarContext);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <ElevationScroll>
        <AppBar
          style={{
            paddingLeft: open ? 200 : 0,
            backgroundColor: colors.primary.dark,
          }}
        >
          <Toolbar>
            {!open && !phone && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={onOpen}
              >
                <MenuIcon />
              </IconButton>
            )}
            {phone && onBackPath !== "" && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  history.push(onBackPath);
                }}
              >
                <BackArrow />
              </IconButton>
            )}
            <Typography variant="h6" className={classes.title}>
              <LogoSvg
                style={{
                  width: 30,
                  height: 30,
                  marginBottom: -8,
                  marginRight: 5,
                }}
              />
              Allting
            </Typography>
            <IconButton
              style={{ float: "right", paddingRight: 0 }}
              color="inherit"
              onClick={() => history.push("/profile")}
            >
              <PersonIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}

// Copied this from material-ui
function ElevationScroll({ children }: { children: React.ReactElement }) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
