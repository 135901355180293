import { Card, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { CentralWrapper } from "./Wrappers";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { colors, getDarkMode, phoneWidth } from "styles";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import Button from "@material-ui/core/Button";
import { FeedbackThumbEnum } from "../__generated__/globalTypes";
import styled from "styled-components";
import { currentContext } from "../App";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function FeedbackQuestion({
  title,
  state,
  onClick,
  darkMode,
}: {
  title: string;
  state: FeedbackThumbEnum;
  onClick: (value: FeedbackThumbEnum) => void;
  darkMode?: boolean;
}) {
  return (
    <Card
      style={
        darkMode ? { backgroundColor: getDarkMode(currentContext).dark } : {}
      }
    >
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: currentContext === "teams" ? "center" : "baseline",
        }}
      >
        <Typography>{title}</Typography>
        <div style={{ display: "flex" }}>
          <IconButton
            onClick={() => onClick(FeedbackThumbEnum.down)}
            style={{
              color:
                state === FeedbackThumbEnum.down
                  ? colors.error.main
                  : colors.text,
            }}
          >
            <ThumbDownIcon />
          </IconButton>
          <IconButton
            onClick={() => onClick(FeedbackThumbEnum.up)}
            style={{
              color:
                state === FeedbackThumbEnum.up
                  ? colors.success.main
                  : colors.text,
            }}
          >
            <ThumbUpIcon />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

function parseQuestions(
  questions: { Id: string; Name: string; Description: string | null }[]
) {
  const qObj: Record<string, FeedbackThumbEnum> = {};
  questions.forEach((q) => {
    qObj[q.Id] = FeedbackThumbEnum.none;
  });
  return qObj;
}

const Top = styled.div`
  background-color: ${colors.primary.main};
  height: 150px;
  width: 100%;
  border-radius: 0 0 10px 10px;
`;

const TopLive = styled.div`
  background-color: ${colors.primary.dark};
  height: 150px;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
`;

export default function Feedback({
  questions,
  onDone,
}: {
  questions: { Id: string; Name: string; Description: string | null }[];
  onDone: (input: {
    Questions: { Id: string; Score: FeedbackThumbEnum }[];
    Overall: number;
  }) => void;
}) {
  const [overall, setOverall] = useState<null | number>(null);
  const [questionsResults, setQuestionResults] = useState<
    Record<string, FeedbackThumbEnum>
  >(parseQuestions(questions));
  return (
    <Card style={{ maxWidth: 390, minWidth: 320 }}>
      <div style={{ width: "100%" }}>
        <Top>
          <Typography
            style={{ color: "white", textAlign: "center", paddingTop: 30 }}
          >
            What did you think of today's meeting?
          </Typography>
          <FaceSelect value={overall} onClick={(val) => setOverall(val)} />
        </Top>
        {questions.map((question) => (
          <div style={{ margin: 5, marginTop: 10 }}>
            <FeedbackQuestion
              state={questionsResults[question.Id]}
              key={question.Id}
              onClick={(value) => {
                const curr = { ...questionsResults };
                if (curr[question.Id] === value) {
                  curr[question.Id] = FeedbackThumbEnum.none;
                } else {
                  curr[question.Id] = value;
                }
                setQuestionResults({ ...curr });
              }}
              title={question.Name}
            />
          </div>
        ))}
        <Button
          onClick={
            overall != null
              ? () =>
                  onDone({
                    Overall: overall,
                    Questions: Object.keys(questionsResults).map((k) => ({
                      Id: k,
                      Score: questionsResults[k],
                    })),
                  })
              : undefined
          }
          disabled={overall == null}
          style={{
            marginLeft: 190,
            marginTop: 10,
            marginBottom: 5,
            marginRight: 5,
          }}
          variant="contained"
          color="primary"
        >
          Give Feedback
        </Button>
      </div>
    </Card>
  );
}

export function LiveFeedback({
  questions,
  onDone,
}: {
  questions: { Id: string; Name: string; Description: string | null }[];
  onDone: (input: {
    Questions: { Id: string; Score: FeedbackThumbEnum }[];
    Overall: number;
  }) => void;
}) {
  const [overall, setOverall] = useState<null | number>(null);
  const [questionsResults, setQuestionResults] = useState<
    Record<string, FeedbackThumbEnum>
  >(parseQuestions(questions));
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 400,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopLive>
        <Typography
          style={{ color: "white", textAlign: "center", paddingTop: 30 }}
        >
          What did you think of today's meeting?
        </Typography>
        <FaceSelect value={overall} onClick={(val) => setOverall(val)} />
      </TopLive>
      {questions.map((question) => (
        <div style={{ margin: 5, marginTop: 10 }}>
          <FeedbackQuestion
            darkMode
            state={questionsResults[question.Id]}
            key={question.Id}
            onClick={(value) => {
              const curr = { ...questionsResults };
              if (curr[question.Id] === value) {
                curr[question.Id] = FeedbackThumbEnum.none;
              } else {
                curr[question.Id] = value;
              }
              setQuestionResults({ ...curr });
            }}
            title={question.Name}
          />
        </div>
      ))}
      <Button
        onClick={
          overall != null
            ? () =>
                onDone({
                  Overall: overall,
                  Questions: Object.keys(questionsResults).map((k) => ({
                    Id: k,
                    Score: questionsResults[k],
                  })),
                })
            : undefined
        }
        disabled={overall == null}
        style={{
          marginTop: 10,
          marginBottom: 5,
          marginRight: 5,
        }}
        variant="contained"
        color="primary"
      >
        Give Feedback
      </Button>
    </div>
  );
}

export function FaceSelect({
  value,
  onClick,
}: {
  value: number | null;
  onClick: (val: number) => void;
}) {
  const smallScreen = useMediaQuery(phoneWidth);
  const fontSize = smallScreen ? (currentContext === "teams" ? 29 : 35) : 41;
  return (
    <div
      style={{
        marginTop: 10,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <IconButton onClick={() => onClick(1)}>
        <MoodBadIcon
          style={{
            fontSize,
            color: value === 1 ? colors.secondary.main : "white",
          }}
        />
      </IconButton>
      <IconButton onClick={() => onClick(2)}>
        <SentimentVeryDissatisfiedIcon
          style={{
            fontSize,
            color: value === 2 ? colors.secondary.main : "white",
          }}
        />
      </IconButton>
      <IconButton onClick={() => onClick(3)}>
        <SentimentSatisfiedIcon
          style={{
            fontSize,
            color: value === 3 ? colors.secondary.main : "white",
          }}
        />
      </IconButton>
      <IconButton onClick={() => onClick(4)}>
        <SentimentSatisfiedAltIcon
          style={{
            fontSize,
            color: value === 4 ? colors.secondary.main : "white",
          }}
        />
      </IconButton>
      <IconButton onClick={() => onClick(5)}>
        <MoodIcon
          style={{
            fontSize,
            color: value === 5 ? colors.secondary.main : "white",
          }}
        />
      </IconButton>
    </div>
  );
}
