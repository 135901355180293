import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { colors as baseColors, getDarkMode, phoneWidth } from "../../styles";
import { useHistory } from "react-router-dom";

import {
  liveFeedQuery,
  liveFeedQuery_liveMeeting_LiveFeed,
  liveFeedQueryVariables,
} from "./__generated__/liveFeedQuery";
import {
  MeetingAccessEnum,
  meetingAttributesEnum,
} from "../../__generated__/globalTypes";
import { CentralWrapper } from "../../components/Wrappers";
import { currentContext, currentUser } from "../../App";
import { ConditionalWrapper } from "../CreateMeeting";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  UpdateMeeting,
  UpdateMeetingVariables,
} from "../Meeting/__generated__/UpdateMeeting";
import { updateMeeting } from "../Meeting/queries";
import * as teams from "@microsoft/teams-js";
import { ThemeProvider } from "@material-ui/styles";
import DialogComp from "../../components/Dialog";
import { formatMinutes } from "../../utils/formatTime";
import { LiveItem } from "./LiveItem";
import { ItemContent } from "./VoteContent";
import { FlagDialog } from "./Flag";
import EnergyVote from "./EnergyVote";
import ProgressBar from "components/ProgressBar";
import normalize from "../../utils/normalize";
import { PopUpLogIn } from "../LogIn";
import ShareIcon from "@material-ui/icons/Link";
import Dialog from "@material-ui/core/Dialog";
import { getFrontEndUrl } from "../../config";
import {
  CascadingConsumer,
  CascadingList,
} from "../../components/AnimatedList";
import ShareDialog from "../../components/ShareDialog";
import {
  UpdateFlagMutation,
  UpdateFlagMutationVariables,
} from "./__generated__/UpdateFlagMutation";
import { Time } from "../../components/DateTime";
import FlagIcon from "@material-ui/icons/Flag";
import EndIcon from "@material-ui/icons/CallEnd";
import { createMuiTheme } from "@material-ui/core/styles";
import firebase from "../../firebase";
import Snack from "../../components/Snack";
import { hasExtension as checkHasExtension } from "../../utils/chromeExtention";
import LiveIntro from "../Intro/LiveIntro";
import { StatusEnum } from "../../__generated__/globalTypes";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Context } from "@microsoft/teams-js";
import LiveFeedback from "./LiveFeedback";
import { useHasGivenFeedback } from "../Meeting/hooks";

const topHeight = 110;

const Top = styled.div<{ context?: "teams" | "zoom" | "chrome-ext" | null }>`
  background-color: ${({ context }) =>
    context === "teams"
      ? baseColors.darkModeTeams.light
      : baseColors.darkMode.light};
  width: 100%;
  height: ${topHeight}px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: ${({ context }) =>
    context === "teams" ? "40px" : 0};
`;

const BottomBackground = styled.div<{
  context?: "teams" | "zoom" | "chrome-ext" | null;
}>`
  background-color: ${({ context }) =>
    context === "teams"
      ? baseColors.darkModeTeams.light
      : baseColors.darkMode.light};
  height: calc(100% - ${topHeight}px);
  width: 100%;
  overflow: hidden;
`;

const Bottom = styled.div<{ context?: "teams" | "zoom" | "chrome-ext" | null }>`
  background-color: ${({ context }) =>
    context === "teams"
      ? baseColors.darkModeTeams.dark
      : baseColors.darkMode.dark};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  overflow: scroll;
  -ms-overflow-style: none;
  border-top-left-radius: ${({ context }) =>
    context === "teams" ? 0 : "40px"};
`;

const LiveFeedQuery = gql`
  query liveFeedQuery($meetingId: ID!) {
    liveMeeting(id: $meetingId) {
      Id
      Length
      Date
      Name
      Host
      Status
      StartTime
      EndTime
      Access
      EnergyLevels {
        Id
        Energy
        UserId
        Timestamp
      }
      Length
      LiveParticipants {
        Id
        User {
          Id
          ImageUrl
          UserName
          Email
        }
        Timestamp
      }
      Participants {
        Id
        Email
      }
      LiveFeed {
        Id
        Title
        Flag
        Time
        Completed
        AlltingGenerated
        Description
        UsersVoted
        Logo
        VoteOptions {
          Label
          Value
        }
        VoteResults
      }
    }
  }
`;

const updateFlagMutation = gql`
  mutation UpdateFlagMutation($meetingId: ID!, $flag: FlagEnum!) {
    liveFeedbackFlag(meetingId: $meetingId, flag: $flag)
  }
`;

const endLiveMeetingMutation = gql`
  mutation EndLiveMeeting($meetingId: ID!) {
    endLiveMeeting(meetingId: $meetingId) {
      Id
      EndTime
      Status
      Completed
      Length
    }
  }
`;

const innerTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      paper: baseColors.darkMode.light,
    },
    primary: {
      light: baseColors.primary.light,
      main: baseColors.primary.light,
      dark: baseColors.primary.main,
    },
    secondary: {
      light: baseColors.secondary.light,
      main: baseColors.secondary.light,
      dark: baseColors.secondary.main,
    },
  },
});
const innerThemeTeams = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      paper: baseColors.darkModeTeams.light,
    },
    primary: {
      light: baseColors.primary.light,
      main: baseColors.primary.light,
      dark: baseColors.primary.main,
    },
    secondary: {
      light: baseColors.secondary.light,
      main: baseColors.secondary.light,
      dark: baseColors.secondary.main,
    },
  },
});

// TODO: Figure out how and when to dispay energy, how do we also show that a participant joined?
const url = getFrontEndUrl();

export default function Live(props: any) {
  useEffect(
    () => firebase.analytics().logEvent("page_view", { page_title: "Live" }),
    []
  );
  const isLoggedIn: boolean = Boolean(props.isLoggedIn);
  const meetingId = props.match.params.id;
  const history = useHistory();
  const [showEnergyVote, setShowEnergyVote] = useState(true);
  const [copyTip, setCopyTip] = useState(false);
  const [askLength, setAskLength] = useState(false);
  const [askNameDialog, setAskNameDialog] = useState(false);
  const [feedbackAsk, setFeedbackAsk] = useState(false);
  const [lastEnergyAsk, setLastEnergyAsk] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [flagOpen, setFlagOpen] = useState(false);
  const [colors, setColors] = useState(baseColors);
  const [shareOpen, setShareOpen] = useState(false);
  const smallScreen = useMediaQuery(phoneWidth);
  const [timeLeft, setTimeLeft] = useState(0);
  const [hasExtension, setHasExtension] = useState(true);
  const [teamsContext, setTeamsContext] = useState<Context | null>();

  const {
    data: givenFeedback,
    refetch: feedbackRefetch,
    loading: feedbackLoading,
  } = useHasGivenFeedback(meetingId);

  const [updateFlag] = useMutation<
    UpdateFlagMutation,
    UpdateFlagMutationVariables
  >(updateFlagMutation);
  const [update] = useMutation<UpdateMeeting, UpdateMeetingVariables>(
    updateMeeting
  );

  useEffect(() => {
    console.log("in teams effect", currentContext);
    if (currentContext === "teams") {
      setColors({ ...baseColors, darkMode: colors.darkModeTeams });
      teams.meeting.getMeetingDetails((err, meetingDetails) => {
        console.log("error in get meeting details", err);
        console.log("here is the meeting details", meetingDetails);
      });
    }
  }, [currentContext]);

  useEffect(() => {
    if (currentContext === "teams") {
      teams.getContext((context) => {
        setTeamsContext(context);
        console.log(context);
      });
    }
  }, [currentContext]);

  useEffect(
    () =>
      smallScreen || currentContext === "teams"
        ? setHasExtension(true)
        : setHasExtension(checkHasExtension()),
    []
  );

  const [endMeeting] = useMutation(endLiveMeetingMutation);

  const { data, loading, error } = useQuery<
    liveFeedQuery,
    liveFeedQueryVariables
  >(LiveFeedQuery, {
    pollInterval: 1000,
    fetchPolicy: "cache-and-network",
    variables: {
      meetingId: meetingId,
    },
  });

  useEffect(() => {
    if (data != null && isLoggedIn && currentContext !== "teams") {
      if (data?.liveMeeting.Status === StatusEnum.processing) {
        history.push(`/feedback/${meetingId}`);
      } else if (data?.liveMeeting.Status === StatusEnum.done) {
        history.push(`/feedback/${meetingId}`);
      } else if (data?.liveMeeting.Status !== StatusEnum.live) {
        history.push(`/scheduled-meeting/${meetingId}`);
      }
    }
  }, [data?.liveMeeting.Status]);

  useEffect(() => {
    if (
      currentUser != null &&
      currentUser?.id == data?.liveMeeting.Host &&
      data?.liveMeeting.EndTime == null &&
      endTime <= 0
    ) {
      setAskLength(true);
    }
    if (
      (endTime == 0 || endTime != data?.liveMeeting.EndTime) &&
      data?.liveMeeting.EndTime != null
    ) {
      setEndTime(data.liveMeeting.EndTime);
      setTimeLeft((data.liveMeeting.EndTime - new Date().valueOf()) / 1000);
    }
  }, [data?.liveMeeting.EndTime]);

  useEffect(() => {
    if (
      currentUser != null &&
      currentUser?.id == data?.liveMeeting.Host &&
      data?.liveMeeting.Name === "" &&
      !askLength
    ) {
      setAskNameDialog(true);
    }
  }, [data?.liveMeeting.Name, askLength]);

  useEffect(() => (givenFeedback ? setFeedbackAsk(false) : undefined), [
    givenFeedback,
  ]);

  useEffect(() => {
    let interval: any = null;
    if (
      !showEnergyVote &&
      lastEnergyAsk > 0 &&
      (new Date().valueOf() - lastEnergyAsk) / 900000 > 1 //15 min in millis
      // get time since last energy ask response and if over 15 ask for update
    ) {
      setShowEnergyVote(true);
    }

    if (
      timeLeft / 60 <= 5 &&
      endTime > 0 &&
      !givenFeedback &&
      !feedbackLoading
    ) {
      setFeedbackAsk(true);
    }
    if (endTime > 0) {
      interval = setInterval(() => {
        setTimeLeft((endTime - new Date().valueOf()) / 1000);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [data, timeLeft]);

  if (error) console.error(error);

  const liveData = useMemo(
    () => data?.liveMeeting?.LiveFeed?.sort((a, b) => b.Time - a.Time) ?? [],
    [data]
  );

  const energy = useMemo(() => {
    const energyLevels = data?.liveMeeting.EnergyLevels;
    if (energyLevels) {
      return (
        normalize(
          energyLevels.reduce((sum, current) => sum + current.Energy, 0) /
            energyLevels.length,
          1,
          5
        ) * 100
      );
    }
    return -1;
  }, [data?.liveMeeting.EnergyLevels]);

  return (
    <ThemeProvider
      theme={currentContext === "teams" ? innerThemeTeams : innerTheme}
    >
      {isLoggedIn && (
        <LiveIntro loadingDone={data != null && isLoggedIn && !askLength} />
      )}
      <div
        style={{
          width: "100%",
          height: smallScreen ? "100%" : "100vh",
          display: "flex",
          justifyContent: "center",
          backgroundColor: getDarkMode(currentContext).dark,
        }}
      >
        <Snack
          open={!hasExtension}
          onClose={() => setHasExtension(true)}
          message={
            <>
              For the best allting experience we recommend getting our chrome
              extension. You can get it here:{" "}
              <a
                style={{ color: colors.secondary.light }}
                href="https://chrome.google.com/webstore/detail/allting/eoijnapbifidmjechncaakpgkbpdihea"
              >
                Allting extension
              </a>
            </>
          }
        />
        <Dialog
          open={!isLoggedIn}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => console.log("trying to close")}
        >
          <PopUpLogIn successUrl={`/live/${meetingId}`} />
        </Dialog>
        <ConditionalWrapper
          condition={!smallScreen}
          wrapper={(children: any) => (
            <CentralWrapper
              style={{
                height: "93vh",
                overflow:"hidden",
                backgroundColor: getDarkMode(currentContext).dark,
              }}
            >
              {children}
            </CentralWrapper>
          )}
        >
          <FlagDialog
            onUpdate={(f) => {
              updateFlag({ variables: { flag: f as any, meetingId } });
              setFlagOpen(false);
            }}
            open={flagOpen}
            onClose={() => setFlagOpen(false)}
          />
          <Top context={currentContext}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {currentContext !== "teams" && (
                <Tooltip
                  open={copyTip}
                  onClose={() => setCopyTip(false)}
                  disableFocusListener
                  disableTouchListener
                  title="Link copied to clipboard"
                >
                  <Button
                    id="share-button"
                    style={{ color: colors.darkMode.text }}
                    onClick={() => setShareOpen(true)}
                    color="inherit"
                  >
                    <ShareIcon style={{ marginRight: 5 }} /> Share
                  </Button>
                </Tooltip>
              )}
              <Typography
                id="length-button"
                variant="h6"
                onClick={() => setAskLength(true)}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  marginRight: 17,
                  color:
                    timeLeft / 60 < 5
                      ? colors.error.light
                      : colors.darkMode.text,
                }}
              >
                {formatMinutes(timeLeft / 60)}
              </Typography>
            </div>
            <Typography
              style={{
                marginBottom: 5,
                width: "100%",
                justifyContent: "center",
                display: "flex",
                color: colors.darkMode.text,
              }}
            >
              Energy Level
            </Typography>
            <div
              id="energy-bar"
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <ProgressBar
                style={{ width: "80%" }}
                dynamicColor
                progress={energy}
              />
            </div>
          </Top>
          <BottomBackground context={currentContext}>
            <Bottom context={currentContext}>
              {feedbackAsk && (
                <div
                  style={{
                    marginTop: 15,
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  <LiveFeedback
                    meetingId={meetingId}
                    onComplete={async () => {
                      feedbackRefetch();
                      setFeedbackAsk(false);
                    }}
                  />
                </div>
              )}
              {showEnergyVote && (
                <div style={{ marginTop: 15, marginLeft: 5, marginRight: 5 }}>
                  <EnergyVote
                    onUpdate={() => {
                      setShowEnergyVote(false);
                      // Check energy levels every 15 minutes
                      setLastEnergyAsk(new Date().valueOf());
                    }}
                    meetingId={meetingId}
                  />
                </div>
              )}

              <CascadingList list={liveData}>
                <CascadingConsumer>
                  {({
                    item,
                    index,
                  }: {
                    item: liveFeedQuery_liveMeeting_LiveFeed;
                    index: number;
                  }) => (
                    <div
                      style={{
                        marginTop: 15,
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: index === liveData.length - 1 ? 80 : 0,
                      }}
                    >
                      <LiveItem
                        key={item.Id}
                        time={item.Time}
                        title={item.Title}
                        hide={(item as any).hide}
                        flag={item.Flag ?? undefined}
                        logo={item.Logo ?? undefined}
                      >
                        <ItemContent item={item} meetingId={meetingId} />
                      </LiveItem>
                    </div>
                  )}
                </CascadingConsumer>
              </CascadingList>
            </Bottom>
          </BottomBackground>
          <div
            style={{
              position: "fixed",
              left: "calc(50% - 55px)",
              display: "flex",
              bottom: 20,
              width: 110,
              justifyContent:
                currentContext === "teams" ? "center" : "space-between",
            }}
          >
            {currentContext !== "teams" && (
              <IconButton
                id="end-meeting-button"
                onClick={async () => {
                  if (
                    currentUser != null &&
                    currentUser?.id === data?.liveMeeting.Host
                  ) {
                    await endMeeting({ variables: { meetingId } });
                  }
                  if (givenFeedback) {
                    history.push(`/meeting/${meetingId}`);
                  } else {
                    history.push(`/feedback/${meetingId}`);
                  }
                }}
                style={{ backgroundColor: colors.error.dark }}
              >
                <EndIcon style={{ color: "white" }} />
              </IconButton>
            )}
            <IconButton
              id="flag-button"
              style={{ backgroundColor: colors.primary.main }}
              onClick={() => {
                setFlagOpen(true);
              }}
            >
              <FlagIcon style={{ color: "white" }} />
            </IconButton>
          </div>
        </ConditionalWrapper>
        <DialogComp
          open={askLength}
          darkMode
          onClose={() =>
            currentContext === "teams" && endTime === 0
              ? undefined
              : setAskLength(false)
          }
          disabled={currentContext === "teams" && endTime === 0}
          description=""
          title="Meeting End Time"
        >
          <Time
            darkMode
            style={{ width: "100%" }}
            label="What time does your meeting end?"
            value={endTime}
            onChange={(date) => {
              update({
                variables: {
                  attribute: meetingAttributesEnum.EndTime,
                  value: `${date?.valueOf() ?? 0}`,
                  id: meetingId,
                },
              });
              setAskLength(false);
            }}
          />
        </DialogComp>
        <NameDialog
          askNameDialog={askNameDialog}
          setAskNameDialog={setAskNameDialog}
          onConfirm={async (name) => {
            setAskNameDialog(false);
            await update({
              variables: {
                attribute: meetingAttributesEnum.Name,
                value: name,
                id: meetingId,
              },
            });
          }}
        />
        {isLoggedIn && currentContext !== "teams" && (
          <ShareDialog
            darkMode={true}
            meetingId={meetingId}
            host={data?.liveMeeting.Host === currentUser?.id}
            access={data?.liveMeeting.Access ?? MeetingAccessEnum.organization}
            preSelectedEmails={[
              ...(data?.liveMeeting.Participants?.map((value) => value.Email) ??
                []),
              ...((data?.liveMeeting.LiveParticipants?.map(
                (v) => v.User?.Email
              ).filter(Boolean) as string[]) ?? []),
            ]}
            url={`${url}/live/${meetingId}`}
            open={shareOpen}
            onClose={() => setShareOpen(false)}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

function NameDialog({
  askNameDialog,
  setAskNameDialog,
  onConfirm,
}: {
  askNameDialog: boolean;
  setAskNameDialog: (val: boolean) => void;
  onConfirm: (name: string) => void;
}) {
  const [name, setName] = useState("");
  return (
    <ConfirmationDialog
      open={askNameDialog}
      darkMode
      onClose={() => {
        setAskNameDialog(false);
        setName("");
      }}
      onConfirm={() => {
        onConfirm(name);
        setName("");
      }}
      description="Please name the meeting."
      title="Name of Meeting"
      confirmButtonText="Confirm"
    >
      <TextField
        value={name}
        onChange={(event) => setName(event.target.value)}
        style={{ width: "100%" }}
        label="Meeting Name"
      />
    </ConfirmationDialog>
  );
}
