import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const config =
    process.env.REACT_APP_PROJECT_ID === "stage-allting"
        ? {
            apiKey: "AIzaSyDq6tBIILGhndgWUZbHP1hgUlV-Hv1mpoU",
            authDomain: "stage-allting.firebaseapp.com",
            databaseURL: "https://stage-allting.firebaseio.com",
            projectId: "stage-allting",
            storageBucket: "stage-allting.appspot.com",
            messagingSenderId: "1065121282804",
            appId: "1:1065121282804:web:58948050ac31ed1dc6fca1",
            measurementId: "G-5G9745YCB7",
        }
        : {
            apiKey: "AIzaSyDRvU650LzFUMGFRH4-O0b5pi79qGl2lcw",
            authDomain: "prod-allting.firebaseapp.com",
            databaseURL: "https://prod-allting.firebaseio.com",
            projectId: "prod-allting",
            storageBucket: "prod-allting.appspot.com",
            messagingSenderId: "860409467551",
            appId: "1:860409467551:web:c25fc84464d27e043e88aa",
            measurementId: "G-Y9QZZEBHFX",
        };
firebase.initializeApp(config)
export default firebase;
export const storage = firebase.storage
export const analytics = firebase.analytics