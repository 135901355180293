import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import Gauge from "components/Gauge";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import firebase from "../../firebase";
import Box from "@material-ui/core/Box";
import { PieGraph } from "./Graphs";
import UserChip from "components/UserChip";
import ProgressBar from "components/ProgressBar";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import StackedBar from "components/StackedBar";
import { colors, phoneWidth } from "styles";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import styled from "styled-components";
import { useMutation, useQuery } from "react-apollo";
import {
  globalInsightsQuery,
  postMeetingQuery,
  updateMeeting,
} from "./queries";
import LoadingCircle from "components/CircularProgressWithLabel";
import { ReactComponent as Celebrate } from "images/raccons/celebrate.svg";
import { ReactComponent as NoData } from "images/raccons/not_found.svg";
import { ReactComponent as NotFound } from "images/raccons/tail_hold.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHasGivenFeedback } from "./hooks";
import { TopBarContext } from "../../components/TopBar";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { formatMinutes } from "../../utils/formatTime";
import {
  PostMeetingQuery,
  PostMeetingQuery_postMeeting,
  PostMeetingQuery_postMeeting_Questions,
  PostMeetingQuery_postMeeting_SpeakerDistribution,
  PostMeetingQueryVariables,
} from "./__generated__/postMeetingQuery";
import EnergyChart from "./EnergyChart";
import Space from "../../components/Space";
import { Avatar, Chip, IconButton } from "@material-ui/core";
import Table from "../../components/Table";
import { CircularGraphWithLabel } from "../../components/CircularGraphWithLabel";
import {
  UpdateMeeting,
  UpdateMeetingVariables,
} from "./__generated__/UpdateMeeting";
import {
  meetingAttributesEnum,
  StatusEnum,
} from "../../__generated__/globalTypes";
import { UploadFileForm } from "../ScheduledMeeting";
import GlobalInsight, { getTopInsights } from "./GlobalInsight";
import { GlobalInsightsQuery } from "./__generated__/GlobalInsightsQuery";
import ScrollDialog from "../../components/ScrollDialog";
import TopFeedbackScores from "../../components/TopFeedbackScores";
import {
  ItemLoadingCard,
  LargeLoadingCard,
  ListLoadingCard,
} from "./LoadingCard";
import {
  inviteExternalParticipants,
  InviteExternalParticipantsDialog,
} from "../InviteExternalParticipantsDialog";
import {
  InviteExternalParticipants,
  InviteExternalParticipantsVariables,
} from "../__generated__/InviteExternalParticipants";
import { sendMeetingDone } from "../../utils/chromeExtention";
import PostMeetingIntro from "../Intro/PostMeetingIntro";
import BackArrow from "@material-ui/icons/ArrowBack";
import { currentContext } from "../../App";
import Snack from "../../components/Snack";
import { useAlert } from "../../components/Alert";

const useStyles = makeStyles({
  root: {
    width: (props: any) =>
      props.smallScreen ? 300 : props.midScreen ? 245 : 280,
    minHeight: 200,
    height: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const useLargeStyles = makeStyles({
  root: {
    width: (props: any) =>
      props.smallScreen ? 340 : props.midScreen ? 745 : 880,
    height: "calc(100vh - 270px)",
    minHeight: 616,
  },
});

function extensionOpen(path: string): boolean {
  if (path.includes("teams-meeting")) {
    return false;
  }

  try {
    const self = window.self;
    const top = window.top;
    if (self != top || currentContext === "chrome-ext") {
      return true;
    }
    console.log("Not in extension");
    return false;
  } catch (e) {
    console.log("In extension", e);
    return true;
  }
}

const screenSize = "(max-width:1240px)";

export default function () {
  useEffect(
    () =>
      firebase
        .analytics()
        .logEvent("page_view", { page_title: "Post Meeting" }),
    []
  );
  const history = useHistory();
  const { pathname } = useLocation();
  const { id: meetingId } = useParams<{ id: string }>();
  const smallScreen = useMediaQuery(phoneWidth);
  const midScreen = useMediaQuery(screenSize);
  const { data, loading, error, refetch } = useQuery<
    PostMeetingQuery,
    PostMeetingQueryVariables
  >(postMeetingQuery, { variables: { id: meetingId }, pollInterval: 10000 });

  // Feedback Stuff
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const { data: givenFeedback } = useHasGivenFeedback(meetingId);
  const { setOnBackPath } = useContext(TopBarContext);

  // Upload File hooks
  const [uploadOpen, setUploadOpen] = useState(false);
  const [extensionDialog, setExtensionDialog] = useState(false);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const alert = useAlert();
  const [update] = useMutation<UpdateMeeting, UpdateMeetingVariables>(
    updateMeeting
  );
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => (data != null ? setInitialLoading(false) : undefined), [
    data,
  ]);

  // If status is not correct push to other screen
  useEffect(() => {
    if (data != null) {
      if (data?.postMeeting.Status === StatusEnum.live) {
        history.replace(`/live/${meetingId}`);
      } else if (
        ![StatusEnum.processing, StatusEnum.done].includes(
          data?.postMeeting?.Status as any
        )
      ) {
        history.replace(`/scheduled-meeting/${meetingId}`);
      }
    }
  }, [data]);

  useEffect(
    () => (extensionOpen(pathname) ? setExtensionDialog(true) : undefined),
    [pathname]
  );

  // ------------- File Upload -------------------
  function getFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e?.target?.files != null && e?.target?.files[0] != null) {
      const file = e.target.files[0];
      if (!file.type.includes("audio") && !file.type.includes("video")) {
        alert({
          title: "Upload Failed!",
          message: `Only audio files are accepted you uploaded type: ${file.type}`,
        });
      } else {
        setFile(file);
        setUploadFormOpen(true);
      }
    }
  }

  // TODO: add participants to firestore
  async function fileUpload() {
    if (file != null) {
      const ref = firebase
        .storage()
        .ref()
        .child(`${meetingId}.${file.name.split(".")[1]}`);
      const uploadTask = ref.put(file);
      setUploadOpen(true);
      setUploadFormOpen(false);
      firebase.analytics().logEvent("uploaded_recording");
      uploadTask.on(
        "state_changed",
        function (snapshot: {
          bytesTransferred: number;
          totalBytes: number;
          state: any;
        }) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // TODO: fix so we can run download while doing other things maybe set up a store wrapping things that shows upload in the background
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // Handle unsuccessful uploads
          throw error;
        },
        async function () {
          update({
            variables: {
              attribute: meetingAttributesEnum.Status,
              value: "processing",
              id: meetingId,
            },
          });
          await update({
            variables: {
              attribute: meetingAttributesEnum.FileUploaded,
              value: true.toString(),
              id: meetingId,
            },
          });
          await refetch();
          setUploadOpen(false);
        }
      );
    }
  }

  useEffect(() => {
    if (givenFeedback === false && meetingId !== "0-demo-post-meeting") {
      setFeedbackDialogOpen(true);
    }
  }, [givenFeedback]);

  useEffect(() => {
    setOnBackPath(currentContext === "teams" ? "/teams-meetings" : "/meetings");
    return () => setOnBackPath("");
  }, [setOnBackPath]);

  const scores = data?.postMeeting?.OverallScore?.Scores ?? [];
  const meetingScoreAvg =
    scores.length > 0 ? scores.reduce((a, b) => a + b, 0) / scores.length : 0;

  return (
    <div
      style={
        smallScreen
          ? { width: 340 }
          : midScreen
          ? { minWidth: 1000 }
          : { width: 1180 }
      }
    >
      {currentContext === "chrome-ext" && (
        <Snack
          open={extensionDialog}
          onClose={() => setExtensionDialog(false)}
          message={
            <>
              View the meeting in full view instead:
              <Button
                style={{ color: colors.secondary.light, marginLeft: -5 }}
                onClick={() => sendMeetingDone(meetingId)}
              >
                Open new Tab
              </Button>
            </>
          }
        />
      )}
      {currentContext === "teams" && (
        <IconButton
          onClick={() => history.push("/teams-meetings")}
          style={{
            position: smallScreen ? "inherit" : "absolute",
            marginLeft: smallScreen ? 0 : -55,
          }}
        >
          <BackArrow />
        </IconButton>
      )}
      <PostMeetingIntro loadingDone={!initialLoading} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div id="meeting-details-card" style={{ marginTop: 10 }}>
          <Typography style={{ fontWeight: 500 }}>Meeting Details</Typography>
          <StatisticsCard
            length={data?.postMeeting.Length ?? 0}
            name={data?.postMeeting.Name ?? ""}
            numParticipants={data?.postMeeting?.NumParticipants ?? 0}
            meetingScore={meetingScoreAvg / 5}
          />
        </div>
        <div id="engagement-card" style={{ marginTop: 10 }}>
          <Typography style={{ fontWeight: 500 }}>Engagement</Typography>
          <EngagementCard
            initialLoading={initialLoading}
            score={data?.postMeeting?.EngagementScore?.Score}
          />
        </div>
        <div id="productivity-card" style={{ marginTop: 10 }}>
          <Typography style={{ fontWeight: 500 }}>Productivity</Typography>
          <ProductivityCard
            score={data?.postMeeting?.ProductivityScore?.Score}
            initialLoading={initialLoading}
          />
        </div>

        <div id="coaching-card" style={{ marginTop: 10 }}>
          <Typography style={{ fontWeight: 500 }}>Coaching Tips</Typography>
          <CoachingCard
            score={meetingScoreAvg}
            questions={data?.postMeeting.Questions ?? []}
            initialLoading={initialLoading}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          flexWrap: "wrap",
          minHeight: 500,
          height: smallScreen ? "auto" : "100%",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <LargeCard />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div
            id="top-feedback-card"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Top Feedback</Typography>
            <TopFeedback
              questions={data?.postMeeting.Questions ?? []}
              initialLoading={initialLoading}
            />
          </div>
          <div
            id="speaker-dist-card"
            style={{
              display: "flex",
              marginTop: 10,
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography style={{ fontWeight: 500 }}>
              Speaker Distribution
            </Typography>
            <SpeakerCard
              getFile={getFile}
              uploadOpen={uploadOpen}
              initialLoading={initialLoading}
              uploadProgress={uploadProgress}
              progress={data?.postMeeting.ProcessingProgress ?? 0}
              fileUploaded={data?.postMeeting.FileUploaded ?? false}
              speakers={data?.postMeeting.SpeakerDistribution ?? []}
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={feedbackDialogOpen}
        title="You have not given feedback!"
        onClose={() => setFeedbackDialogOpen(false)}
        confirmButtonText="Give Feedback"
        onConfirm={() => history.push(`/feedback/${meetingId}`)}
        description="We don't have any feedback from you in this meeting. If you attended please provide feedback to help your team"
      />
      <UploadFileForm
        open={uploadFormOpen}
        noParticipantsAsk={true}
        onClose={() => {
          setUploadFormOpen(false);
          setFile(null);
        }}
        fileName={file?.name ?? "Error file missing"}
        onConfirm={() => fileUpload()}
        members={data?.postMeeting.NumParticipants ?? 2}
      />
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const smallScreen = useMediaQuery(phoneWidth);
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useQuery<
    PostMeetingQuery,
    PostMeetingQueryVariables
  >(postMeetingQuery, { variables: { id } });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  if (data == null)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <LargeLoadingCard />
        </div>
      </div>
    );
  const scores = data?.postMeeting?.OverallScore?.Scores ?? [];
  const meetingScore =
    scores.length > 0 ? scores.reduce((a, b) => a + b, 0) / scores.length : 0;

  const fontSize = smallScreen ? 10 : "0.875rem";
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Tabs
        style={{ width: smallScreen ? "100%" : 640 }}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab style={{ fontSize }} label="Live" {...a11yProps(0)} />
        <Tab style={{ fontSize }} label="Feedback" {...a11yProps(1)} />
        <Tab
          style={{ fontSize }}
          label="Transcription"
          // disabled={data?.postMeeting.Summary == null}
          {...a11yProps(2)}
        />
        <Tab style={{ fontSize }} label="Participants" {...a11yProps(3)} />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "560px", overflow: "scroll" }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <LiveTab data={data?.postMeeting} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <FeedbackTab data={data?.postMeeting} meetingScore={meetingScore} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <TranscriptionTab data={data?.postMeeting} meetingId={id} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ParticipantsTab
            data={data?.postMeeting}
            meetingId={id}
            refetch={refetch}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

const iconStyle = { fontSize: 41, color: "textSecondary", marginRight: 10 };

function FeedbackTab({
  data,
  meetingScore,
}: {
  data: PostMeetingQuery_postMeeting;
  meetingScore: number;
}) {
  const smallScreen = useMediaQuery(phoneWidth);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography color="textSecondary">Response Rate</Typography>
          <br />
          <ProgressBar
            style={{ width: 300 }}
            progress={(data.FeedbackGiven.length / data.NumParticipants) * 100}
          />
          <Typography
            color="textSecondary"
            style={{ fontSize: 14, marginTop: 10 }}
          >
            {data.FeedbackGiven?.length} of {data.NumParticipants} participants
            provided responses
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: 300,
          }}
        >
          <Typography
            color="textSecondary"
            style={{ marginBottom: 5, textAlign: "center" }}
          >
            Average Meeting Score
          </Typography>
          <div>
            <MoodBadIcon
              style={{
                ...iconStyle,
                color:
                  meetingScore >= 0 && meetingScore <= 1
                    ? colors.primary.light
                    : "textSecondary",
              }}
            />
            <SentimentVeryDissatisfiedIcon
              style={{
                ...iconStyle,
                color:
                  meetingScore > 1 && meetingScore <= 2
                    ? colors.primary.light
                    : "textSecondary",
              }}
            />
            <SentimentSatisfiedIcon
              style={{
                ...iconStyle,
                color:
                  meetingScore > 2 && meetingScore <= 3
                    ? colors.primary.light
                    : "textSecondary",
              }}
            />
            <SentimentSatisfiedAltIcon
              style={{
                ...iconStyle,
                color:
                  meetingScore > 3 && meetingScore <= 4
                    ? colors.primary.light
                    : "textSecondary",
              }}
            />
            <MoodIcon
              style={{
                ...iconStyle,
                color:
                  meetingScore > 4 ? colors.primary.light : "textSecondary",
              }}
            />
          </div>

          <Typography color="textSecondary" style={{ fontSize: 14 }}>
            The average score of your meeting is {meetingScore.toFixed(2)}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: 50,
          marginBottom: 20,
        }}
      >
        <Typography color="textSecondary"> Response Results </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: 350,
          marginLeft: smallScreen ? 0 : 90,
          marginBottom: 15,
        }}
      >
        <ThumbUpAltIcon style={{ color: colors.success.main }} />
        <Typography color="textSecondary">Positive</Typography>
        <RemoveCircleIcon style={{ color: colors.grey.main }} />
        <Typography color="textSecondary">No Response</Typography>
        <ThumbDownAltIcon style={{ color: colors.error.main }} />
        <Typography color="textSecondary">Negative</Typography>
      </div>
      {data.Questions?.map((q) => (
        <ThumbsCircle question={q} />
      ))}
    </div>
  );
}

function LiveTab({ data }: { data?: PostMeetingQuery_postMeeting }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Space height={20} />
      <EnergyChart
        startTime={data?.StartTime ?? 0}
        endTime={data?.EndTime ?? 0}
        energyLevels={
          data?.EnergyLevels?.filter(
            (e) => e.Timestamp <= (data?.EndTime ?? 0)
          ) ?? []
        }
        liveFeed={data?.LiveFeed ?? []}
      />
    </div>
  );
}

const ChipWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
`;

function TranscriptionTab({
  data,
  meetingId,
}: {
  data?: PostMeetingQuery_postMeeting;
  meetingId: string;
}) {
  const history = useHistory();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {data?.Summary != null ? (
        <>
          <Typography
            color="textSecondary"
            style={{ marginBottom: 5, textAlign: "center" }}
          >
            Keywords
          </Typography>
          <ChipWrapper>
            {(data?.Keywords ?? []).map((keyword) => (
              <Chip
                key={keyword}
                style={{ color: colors.text, margin: 5, borderColor: "grey" }}
                label={keyword}
                variant="outlined"
              />
            ))}
          </ChipWrapper>
          <Space height={20} />
          <Typography
            color="textSecondary"
            style={{ marginBottom: 5, textAlign: "center" }}
          >
            Transcription Extracts
          </Typography>
          <p style={{ color: colors.text, paddingLeft: 15, paddingRight: 15 }}>
            <ul>
              {data?.Summary?.split(/\r?\n/g).map((t) => (
                <li style={{ marginTop: 4 }}>{t}</li>
              ))}
            </ul>
          </p>
          <Space height={20} />
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => history.push(`/transcription/${meetingId}`)}
              variant="contained"
              color="primary"
            >
              View Full Transcription
            </Button>
          </div>
        </>
      ) : (
        <Typography
          color="textSecondary"
          style={{ marginBottom: 5, textAlign: "center" }}
        >
          Upload a meeting recording to get access to keywords, auto summary,
          transcription and more.
        </Typography>
      )}
    </div>
  );
}

function ParticipantsTab({
  data,
  meetingId,
  refetch,
}: {
  data?: PostMeetingQuery_postMeeting;
  meetingId: string;
  refetch: () => void;
}) {
  const [inviteParticipantsOpen, setInviteParticipantsOpen] = useState(false);
  const [inviteExternal] = useMutation<
    InviteExternalParticipants,
    InviteExternalParticipantsVariables
  >(inviteExternalParticipants);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Button
        onClick={() => setInviteParticipantsOpen(true)}
        color="primary"
        variant="contained"
      >
        Add Participants
      </Button>
      <Table columns={participantsColumns} data={data?.Participants ?? []} />
      <InviteExternalParticipantsDialog
        onClose={() => setInviteParticipantsOpen(false)}
        open={inviteParticipantsOpen}
        memberEmails={data?.Participants?.map((p) => p.Email) ?? []}
        onConfirm={async (emails) => {
          setInviteParticipantsOpen(false);
          await inviteExternal({ variables: { emails, meetingId } });
          if (emails.length > 0)
            firebase.analytics().logEvent("invited_users", {
              count: emails.length,
              page_title: "Post Meeting",
            });
          await refetch();
        }}
      />
    </div>
  );
}

const participantsColumns = [
  {
    Header: "Participant",
    accessor: "UserName",
    mobile: true,
    Cell: ({
      cell: {
        row: { original },
      },
    }: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={original.ImageUrl ?? undefined} alt={""} />
        <Typography style={{ marginLeft: 10 }}>{original.UserName}</Typography>
      </div>
    ),
  },
];

function ThumbsCircle({
  question,
}: {
  question: PostMeetingQuery_postMeeting_Questions;
}) {
  const barData: Record<
    string,
    { color: string; name: string; id: number; value: number }
  > = {
    "1": {
      name: "Thumb up",
      value: 0,
      id: 1,
      color: colors.success.main,
    },
    "0": {
      name: "No response",
      value: 0,
      id: 0,
      color: colors.grey.main,
    },
    "-1": {
      name: "Thumb down",
      value: 0,
      id: -1,
      color: colors.error.main,
    },
  };

  question.Scores.forEach((s) => {
    if (barData[s.toString()] != null) {
      barData[s.toString()].value += 1;
    } else {
      console.log("Wrong value: ", s);
    }
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div style={{ width: 300, height: 20, marginBottom: 30 }}>
          <Typography color="textSecondary" style={{ fontSize: 14 }}>
            {question.Name}
          </Typography>
          <StackedBar data={Object.values(barData)} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 300,
            marginTop: 6,
            justifyContent: "space-evenly",
          }}
        >
          <FlexDiv>
            <ThumbUpAltIcon />
            <Typography style={{ marginLeft: 10 }}>
              {barData["1"].value}
            </Typography>
          </FlexDiv>
          <FlexDiv>
            <RemoveCircleIcon />
            <Typography style={{ marginLeft: 10 }}>
              {barData["0"].value}
            </Typography>
          </FlexDiv>
          <FlexDiv>
            <ThumbDownAltIcon />
            <Typography style={{ marginLeft: 10 }}>
              {barData["-1"].value}
            </Typography>
          </FlexDiv>
        </div>
      </div>
    </div>
  );
}

const FlexDiv = styled.div`
  display: flex;
`;

export function StatisticsCard({
  numParticipants,
  name,
  length,
  meetingScore,
}: {
  numParticipants: number;
  name: string;
  length: number;
  meetingScore: number;
}) {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useStyles({ midScreen, smallScreen });

  return (
    <Card className={classes.root} style={{ height: 210 }}>
      <CardContent>
        <Typography
          variant="subtitle2"
          style={{
            marginTop: -5,
            marginBottom: 5,
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <PeopleOutlineIcon />
              <Typography
                variant="h6"
                style={{
                  marginLeft: 10,
                  width: 40,
                  whiteSpace: "nowrap",
                  fontSize: 16,
                }}
              >
                {numParticipants}
              </Typography>
            </div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              style={{ marginBottom: 10, fontSize: 14, whiteSpace: "nowrap" }}
            >
              Participants
            </Typography>

            <div style={{ display: "flex", alignItems: "center" }}>
              <AccessTimeIcon />
              <Typography
                variant="h6"
                style={{
                  marginLeft: 10,
                  width: 40,
                  whiteSpace: "nowrap",
                  fontSize: 16,
                }}
              >
                {formatMinutes(length / 60)}
              </Typography>
            </div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              style={{ fontSize: 14, whiteSpace: "nowrap" }}
            >
              Meeting Length
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: midScreen ? 5 : 30,
            }}
          >
            <CircularGraphWithLabel
              progress={meetingScore * 100}
              max={5}
              colorMode="main"
            />
            <Typography
              color="textSecondary"
              style={{ textAlign: "center", fontSize: 14 }}
            >
              Meeting Score
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export function EngagementCard({
  score,
  initialLoading,
}: {
  score?: number;
  initialLoading?: boolean;
}) {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useStyles({ midScreen, smallScreen });

  if (initialLoading)
    return (
      <Card className={classes.root} style={{ height: 210 }}>
        <CardContent>
          <ItemLoadingCard />
        </CardContent>
      </Card>
    );

  return (
    <Card className={classes.root} style={{ height: 210 }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {score == null ? (
            <div>
              <Typography
                className={classes.title}
                color="textSecondary"
                style={{ marginTop: 20, fontSize: 16 }}
              >
                Missing data. More users need to give feedback to be able to
                calculate a score.
              </Typography>
            </div>
          ) : (
            <>
              <Gauge progress={score * 100} />
              <Typography
                className={classes.title}
                color="textSecondary"
                style={{ marginTop: 5, fontSize: 13 }}
              >
                {getEngagementText(score)}
              </Typography>
            </>
          )}
        </div>
      </CardContent>
      {/*<CardActions>*/}
      {/*  <Button size="small" style={{ marginLeft: "auto" }}>*/}
      {/*    Info*/}
      {/*  </Button>*/}
      {/*</CardActions>*/}
    </Card>
  );
}

function getEngagementText(score: number) {
  if (score < 0.3) {
    return "Ouch, that is a low score. Check the feedback and coaching tips for how to improve!";
  } else if (score < 0.7) {
    return "There is plenty of room for growth here. Check out coaching tips for specific things you can do.";
  } else if (score < 0.9) {
    return "This is a good score! But there is still room to grow!";
  } else {
    return "Great Job! Your team was engaged during the meeting.";
  }
}

export function ProductivityCard({
  score,
  initialLoading,
}: {
  score?: number;
  initialLoading?: boolean;
}) {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useStyles({ midScreen, smallScreen });

  if (initialLoading)
    return (
      <Card className={classes.root} style={{ height: 210 }}>
        <CardContent>
          <ItemLoadingCard />
        </CardContent>
      </Card>
    );

  return (
    <Card className={classes.root} style={{ height: 210 }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {score == null ? (
            <div>
              <Typography
                className={classes.title}
                color="textSecondary"
                style={{ marginTop: 20, fontSize: 16 }}
              >
                Missing data. More users to need give feedback to be able to
                calculate a score.
              </Typography>
            </div>
          ) : (
            <>
              <Gauge progress={score * 100} />
              <Typography
                className={classes.title}
                color="textSecondary"
                style={{ marginTop: 5, fontSize: 13 }}
              >
                {getEngagementText(score)}
              </Typography>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export function CoachingCard({
  score,
  questions,
  initialLoading,
}: {
  questions: PostMeetingQuery_postMeeting_Questions[];
  initialLoading?: boolean;
  score: number;
}) {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useStyles({ midScreen, smallScreen });
  const [open, setOpen] = useState(false);
  const { data: globalInsightsData, loading } = useQuery<GlobalInsightsQuery>(
    globalInsightsQuery
  );
  const topInsights = useMemo(
    () => getTopInsights(questions, globalInsightsData?.globalInsights ?? []),
    [globalInsightsData, questions]
  );

  if (initialLoading) {
    return (
      <Card className={classes.root} style={{ maxHeight: 210 }}>
        <CardContent>
          <ListLoadingCard />
        </CardContent>
      </Card>
    );
  }

  if (topInsights.length === 0 && globalInsightsData?.globalInsights != null)
    return (
      <Card className={classes.root} style={{ maxHeight: 210 }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {(questions[0]?.Scores.length ?? 0) > 0 && score > 3 ? (
              <>
                <Celebrate style={{ width: 140, height: 140 }} />
                <Typography color="textSecondary" style={{ marginTop: 10 }}>
                  Wow! You are doing great!
                </Typography>
              </>
            ) : (
              <>
                <NoData style={{ width: 140, height: 140 }} />
                <Typography color="textSecondary" style={{ marginTop: 10 }}>
                  Not enough data received yet...
                </Typography>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    );
  return (
    <Card className={classes.root} style={{ maxHeight: 210 }}>
      <CardContent>
        <div>
          {topInsights.slice(0, 3).map((i, index) => (
            <div style={{ marginTop: index === 0 ? 0 : 10 }}>
              <GlobalInsight insight={i} hideText />
            </div>
          ))}
        </div>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => setOpen(true)}
          size="small"
          style={{ marginLeft: "auto" }}
        >
          More
        </Button>
      </CardActions>
      <ScrollDialog
        open={open}
        title="Coaching Tips"
        handleClose={() => setOpen(false)}
      >
        {topInsights.map((i, index) => (
          <div style={{ marginTop: index === 0 ? 0 : 20 }}>
            <GlobalInsight insight={i} />
          </div>
        ))}
      </ScrollDialog>
    </Card>
  );
}

export function TopFeedback({
  questions,
  initialLoading,
}: {
  questions: PostMeetingQuery_postMeeting_Questions[];
  initialLoading?: boolean;
}) {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useStyles({ midScreen, smallScreen });

  if (initialLoading) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <ListLoadingCard />
        </CardContent>
      </Card>
    );
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <div style={{ marginLeft: -10 }}>
          <TopFeedbackScores
            width={midScreen ? 247 : 280}
            questions={questions}
          />
        </div>
      </CardContent>
    </Card>
  );
}

const pieColors = [
  colors.success.main,
  colors.warning.main,
  colors.error.main,
  colors.info.main,
];

export function SpeakerCard({
  speakers,
  fileUploaded,
  uploadOpen,
  getFile,
  uploadProgress,
  initialLoading,
  progress,
}: {
  speakers: PostMeetingQuery_postMeeting_SpeakerDistribution[];
  fileUploaded: Boolean;
  uploadOpen: Boolean;
  getFile: (val: any) => void;
  uploadProgress: number;
  initialLoading: boolean;
  progress?: number;
}) {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useStyles({ midScreen, smallScreen });
  const inputRef = useRef<HTMLInputElement | null>(null);

  if (initialLoading) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <ItemLoadingCard />
        </CardContent>
      </Card>
    );
  }

  const pieData = speakers.map((s) => ({
    color: pieColors[s.Tag % speakers.length],
    id: s.Tag,
    name: s.Name,
    value: s.WordsSpoken,
    user: s.User,
  }));

  if (speakers.length === 0)
    return (
      <Card className={classes.root}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <NotFound style={{ width: 150, height: 150 }} />
          {fileUploaded ? (
            <>
              <Typography variant="subtitle2" color="textSecondary">
                Parsing audio
              </Typography>
              <ProgressBar
                style={{ width: 200, marginTop: 20 }}
                progress={progress ?? 0}
              />
            </>
          ) : (
            <>
              <input
                style={{ display: "none" }}
                type="file"
                ref={inputRef}
                onChange={(e) => getFile(e)}
              />
              <Typography variant="subtitle2" color="textSecondary">
                {uploadOpen
                  ? "Uploading file"
                  : "Upload meeting recording to get more data! English only."}
              </Typography>
              {uploadOpen ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <LoadingCircle value={uploadProgress} />
                </div>
              ) : (
                <Button
                  style={{
                    width: 150,
                    marginTop: 20,
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => inputRef?.current?.click()}
                >
                  Upload File
                </Button>
              )}
            </>
          )}
        </CardContent>
      </Card>
    );
  return (
    <Card className={classes.root}>
      <CardContent>
        <div style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
          <PieGraph data={pieData} />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: -5,
            flexWrap: "wrap",
          }}
        >
          {pieData.map((speaker: any) => (
            <div style={{ margin: 5 }}>
              <UserChip
                color={speaker.color}
                label={speaker.user?.UserName ?? speaker.name}
                src={speaker.user?.ImageUrl ?? ""}
              />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export function LargeCard() {
  const midScreen = useMediaQuery(screenSize);
  const smallScreen = useMediaQuery(phoneWidth);
  const classes = useLargeStyles({ midScreen, smallScreen });

  return (
    <Card className={classes.root}>
      <FullWidthTabs />
    </Card>
  );
}
