import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import Table from "components/Table";
import { AdaptiveWrapper } from "components/Wrappers";
import { colors, phoneWidth } from "styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useMutation, useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { currentUser } from "../App";
import LoadingTable from "../components/LoadingTable";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { UsersInvite } from "../components/UserSearch";
import { CreateTeam, CreateTeamVariables } from "./__generated__/CreateTeam";
import { useHistory } from "react-router-dom";
import validateEmail from "../utils/validateEmail";
import Space from "../components/Space";
import { ReactComponent as NotFound } from "../images/raccons/not_found.svg";
import firebase from "../firebase";

const query = gql`
  query getTeams {
    getUsersTeams {
      Id
      Name
      Members
    }
  }
`;

const mutation = gql`
  mutation CreateTeam(
    $name: String!
    $externalMemberEmails: [String!]!
    $internalMembers: [String!]!
  ) {
    createTeam(
      name: $name
      externalMemberEmails: $externalMemberEmails
      internalMembers: $internalMembers
    ) {
      Id
      Members
      Name
      OrgId
      PendingInviteEmails
    }
  }
`;

export default function Teams(props: { history: any }) {
  useEffect(
    () =>
      firebase.analytics().logEvent("page_view", { page_title: "All Teams" }),
    []
  );
  const { data, loading } = useQuery(query, {
    pollInterval: 10000,
    fetchPolicy: "cache-and-network",
  });
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const [
    createTeam,
    { loading: mutationLoading, data: mutationData },
  ] = useMutation<CreateTeam, CreateTeamVariables>(mutation);
  const [createTeamDialog, setCreateTeamDialog] = useState(false);
  const smallScreen = useMediaQuery(phoneWidth);
  const history = useHistory();

  useEffect(() => (firstLoadDone ? undefined : setFirstLoadDone(!loading)), [
    loading,
  ]);

  useEffect(() => {
    if (mutationData != null)
      history.push(`team/${mutationData?.createTeam.Id}`);
  }, [mutationData, loading]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <WrapperSelect>
        {firstLoadDone && (data ?? []).length === 0 && <NoData />}
        <Button
          color="primary"
          variant="contained"
          style={{
            alignSelf:
              firstLoadDone && (data ?? []).length === 0
                ? "center"
                : "flex-end",
            margin: 10,
          }}
          onClick={() => setCreateTeamDialog(true)}
        >
          Create New Team
        </Button>
        {data == null && loading && (
          <div style={{ marginTop: 20 }}>
            <LoadingTable width={smallScreen ? 400 : 1000} />
          </div>
        )}
        {data != null && (
          <Table
            columns={columns}
            data={data?.getUsersTeams ?? []}
            onRowClick={(row) => props.history.push(`/team/${row.Id}`)}
          />
        )}
      </WrapperSelect>
      <CreateTeamDialog
        onClose={() => setCreateTeamDialog(false)}
        open={createTeamDialog}
        // Adding current user to team
        members={currentUser.email != null ? [currentUser.email] : []}
        onConfirm={async (props) => {
          setCreateTeamDialog(false);
          await createTeam({
            variables: {
              name: props.name,
              externalMemberEmails: props.externalEmails,
              internalMembers: props.members.filter(Boolean),
            },
          });
          firebase.analytics().logEvent("create_team", {
            page_title: "Create Teams",
            count: props.externalEmails.length,
          });
        }}
      />
    </div>
  );
}

function WrapperSelect({ children }: { children: React.ReactNode }) {
  const smallScreen = useMediaQuery(phoneWidth);
  if (smallScreen) return <div>{children}</div>;
  return <AdaptiveWrapper>{children}</AdaptiveWrapper>;
}

function CreateTeamDialog({
  onClose,
  members,
  open,
  onConfirm,
}: {
  onClose: () => void;
  open: boolean;
  members: string[];
  onConfirm: (props: {
    name: string;
    members: string[];
    externalEmails: string[];
  }) => void;
}) {
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
  const [teamName, setTeamName] = useState("");
  const [teamNameError, setTeamNameError] = useState<string | null>(null);
  const smallScreen = useMediaQuery(phoneWidth);
  return (
    <ConfirmationDialog
      confirmButtonText="Create"
      description="Create a new Team! Press Enter to create a new email if you can't find the user in the list"
      disabled={
        teamName === "" ||
        membersToAdd.filter(validateEmail).length < membersToAdd.length
      }
      onClose={() => {
        onClose();
        setMembersToAdd([]);
        setTeamName("");
      }}
      onConfirm={() => {
        if (teamName.length < 3) {
          setTeamNameError(
            "Name is to short. Needs to be at least 3 characters"
          );
        } else if (teamName.length > 20) {
          setTeamNameError("Name is to long, max 20 characters");
        } else {
          onConfirm({
            name: teamName,
            members: [currentUser.id],
            externalEmails: membersToAdd,
          });
          setMembersToAdd([]);
        }
      }}
      open={open}
      title="Add Member"
    >
      <form
        style={{ width: smallScreen ? 300 : 500 }}
        noValidate
        autoComplete="off"
        onSubmit={() => null}
      >
        {membersToAdd.filter(validateEmail).length < membersToAdd.length && (
          <Typography gutterBottom style={{ color: colors.error.main }}>
            One of the emails are invalid!
          </Typography>
        )}
        {teamNameError != null && (
          <Typography gutterBottom style={{ color: colors.error.main }}>
            {teamNameError}
          </Typography>
        )}
        <TextField
          style={{ width: "100%" }}
          label="Team Name"
          required
          value={teamName}
          onChange={(event) => setTeamName(event.target.value)}
        />
        <Space height={30} />
        <UsersInvite
          chosenUserEmails={members}
          onChange={setMembersToAdd}
          selectedEmails={membersToAdd}
        />
      </form>
    </ConfirmationDialog>
  );
}

function NoData() {
  const smallScreen = useMediaQuery(phoneWidth);
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <NotFound style={{ width: 320, height: 320 }} />
      <Typography style={{ maxWidth: 400 }} gutterBottom>
        There seems to be no teams here. Why don't you create one to start
        improving your meeting culture!
      </Typography>
    </div>
  );
}

const columns = [
  {
    Header: "Team Name",
    accessor: "Name",
    mobile: true,
    Cell: ({ cell: { value } }: any) => (
      <Typography style={{ fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    Header: "Members",
    accessor: "Members.length",
    right: true,
    cellRight: true,
    // Cell: ({ cell: { value } }: any) => <div>{value?.length}</div>,
  },
];
