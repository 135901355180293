import React, { useEffect, useState } from "react";
import * as teams from "@microsoft/teams-js";
import { Context } from "@microsoft/teams-js";
import { getFrontEndUrl } from "../config";
import { currentContext } from "../App";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import {
  CreateOrJoinTeamsMeeting,
  CreateOrJoinTeamsMeetingVariables,
} from "./__generated__/CreateOrJoinTeamsMeeting";
import { colors } from "../styles";
import { ReactComponent as AlltingLog } from "../images/raccoon_orange.svg";
import { LogoFont } from "../components/LogoFont";
import { CircularProgress, Typography } from "@material-ui/core";

const mutation = gql`
  mutation CreateOrJoinTeamsMeeting(
    $teamsMeetingId: String!
    $timezone: String!
  ) {
    createOrJoinTeamsMeeting(
      teamsMeetingId: $teamsMeetingId
      timezone: $timezone
    )
  }
`;

teams.settings.initialize();
export default function TeamsConfig() {
  const [ctx, setContext] = useState<Context | null>();
  const [loading, setLoading] = useState(true);

  const [createOrJoinMeeting] = useMutation<
    CreateOrJoinTeamsMeeting,
    CreateOrJoinTeamsMeetingVariables
  >(mutation);

  async function getOrCreateMeeting(teamsMeetingId?: string): Promise<string> {
    if (teamsMeetingId != null) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { errors } = await createOrJoinMeeting({
        variables: { teamsMeetingId, timezone },
      });
      console.log("got data, here are error", errors);
      return teamsMeetingId;
    }
    throw Error("No Teams Meeting Id Found");
  }

  async function setup() {
    const teamsMeetId = ctx?.meetingId;
    await getOrCreateMeeting(teamsMeetId);
    teams.settings.setValidityState(true);
    setLoading(false);
    teams.settings.registerOnSaveHandler((saveEvent) => {
      teams.settings.setSettings({
        websiteUrl: getFrontEndUrl(),
        contentUrl: `${getFrontEndUrl()}/live/${teamsMeetId}`,
        entityId: "liveMeeting",
        suggestedDisplayName: "Allting",
      });
      saveEvent.notifySuccess();
    });
  }

  useEffect(() => {
    if (currentContext === "teams") {
      teams.getContext((context) => setContext(context));
    }
  }, [currentContext]);

  useEffect(() => {
    if (ctx != null) {
      setup().then(() => setLoading(false));
    }
  }, [ctx]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: colors.darkModeTeams.dark,
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 30,
          marginBottom: 60,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AlltingLog style={{ width: 60, height: 60 }} />
        <LogoFont>Allting</LogoFont>
        {loading ? (
          <>
            <CircularProgress
              style={{ marginTop: 50, width: 50, height: 50, marginBottom: 20 }}
              color="primary"
            />
            Please wait while we are setting things up for you.
          </>
        ) : (
          <Typography variant="h6" style={{ marginTop: 30 }}>
            Click "Save" to add the Allting Tab to this meeting
          </Typography>
        )}
      </div>
    </div>
  );
}
