import React from "react";
import { DateTimePicker, TimePicker } from "@material-ui/pickers";
import { IconButton, InputAdornment, InputLabel } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/Event";
import ClockIcon from "@material-ui/icons/AccessTime";
import Space from "./Space";
import { colors } from "../styles";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  label: string;
  style?: Object;
  value: number;
  onChange: (date: MaterialUiPickersDate) => void;
  darkMode?: boolean;
};

export default function DateTime(props: Props) {
  const { label, style, value, onChange } = props;
  return (
    <div style={style}>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <DateTimePicker
        style={{ width: "100%" }}
        ampm={false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        format="MM/DD/YYYY, HH:mm"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export function Time(props: Props) {
  const { label, style, value, onChange, darkMode } = props;

  return (
    <div style={style}>
      <InputLabel
        style={{ color: darkMode ? colors.darkMode.text : colors.text }}
        htmlFor="age-simple"
      >
        {label}
      </InputLabel>
      <Space height={10} />
      <TimePicker
        style={{
          width: "100%",
          color: darkMode ? colors.darkMode.text : colors.text,
        }}
        ampm={Boolean(new Date().toLocaleDateString().match(/am|pm/i))}
        InputProps={{
          style: {
            color: darkMode ? colors.darkMode.text : colors.text,
            borderBottomColor: darkMode ? colors.darkMode.text : colors.text,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <ClockIcon
                  style={{
                    color: darkMode ? colors.darkMode.text : colors.text,
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        format="HH:mm"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
