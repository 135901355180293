import React from "react";
// import { styled } from "@material-ui/styles";
import styled from "styled-components";
import { phoneWidth } from "styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Paper } from "@material-ui/core";
import { currentContext } from "../App";

const CentralWrapper = styled(Paper)({
  display: "flex",
  margin: 10,
  flex: 1,
  maxWidth: 800,
  padding: 20,
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
});

const LargeScreenWrapper = styled(Paper)`
  display: flex;
  margin: 20px;
  padding: 10px;
  width: 1000px;
  min-height: 95vh;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

const RowWrapper = styled("div")({
  display: "flex",
  margin: 10,
  maxWidth: 800,
  padding: 20,
  alignItems: "center",
  backgroundColor: "white",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
});

const ExternalWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  width: "100%",
});

export function AdaptiveWrapper({ children }: { children: any }) {
  const phone = useMediaQuery(phoneWidth);
  // const tablet = useMediaQuery(tabletWidth);

  if (phone) return <CentralWrapper>{children}</CentralWrapper>;

  return <LargeScreenWrapper>{children}</LargeScreenWrapper>;
}

export default function ViewWrapper({
  children,
  exclude,
  minimized,
}: {
  children: any;
  exclude: boolean;
  minimized: boolean;
}) {
  const smallScreen = useMediaQuery(phoneWidth);

  return (
    <div
      style={
        smallScreen
          ? {
              width: "100vw",
              height:
                currentContext === "teams" ? "100vh" : "calc(100vh - 56px)",
              marginTop: currentContext === "teams" ? 0 : 56,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }
          : {
              marginLeft: exclude ? 0 : minimized ? 50 : 200,
              width: exclude
                ? "100vw"
                : minimized
                ? "calc(100vw-50px)"
                : "calc(100vw-200px)",
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }
      }
    >
      {children}
    </div>
  );
}

export { CentralWrapper, LargeScreenWrapper, RowWrapper, ExternalWrapper };
