import React, { useEffect, useMemo, useState } from "react";
import { useGetMeetings } from "./hooks";
import { Button, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Table from "components/Table";
import { AdaptiveWrapper } from "components/Wrappers";
import { colors, phoneWidth } from "styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MeetingsQuery_meeting } from "./__generated__/MeetingsQuery";
import LoadingTable from "../../components/LoadingTable";
import { formatMinutes } from "../../utils/formatTime";
import ProgressBar from "../../components/ProgressBar";
import { ReactComponent as NotFound } from "../../images/raccons/celebrate.svg";
import CreateOrgDialog from "../../components/CreateOrgDialog";
import useGetUser from "../useGetUser";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { StatusEnum } from "../../__generated__/globalTypes";
import { UsersInvite } from "../../components/UserSearch";
import validateEmail from "../../utils/validateEmail";
import { useMutation } from "react-apollo";
import {
  hasExtension as checkHasExtension,
  hasExtRelevantBrowserOrDevice,
} from "../../utils/chromeExtention";
import {
  InviteExternalParticipantsToOrg,
  InviteExternalParticipantsToOrgVariables,
} from "../../components/__generated__/InviteExternalParticipantsToOrg";
import { inviteExternalToOrgMutation } from "../../components/ShareDialog";
import Snack, { useSnack } from "../../components/Snack";
import firebase from "../../firebase";
import styled from "styled-components";
import { CircularGraphWithLabel } from "../../components/CircularGraphWithLabel";
import UserRequest from "../../components/UserRequest";
import MeetingsIntro from "../Intro/MeetingsIntro";
import { currentContext } from "../../App";

const TableTitle = styled(Typography)`
  margin-left: 15px;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
  color: ${colors.secondary.dark};
`;

function checkJoinLink(link: string) {
  return !(
    link === "" ||
    !(
      link.includes("app.allting.co") ||
      link.includes("alltinglabs.com") ||
      link.includes("prod-allting.firebaseapp")
    )
  );
}

export default function Meetings(props: { history: any }) {
  useEffect(
    () =>
      firebase
        .analytics()
        .logEvent("page_view", { page_title: "All Meetings" }),
    []
  );
  const { pathname } = useLocation();
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const [createOrg, setCreateOrg] = useState(false);
  const [hasExtension, setHasExtension] = useState(true);
  const history = useHistory();
  const inviteToOrgMatch = useRouteMatch("/meetings/invite-to-org");
  const [joinLink, setJoinLink] = useState("");
  const [invalidJoinLink, setInvalidJoinLink] = useState(false);
  const joinMeetingMatch = useRouteMatch("/meetings/join");
  const [inviteExternalToOrg] = useMutation<
    InviteExternalParticipantsToOrg,
    InviteExternalParticipantsToOrgVariables
  >(inviteExternalToOrgMutation);
  const [
    currentMeeting,
    setCurrentMeeting,
  ] = useState<null | MeetingsQuery_meeting>();
  const { data, loading, refetch: meetingsRefetch } = useGetMeetings();
  const {
    data: notCompletedMeetings,
    loading: completedLoading,
  } = useGetMeetings(false, currentContext === "teams");
  const { user, refetch: userRefetch } = useGetUser();
  const smallScreen = useMediaQuery(phoneWidth);
  useEffect(
    () =>
      firstLoadDone
        ? undefined
        : setFirstLoadDone(!loading && !completedLoading),
    [loading, completedLoading]
  );
  function allRefetch() {
    userRefetch();
    meetingsRefetch();
  }
  useEffect(() => window.scrollTo(0, 1), [firstLoadDone]);

  // Check if user has chrome extension or not
  useEffect(
    () =>
      pathname.includes("teams-meetings") ||
      smallScreen ||
      !hasExtRelevantBrowserOrDevice() ||
      currentContext != null
        ? undefined
        : setHasExtension(checkHasExtension()),
    [currentContext]
  );

  useEffect(() => {
    if (
      data != null &&
      currentMeeting === undefined &&
      currentContext !== "teams"
    ) {
      const currMeeting = notCompletedMeetings.find(
        (v) => v.Status === StatusEnum.live
      );
      if (currMeeting != null) {
        setCurrentMeeting(currMeeting);
      }
    }
  }, [data]);

  // use Effect check if user has a org and reload on close
  useEffect(() => {
    if (user?.Org?.Id == null && user != null) setCreateOrg(true);
  }, [user]);
  function closeCreate() {
    setCreateOrg(false);
    userRefetch();
  }
  function inviteUsersToOrg(emails: string[]) {
    inviteExternalToOrg({ variables: { emails } });
    firebase.analytics().logEvent("invite_new_user", {
      count: emails.length,
      page_title: "Invite to Org, Meetings",
    });
    props.history.push("/meetings");
  }

  if (firstLoadDone && notCompletedMeetings.length === 0 && data.length === 0)
    return (
      <>
        <Snack
          open={!hasExtension}
          onClose={() => setHasExtension(true)}
          message={
            <>
              For the best Allting experience we recommend getting our chrome
              extension. You can get it here:{" "}
              <a
                style={{ color: colors.secondary.light }}
                href="https://chrome.google.com/webstore/detail/allting/eoijnapbifidmjechncaakpgkbpdihea"
              >
                Allting extension
              </a>
            </>
          }
        />
        <>
          <MeetingsIntro loadingDone={firstLoadDone && !createOrg} />
          {user?.Requests?.map((req) => (
            <UserRequest
              Id={req.Id}
              Label={req.Label}
              refetch={allRefetch}
              Type={req.Type}
            />
          ))}
          <InviteUsers
            onClose={() => props.history.push("/meetings")}
            open={inviteToOrgMatch != null}
            onConfirm={inviteUsersToOrg}
          />
          <ConfirmationDialog
            open={currentMeeting != null}
            onClose={() => setCurrentMeeting(null)}
            title="Ongoing Meeting"
            description={`${currentMeeting?.Name} is currently ongoing. Do you want to join this meeting?`}
            onConfirm={() => props.history.push(`/live/${currentMeeting?.Id}`)}
            confirmButtonText="Join Meeting"
          />
          <ConfirmationDialog
            open={joinMeetingMatch != null}
            onClose={() => props.history.push("/meetings")}
            title="Join Meeting"
            disabled={joinLink.length === 0}
            description={
              <div style={{ width: "100%" }}>
                <p>To join a meeting paste the Allting link below.</p>
                {invalidJoinLink && (
                  <p style={{ color: colors.error.main }}>
                    Invalid meeting link.
                  </p>
                )}
                <TextField
                  label="Meeting Link"
                  error={invalidJoinLink}
                  style={{ width: "100%" }}
                  value={joinLink}
                  onChange={(event) => setJoinLink(event.target.value)}
                />
              </div>
            }
            onConfirm={() => {
              if (checkJoinLink(joinLink)) {
                const urlSplit = joinLink.split("/");
                const newMeetingId = urlSplit[urlSplit.length - 1];
                props.history.push(`/live/${newMeetingId}`);
              } else {
                setInvalidJoinLink(true);
              }
            }}
            confirmButtonText="Join"
          />
          <CreateOrgDialog open={createOrg} onClose={closeCreate} />
        </>
        <NoData
          hasOrg={user?.Org?.Id != null}
          setShowOrgDialog={setCreateOrg}
        />
      </>
    );

  if (smallScreen)
    return (
      <>
        <>
          {user?.Requests?.map((req) => (
            <UserRequest
              Id={req.Id}
              Label={req.Label}
              refetch={allRefetch}
              Type={req.Type}
            />
          ))}
          <InviteUsers
            onClose={() => props.history.push("/meetings")}
            open={inviteToOrgMatch != null}
            onConfirm={inviteUsersToOrg}
          />
          <ConfirmationDialog
            open={currentMeeting != null}
            onClose={() => setCurrentMeeting(null)}
            title="Ongoing Meeting"
            description={`${currentMeeting?.Name} is currently ongoing. Do you want to join this meeting?`}
            onConfirm={() => props.history.push(`/live/${currentMeeting?.Id}`)}
            confirmButtonText="Join Meeting"
          />
          <CreateOrgDialog open={createOrg} onClose={closeCreate} />
          <ConfirmationDialog
            open={joinMeetingMatch != null}
            onClose={() => props.history.push("/meetings")}
            title="Join Meeting"
            disabled={joinLink.length === 0}
            description={
              <div style={{ width: "100%" }}>
                <p>To join a meeting paste the allting link below.</p>
                {invalidJoinLink && (
                  <p style={{ color: colors.error.main }}>
                    Invalid meeting link.
                  </p>
                )}
                <TextField
                  label="Meeting Link"
                  error={invalidJoinLink}
                  style={{ width: "100%" }}
                  value={joinLink}
                  onChange={(event) => setJoinLink(event.target.value)}
                />
              </div>
            }
            onConfirm={() => {
              if (checkJoinLink(joinLink)) {
                const urlSplit = joinLink.split("/");
                const newMeetingId = urlSplit[urlSplit.length - 1];
                props.history.push(`/live/${newMeetingId}`);
              } else {
                setInvalidJoinLink(true);
              }
            }}
            confirmButtonText="Join"
          />
        </>
        {currentContext === "teams" && user?.Org?.Id != null && (
            <Button
                style={{
                  marginLeft: 15,
                  marginTop: 10,
                  alignSelf: "flex-start",
                }}
                color="primary"
                variant="contained"
                onClick={() => history.push("/meetings/invite-to-org")}
            >
              Add Colleagues
            </Button>
        )}
        <MobileView
          history={props.history}
          data={data}
          loading={loading}
          completedData={notCompletedMeetings}
          completedLoading={completedLoading}
        />
      </>
    );

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Snack
        open={!hasExtension}
        onClose={() => setHasExtension(true)}
        message={
          <>
            To be able to use Allting during your meetings you need to get our
            chrome extension. You can get it here:{" "}
            <a
              style={{ color: colors.secondary.light }}
              href="https://chrome.google.com/webstore/detail/allting/eoijnapbifidmjechncaakpgkbpdihea"
            >
              Allting extension
            </a>
          </>
        }
      />
      <>
        <MeetingsIntro loadingDone={firstLoadDone && !createOrg} />
        {user?.Requests?.map((req) => (
          <UserRequest
            Id={req.Id}
            Label={req.Label}
            Type={req.Type}
            refetch={allRefetch}
          />
        ))}
        <InviteUsers
          onClose={() => props.history.push("/meetings")}
          open={inviteToOrgMatch != null}
          onConfirm={inviteUsersToOrg}
        />
        <ConfirmationDialog
          open={currentMeeting != null}
          onClose={() => setCurrentMeeting(null)}
          title="Ongoing Meeting"
          description={`${currentMeeting?.Name} is currently ongoing. Do you want to join this meeting?`}
          onConfirm={() => props.history.push(`/live/${currentMeeting?.Id}`)}
          confirmButtonText="Join Meeting"
        />
        <CreateOrgDialog open={createOrg} onClose={closeCreate} />
      </>
      <AdaptiveWrapper>
        {currentContext === "teams" && user?.Org?.Id != null && (
          <Button
            style={{
              marginLeft: 15,
              marginBottom: 20,
              alignSelf: "flex-start",
            }}
            color="primary"
            variant="contained"
            onClick={() => history.push("/meetings/invite-to-org")}
          >
            Add Colleagues
          </Button>
        )}
        {notCompletedMeetings.length > 0 && currentContext !== "teams" && (
          <div style={{ margin: "20px 0", marginBottom: 60, width: "100%" }}>
            <TableTitle variant="h6">Upcoming Meetings</TableTitle>
            <Table
              columns={completedColumns}
              data={notCompletedMeetings}
              defaultSorted={[
                {
                  id: "StartTime",
                  desc: true,
                },
              ]}
              onRowClick={(row) =>
                props.history.push(`/scheduled-meeting/${row.Id}`)
              }
            />
          </div>
        )}
        {data.length > 0 && (
          <div style={{ width: "100%", marginTop: 20 }}>
            <TableTitle id="step1" variant="h6">
              Previous Meetings
            </TableTitle>
            <Table
              columns={columns}
              data={data}
              defaultSorted={[
                {
                  id: "StartTime",
                  desc: true,
                },
              ]}
              onRowClick={(row) => props.history.push(`/meeting/${row.Id}`)}
            />
          </div>
        )}

        {loading && data.length === 0 && !firstLoadDone && (
          <div style={{ marginTop: 20 }}>
            <LoadingTable width={1000} />
          </div>
        )}
        {completedLoading &&
          notCompletedMeetings.length === 0 &&
          !firstLoadDone && (
            <div style={{ marginTop: 20 }}>
              <LoadingTable width={1000} />
            </div>
          )}
      </AdaptiveWrapper>
    </div>
  );
}

function MobileView(props: {
  history: any;
  data: MeetingsQuery_meeting[];
  loading: boolean;
  completedData: MeetingsQuery_meeting[];
  completedLoading: boolean;
}) {
  const { data, loading, completedData, completedLoading } = props;

  // Using memo beacuse of react tables
  const cols = useMemo(
    () => columns.filter((header: any) => Boolean(header.mobile)),
    []
  );
  const completedCols = useMemo(
    () => completedColumns.filter((header: any) => Boolean(header.mobile)),
    []
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 100,
      }}
    >
      {completedData.length > 0 && (
        <div style={{ margin: "40px 0", width: "100%" }}>
          <TableTitle variant="h6">Upcoming Meetings</TableTitle>
          <Table
            columns={completedCols}
            data={completedData}
            defaultSorted={[
              {
                id: "StartTime",
                desc: true,
              },
            ]}
            onRowClick={(row) =>
              props.history.push(`/scheduled-meeting/${row.Id}`)
            }
          />
        </div>
      )}
      {data.length > 0 && (
        <div style={{ width: "100%" }}>
          <TableTitle variant="h6">Previous Meetings</TableTitle>
          <Table
            columns={cols}
            defaultSorted={[
              {
                id: "StartTime",
                desc: true,
              },
            ]}
            data={data}
            onRowClick={(row) => props.history.push(`/meeting/${row.Id}`)}
          />
        </div>
      )}
      {loading && data.length === 0 && completedData.length === 0 && (
        <div style={{ marginTop: 20 }}>
          <LoadingTable width={400} />
        </div>
      )}
    </div>
  );
}

function NoData({
  hasOrg,
  setShowOrgDialog,
}: {
  hasOrg: boolean;
  setShowOrgDialog: (val: boolean) => void;
}) {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <NotFound
        style={{ width: 200, height: 200, marginTop: 40, marginBottom: 10 }}
      />
      <Typography gutterBottom variant="h5">
        Welcome!
      </Typography>
      <Typography style={{ maxWidth: 400 }} gutterBottom>
        It seems like you don't have any meetings. Start sharpening your meeting
        skills by creating one now! You will need to join or create an
        organisation to do so.
      </Typography>
      {currentContext !== "teams" && (
        <Button
          style={{ marginTop: 20 }}
          color="primary"
          variant="contained"
          onClick={() =>
            hasOrg ? history.push("/create-meeting") : setShowOrgDialog(true)
          }
        >
          {hasOrg ? "Create Meeting" : "Create Organisation"}
        </Button>
      )}
      {currentContext === "teams" && hasOrg && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push("/meetings/invite-to-org")}
        >
          Add Colleagues
        </Button>
      )}
    </div>
  );
}

const columns = [
  {
    Header: "Meeting Name",
    accessor: "Name",
    mobile: true,
    Cell: ({ cell: { value } }: any) => (
      <Typography style={{ fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    Header: "Length",
    accessor: "Length",
    Cell: ({ cell: { value } }: any) =>
      value ? formatMinutes(value / 60) : null,
    right: true,
    cellRight: true,
  },
  {
    Header: "Participants",
    accessor: "Participants.length",
    right: true,
    cellRight: true,
    Cell: ({
      cell: {
        value,
        row: { original },
      },
    }: any) => (
      <div>{(value ?? 0) + (original.ExternalParticipants?.length ?? 0)}</div>
    ),
  },
  {
    Header: "Tags",
    accessor: "Tags",
    Cell: ({ cell: { value } }: any) =>
      (value ?? []).map((val: any) => tags[val]).join(", "),
  },

  {
    Header: "Meeting Score",
    accessor: "AvgOverallScore",
    right: true,
    cellRight: true,
    Cell: ({
      cell: {
        row: { original },
        value,
      },
    }: any) =>
      original.Status === "processing" && original.FileUploaded === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <ProgressBar variant="small" progress={original.ProcessingProgress} />
        </div>
      ) : value ? (
        <CircularGraphWithLabel
          progress={value * 20}
          small
          max={5}
          colorMode="main"
        />
      ) : (
        "Awaiting Feedback"
      ),
  },
  {
    Header: "Date",
    accessor: "StartTime",
    cellRight: true,
    mobile: true,
    right: true,
    Cell: ({ cell: { value, row } }: any) => (
      <Typography>
        {moment(value ?? row.original.Date).format("L, HH:mm")}
      </Typography>
    ),
  },
];

const completedColumns = [
  {
    Header: "Meeting Name",
    accessor: "Name",
    mobile: true,
    Cell: ({ cell: { value } }: any) => (
      <Typography style={{ fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    Header: "Tags",
    accessor: "Tags",
    Cell: ({ cell: { value } }: any) =>
      (value ?? []).map((val: string) => tags[val]).join(", "),
  },
  {
    Header: "Date",
    accessor: "StartTime",
    cellRight: true,
    right: true,
    mobile: true,
    Cell: ({ cell: { value } }: any) => (
      <Typography>{moment(value).format("L, HH:mm")}</Typography>
    ),
  },
];

function InviteUsers({
  onClose,
  open,
  onConfirm,
}: {
  onClose: () => void;
  open: boolean;
  onConfirm: (members: string[]) => void;
}) {
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
  const smallScreen = useMediaQuery(phoneWidth);
  const snack = useSnack();

  return (
    <ConfirmationDialog
      confirmButtonText="Add"
      description="Add colleagues to your Allting organization. Enter email followed by space or enter key"
      onClose={() => {
        onClose();
        setMembersToAdd([]);
      }}
      onConfirm={() => {
        snack({
          message: `Invite sent to ${
            membersToAdd.length > 1 ? membersToAdd.join("\n") : membersToAdd[0]
          }`,
        });
        onConfirm(membersToAdd);
        setMembersToAdd([]);
      }}
      open={open}
      title="Add Colleagues"
      disabled={
        membersToAdd.length === 0 ||
        membersToAdd.filter(validateEmail).length < membersToAdd.length
      }
    >
      <div style={{ width: smallScreen ? 300 : 500 }}>
        {membersToAdd.filter(validateEmail).length < membersToAdd.length && (
          <Typography gutterBottom style={{ color: colors.error.main }}>
            One of the emails are invalid!
          </Typography>
        )}
        <UsersInvite
          placeholder="Enter email followed by space or enter key"
          chosenUserEmails={[]}
          hideSuggestions
          onChange={setMembersToAdd}
          selectedEmails={membersToAdd}
        />
      </div>
    </ConfirmationDialog>
  );
}

const tags: Record<string, string> = {
  ["workshop"]: "Workshop",
  ["planning"]: "Planning",
  ["progress-check"]: "Progress check",
  ["idea-generation"]: "Idea generation",
  ["problem-solving"]: "Problem Solving",
  ["decision-making"]: "Decision making",
  ["team-alignment"]: "Team alignment",
  ["one-on-one"]: "One on One",
};
