import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import firebaseui, { auth } from "firebaseui";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";

import { ReactComponent as AlltingLog } from "../images/raccons/happy.svg";
import styled from "styled-components";
import { currentContext } from "../App";
import * as teams from "@microsoft/teams-js";
import { useLocation } from "react-router-dom";

const fireLogger = firebase.analytics();
const ui = new auth.AuthUI(firebase.auth());

const callbacks: any = {
  signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
    // User successfully signed in.
    // Return type determines whether we continue the redirect automatically
    // or whether we leave that to developer to handle.
    if (currentContext === "teams") {
      teams.authentication.notifySuccess(
        authResult.additionalUserInfo.isNewUser ? "new-user" : "success"
      );
    }
    if (authResult.additionalUserInfo.isNewUser) {
      console.log(uiConfig, redirectUrl);
      // @ts-ignore
      fireLogger.logEvent("new_user");
      window.history.pushState(null, "New User", `/new-user`);
      window.location.reload();
      return false;
    }
    // @ts-ignore
    fireLogger.logEvent("login");
    // we might want to do something here like check if a user was created from what screen it was created if it was a invited user etc
    return true;
  },
  signInFailure(error: firebaseui.auth.AuthUIError): Promise<void> {
    console.log("there is a error here: ", error);
    if (error.code === "auth/user-disabled") {
      console.log("Ah this user is disabled!!!");
    }
    return new Promise(async () => undefined);
  },

  uiShown: function () {
    // The widget is rendered.
    // Hide the loader.
    const doc = document.getElementById("loader");
    if (doc != null) doc.style.display = "none";
  },
};

const uiConfig: firebaseui.auth.Config = {
  callbacks: callbacks,
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: "popup",
  signInSuccessUrl: "meetings",
  siteName: "Allting",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
    },
    {
      provider: new firebase.auth.OAuthProvider("microsoft.com").providerId,
      requireDisplayName: true,
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod:
        firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      requireDisplayName: false,
    },
  ],
  // Terms of service url.
  tosUrl: "https://www.allting.co/terms-of-service",
  // Privacy policy url.
  privacyPolicyUrl: "https://www.allting.co/privacy-policy",
};

export default function LogIn() {
  const { search } = useLocation();
  useEffect(
    () => fireLogger.logEvent("page_view", { page_title: "Log in" }),
    []
  );
  useEffect(
    () =>
      ui.start("#firebaseui-auth-container", {
        ...uiConfig,
          signInOptions: currentContext === "teams" || search.includes("context=teams")
              ? uiConfig.signInOptions?.slice(1): uiConfig.signInOptions,
        signInFlow:
          currentContext === "teams" || search.includes("context=teams")
            ? "redirect"
            : "popup",
      }),
    []
  );

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 350, marginTop: 100 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            marginBottom: 60,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LogoFont>Allting</LogoFont>
          <AlltingLog style={{ marginTop: 10, height: 120, marginRight: 15 }} />
          <Typography
            style={{
              font: "NexaLightText",
              marginTop: 15,
              textAlign: "center",
            }}
          >
            Love your meetings
          </Typography>
        </div>
        <Typography style={{ textAlign: "center" }}>
          Sign in or create an account to get access to Allting!
          {currentContext === "teams"
            ? ""
            : " It is free and no credit card is needed."}
        </Typography>
        <div id="firebaseui-auth-container" />
      </div>
    </div>
  );
}

function LogoFont({ children }: { children: any }) {
  return (
    <span
      style={{
        fontFamily: "NexaBold",
        fontSize: 40,
        textAlign: "center",
      }}
    >
      {children}
    </span>
  );
}

const StyledControl = styled(FormControl)`
  width: 220px;
`;

export function PopUpLogIn({
  allowAnonymous,
  successUrl,
}: {
  allowAnonymous?: boolean;
  successUrl: string;
}) {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  useEffect(
    () =>
      ui.start("#firebaseui-auth-popup", {
        ...uiConfig,
        signInFlow:
          currentContext === "teams" || search.includes("context=teams")
            ? "redirect"
            : "popup",
        callbacks: {
          ...callbacks,
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            if (authResult.additionalUserInfo.isNewUser) {
              console.log(uiConfig, redirectUrl);

              // @ts-ignore
              fireLogger.logEvent("sign_up");
              window.history.pushState(
                null,
                "New User",
                `/new-user?redirect=${successUrl}`
              );
              window.location.reload();
              return false;
            }
            // @ts-ignore
            fireLogger.logEvent("login");
            // we might want to do something here like check if a user was created from what screen it was created if it was a invited user etc
            return true;
          },
        },
        signInSuccessUrl: successUrl,
      }),
    []
  );
  return (
    <div style={{ width: 355, padding: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AlltingLog style={{ width: 60, height: 60 }} />
        <LogoFont>Allting</LogoFont>
      </div>
      {!loading ? (
        <>
          {allowAnonymous && (
            <form
              onSubmit={async (val) => {
                setLoading(true);
                firebase
                  .auth()
                  .signInAnonymously()
                  .then(() => {
                    console.log("success! lets see what happened");
                  })
                  .catch((error) => {
                    console.error("failed login: ", error);
                  });
              }}
              style={{
                display: "flex",
                marginTop: 40,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ textAlign: "center", marginBottom: 15 }}
              >
                Try without creating account
              </Typography>
              <StyledControl>
                {/*<FormLabel required>Username</FormLabel>*/}
                <TextField
                  required
                  placeholder="Username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </StyledControl>
              <Button
                style={{ marginTop: 10, marginBottom: 10 }}
                variant="contained"
                color="primary"
                disabled={username === ""}
                type="submit"
              >
                Continue
              </Button>
            </form>
          )}
          <Typography
            variant="subtitle2"
            style={{ textAlign: "center", margin: 25, marginTop: 30 }}
          >
            {allowAnonymous && "Or "}Sign-in/Create account
          </Typography>
          <div id="firebaseui-auth-popup" />{" "}
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
