import React, { useState } from "react";
import { Button, Snackbar } from "@material-ui/core";
import { colors } from "../styles";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import {
  RespondToRequest,
  RespondToRequestVariables,
} from "./__generated__/RespondToRequest";
import { UserRequestType } from "../__generated__/globalTypes";
import ConfirmationDialog from "./ConfirmationDialog";

const requestResponseMutation = gql`
  mutation RespondToRequest($accept: Boolean!, $id: ID!) {
    respondToRequest(id: $id, accept: $accept) {
      Id
      Requests {
        Id
        Label
        Type
      }
    }
  }
`;

export default function UserRequest(req: {
  Label: string;
  Id: string;
  Type: UserRequestType;
  refetch: () => void;
}) {
  const [show, setShow] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [respondToRequest] = useMutation<
    RespondToRequest,
    RespondToRequestVariables
  >(requestResponseMutation);

  async function respond(accept: boolean) {
    setShow(false);
    await respondToRequest({ variables: { id: req.Id, accept } });
    req.refetch();
  }

  return (
    <>
      <Snackbar
        style={{ maxWidth: 330 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={show}
        message={req.Label}
        action={
          <>
            <Button
              onClick={() => respond(false)}
              style={{ color: colors.error.light }}
              color="inherit"
            >
              Reject
            </Button>
            <Button
              onClick={() => {
                if (req.Type === UserRequestType.OrgInvite) {
                  setShowDialog(true);
                  setShow(false);
                } else {
                  respond(true);
                }
              }}
              style={{ color: colors.success.light }}
              color="inherit"
            >
              Accept
            </Button>
          </>
        }
      />
      <ConfirmationDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
          setShow(true);
        }}
        title="Are you sure"
        description="This will cause you to leave your current organization are you sure you want to do this?"
        onConfirm={() => respond(true)}
        confirmButtonText="Join"
      />
    </>
  );
}
