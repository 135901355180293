import React from "react";
import { LiveItem } from "./LiveItem";
import Feedback, { LiveFeedback } from "../../components/Feedback";
import { useMutation, useQuery } from "react-apollo";
import { FeedbackQuestionsQuery } from "../Meeting/__generated__/feedbackQuestionsQuery";
import {
  feedbackQuestionsQuery,
  giveFeedbackMutation,
} from "../Meeting/queries";
import {
  giveFeedbackMutation as GiveFeedbackMutationType,
  giveFeedbackMutationVariables,
} from "../Meeting/__generated__/giveFeedbackMutation";

export default function ({
  meetingId,
  onComplete,
}: {
  meetingId: string;
  onComplete: () => void;
}) {
  const { data, loading, error } = useQuery<FeedbackQuestionsQuery>(
    feedbackQuestionsQuery
  );

  const [
    giveFeedback,
    { data: mutationData, loading: mutationLoading },
  ] = useMutation<GiveFeedbackMutationType, giveFeedbackMutationVariables>(
    giveFeedbackMutation
  );
  if (mutationLoading) return null;
  return (
    <LiveItem title="Feedback will be anonymous" time={new Date().getTime()}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 400,
          }}
        >
          <LiveFeedback
            questions={data?.getFeedbackQuestions.Questions ?? []}
            onDone={async (inp) => {
              await giveFeedback({
                variables: {
                  meetingId,
                  input: {
                    ...inp,
                    QuestionVersion: "v2",
                    Timestamp: new Date().valueOf(),
                  },
                },
              });
              onComplete();
            }}
          />
        </div>
      </div>
    </LiveItem>
  );
}
