import { FeedbackQuestionsQuery } from "./Meeting/__generated__/feedbackQuestionsQuery";
import { useHistory, useParams } from "react-router-dom";

import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import {
  feedbackQuestionsQuery,
  giveExternalFeedbackMutation,
  giveFeedbackMutation,
} from "./Meeting/queries";
import { CentralWrapper } from "components/Wrappers";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import {
  giveFeedbackMutation as GiveFeedbackMutationType,
  giveFeedbackMutationVariables,
} from "./Meeting/__generated__/giveFeedbackMutation";
import {
  useHasExternalGivenFeedback,
  useHasGivenFeedback,
} from "./Meeting/hooks";
import {
  GiveExternalFeedbackMutation,
  GiveExternalFeedbackMutationVariables,
} from "./Meeting/__generated__/giveExternalFeedbackMutation";
import { colors, phoneWidth } from "../styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Feedback from "../components/Feedback";
import { FeedbackInput } from "../../__generated__/globalTypes";
import { currentContext, currentUser } from "../App";
import Space from "../components/Space";
import firebase from "../firebase";

export default function GiveFeedback(props: { match: any; history: any }) {
  useEffect(
    () =>
      firebase.analytics().logEvent("page_view", { page_title: "Feedback" }),
    []
  );
  const id = props.match.params.id;

  const [
    giveFeedback,
    { data: mutationData, loading: mutationLoading },
  ] = useMutation<GiveFeedbackMutationType, giveFeedbackMutationVariables>(
    giveFeedbackMutation
  );

  function feedbackMutation(input: FeedbackInput) {
    giveFeedback({
      variables: {
        meetingId: id,
        input,
      },
    });
  }

  const { data: givenFeedback } = useHasGivenFeedback(id);
  if ((mutationData != null && mutationData) || givenFeedback)
    props.history.push(`/meeting/${id}`);
  return (
    <GiveFeedbackComponent
      mutationLoading={mutationLoading}
      giveFeedback={feedbackMutation}
    />
  );
}

export function ExternalGiveFeedback(props: any) {
  useEffect(
    () =>
      firebase
        .analytics()
        .logEvent("page_view", { page_title: "External Feedback" }),
    []
  );
  const { encodedReq } = useParams() as any;
  const { id, email } = JSON.parse(atob(encodedReq));
  const [feedbackGiven, setFeedbackGiven] = useState(false);

  const smallScreen = useMediaQuery(phoneWidth);
  const { data: givenFeedback } = useHasExternalGivenFeedback(id, email);

  const [giveFeedback, { data, loading }] = useMutation<
    GiveExternalFeedbackMutation,
    GiveExternalFeedbackMutationVariables
  >(giveExternalFeedbackMutation);

  useEffect(() => setFeedbackGiven(data != null), [data]);

  function feedbackMutation(input: FeedbackInput) {
    giveFeedback({
      variables: {
        meetingId: id,
        email,
        input,
      },
    });
    firebase.analytics().logEvent("gave_feedback");
  }

  if (givenFeedback) {
    return (
      <div style={smallScreen ? {} : { marginLeft: -200 }}>
        <ThankYou title="You have already given feedback for this meeting thank you!" />
      </div>
    );
  }

  return (
    <div style={smallScreen ? {} : { marginLeft: -200 }}>
      {feedbackGiven ? (
        <ThankYou title="Thank you for your feedback!" />
      ) : (
        <GiveFeedbackComponent
          mutationLoading={loading}
          giveFeedback={feedbackMutation}
        />
      )}
    </div>
  );
}

function GiveFeedbackComponent({
  giveFeedback,
  mutationLoading,
}: {
  mutationLoading: boolean;
  giveFeedback: (input: FeedbackInput) => void;
}) {
  const { data, loading, error } = useQuery<FeedbackQuestionsQuery>(
    feedbackQuestionsQuery
  );

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: 40,
          color: colors.grey.main,
        }}
      >
        <Typography variant="h3">Loading...</Typography>
      </div>
    );
  }
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {mutationLoading && <LinearProgress />}
      <Space height={20} />
      <Feedback
        questions={data?.getFeedbackQuestions.Questions ?? []}
        onDone={(inp) =>
          giveFeedback({
            ...inp,
            QuestionVersion: "v2",
            Timestamp: new Date().valueOf(),
          })
        }
      />
    </div>
  );
}

function ThankYou({ title }: { title: string }) {
  const history = useHistory();

  return (
    <div
      style={{
        marginTop: 250,
        paddingLeft: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">{title}</Typography>

      <Typography style={{ marginTop: 40 }} variant="body1">
        To see results and improve your meetings join Allting!
      </Typography>
      <Button
        style={{ width: 170, marginTop: 40 }}
        variant="contained"
        color="primary"
        onClick={() => history.push("/meetings")}
      >
        Join Allting
      </Button>
    </div>
  );
}
