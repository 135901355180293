import { uploadAudio } from "./apiUpload";
const recorder = require("audio-recorder-polyfill");
window.MediaRecorder = recorder;

let shouldStop = false;
let stopped = false;
let shutdown = false;
let meetingId: string,
  count: number,
  userId: string,
  startTime: string,
  participants: number;
let done = false;

let mediaRecorder: MediaRecorder | null = null;
const handleSuccess = function(stream: MediaStream) {
  const options = { mimeType: "audio/wav" };
  console.log("started");

  mediaRecorder = new MediaRecorder(stream, options);

  if (mediaRecorder == null) {
    throw Error("Error Media recorder not working! Try diffrent browser");
  }

  mediaRecorder.addEventListener("dataavailable", function(e: any) {
    if (mediaRecorder != null) {
      if (e.data.size > 0 && !done) {
        if (shouldStop) done = true;
        e.data.arrayBuffer().then((val: any) => console.log(val));
        uploadAudio(e.data, {
          meetingId,
          count,
          userId,
          startTime,
          participants,
          done
        });
        count += 1;
      }
      if (shouldStop && !stopped) {
        mediaRecorder.stop();
        stopped = true;
      }
    }
  });

  mediaRecorder.addEventListener("stop", function() {
    stream.getTracks().forEach(track => track.stop());
  });

  mediaRecorder.start(5000);
};

export function startRecording(
  name: string,
  userIdVar: string,
  numParticipants: number
) {
  // @ts-ignore
  if (MediaRecorder.notSupported) {
    // @ts-ignore
    // noSupport.style.display = "block";
    // // @ts-ignore
    // dictaphone.style.display = "none";
    alert("recording is not supported on your device");
  }
  console.log("starting to record with, ", name, userIdVar, numParticipants);
  startTime = new Date().toISOString();
  meetingId = name;
  shutdown = false;
  done = false;
  count = 0;
  userId = userIdVar;
  participants = numParticipants;
  navigator.mediaDevices
    .getUserMedia({ audio: true, video: false })
    .then(handleSuccess);
}

export function stopRecording() {
  shouldStop = true;
}

export function pauseRecording() {
  if (mediaRecorder != null) mediaRecorder.pause();
}

export function resumeRecording() {
  if (mediaRecorder != null) mediaRecorder.resume();
}
