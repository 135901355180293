import React from "react";
import { useTransition, animated } from "react-spring";

const AnimationContext = React.createContext<any>(null);

export function CascadingList({
  children,
  list
}: {
  children: JSX.Element;
  list: any[];
}) {
  const transitions = useTransition(list, item => item.Id, {
    from: { opacity: 0, width: "100%" },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 150
  });
  return (
    <div>
      {transitions.map(({ item, props, key }, index) => (
        <animated.div key={key} style={props}>
          <AnimationContext.Provider value={{ item, index }}>
            {children}
          </AnimationContext.Provider>
        </animated.div>
      ))}
    </div>
  );
}

export const CascadingConsumer = AnimationContext.Consumer;
