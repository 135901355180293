import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useMutation } from "react-apollo";
import { UsersInvite } from "components/UserSearch";
import ShareIcon from "@material-ui/icons/Link";
import { colors, getDarkMode, phoneWidth } from "../styles";
import { MeetingAccessEnum } from "../__generated__/globalTypes";
import gql from "graphql-tag";
import PublicIcon from "@material-ui/icons/Public";
import PrivateIcon from "@material-ui/icons/Lock";
import OrgIcon from "@material-ui/icons/People";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Space from "components/Space";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { updateMeeting } from "../views/Meeting/queries";
import {
  UpdateMeeting,
  UpdateMeetingVariables,
} from "../views/Meeting/__generated__/UpdateMeeting";
import { meetingAttributesEnum } from "../__generated__/globalTypes";
import {
  UpdateMeetingAccess,
  UpdateMeetingAccessVariables,
} from "./__generated__/UpdateMeetingAccess";
import {
  InviteExternalParticipants,
  InviteExternalParticipantsVariables,
} from "../views/__generated__/InviteExternalParticipants";
import { inviteExternalParticipants } from "../views/InviteExternalParticipantsDialog";
import {
  InviteExternalParticipantsToOrg,
  InviteExternalParticipantsToOrgVariables,
} from "./__generated__/InviteExternalParticipantsToOrg";
import firebase from "firebase/app";
import { currentContext } from "../App";

const updateMeetingMutation = gql`
  mutation UpdateMeetingAccess($meetingId: ID!, $access: MeetingAccessEnum!) {
    updateMeetingAccess(meetingId: $meetingId, access: $access) {
      Id
      Access
    }
  }
`;

export const inviteExternalToOrgMutation = gql`
  mutation InviteExternalParticipantsToOrg($emails: [String!]!) {
    inviteExternalToOrg(emails: $emails)
  }
`;

export default function ShareDialog({
  open,
  onClose,
  preSelectedEmails,
  url,
  access,
  meetingId,
  host,
  darkMode,
}: {
  open: boolean;
  meetingId: string;
  onClose: () => void;
  preSelectedEmails: string[];
  url: string;
  access: MeetingAccessEnum;
  host: boolean;
  darkMode?: boolean;
}) {
  const [emails, setEmails] = useState<string[]>([]);
  const smallScreen = useMediaQuery(phoneWidth);
  const [inviteExternalToMeeting] = useMutation<
    InviteExternalParticipants,
    InviteExternalParticipantsVariables
  >(inviteExternalParticipants);
  const [inviteExternalToOrg] = useMutation<
    InviteExternalParticipantsToOrg,
    InviteExternalParticipantsToOrgVariables
  >(inviteExternalToOrgMutation);
  const [loadingState, setLoadingState] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);

  return (
    <Dialog
      open={open}
      PaperProps={
        darkMode
          ? {
              style: {
                backgroundColor: getDarkMode(currentContext).light,
              },
            }
          : {}
      }
      onClose={() => {
        setLoadingState(false);
        setEmails([]);
        setInviteSuccess(false);
        onClose();
      }}
    >
      <div style={{ width: smallScreen ? "100%" : 530 }}>
        {host && (
          <UsersInvite
            placeholder="Add people using their email"
            chosenUserEmails={preSelectedEmails}
            onChange={(email) => setEmails(email)}
            selectedEmails={emails}
          />
        )}
        {loadingState ? (
          <LoadingState />
        ) : inviteSuccess ? (
          <AfterInviteState url={url} darkMode={darkMode} />
        ) : emails.length > 0 ? (
          <EmailInputedState
            inviteClicked={async (inviteToOrg) => {
              setLoadingState(true);
              await inviteExternalToMeeting({
                variables: { meetingId, emails },
              });
              if (inviteToOrg) {
                await inviteExternalToOrg({ variables: { emails } });
              }
              setLoadingState(false);
              setInviteSuccess(true);
              firebase.analytics().logEvent("invited_users", {
                count: emails.length,
                page_title: "Share Dialog",
              });
            }}
          />
        ) : (
          <InitialState
            host={host}
            darkMode={darkMode}
            meetingId={meetingId}
            url={url}
            access={access}
          />
        )}
      </div>
    </Dialog>
  );
}

function InitialState({
  url,
  access: initialAccess,
  meetingId,
  host,
  darkMode,
}: {
  url: string;
  access: MeetingAccessEnum;
  meetingId: string;
  host: boolean;
  darkMode?: boolean;
}) {
  const [update] = useMutation<
    UpdateMeetingAccess,
    UpdateMeetingAccessVariables
  >(updateMeetingMutation);
  const [access, setAccess] = useState(initialAccess);
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ padding: 15 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 20,
          marginTop: 10,
          marginBottom: 5,
        }}
      >
        <ShareIcon style={{ marginRight: 10 }} />
        <Typography variant="subtitle2" style={{ fontSize: 16 }}>
          Sharable Link
        </Typography>
      </div>
      <SharableLink url={url} darkMode={darkMode} />
      {host && (
        <>
          <Space height={30} />
          <AccessSelector
            disabled={loading}
            updateAccess={async (state) => {
              setAccess(state);
              setLoading(true);
              await update({
                variables: {
                  meetingId,
                  access: state,
                },
              });
              setLoading(false);
            }}
            access={access}
          />
        </>
      )}
    </div>
  );
}

function EmailInputedState({
  inviteClicked,
  allUsersPartOfOrg,
}: {
  inviteClicked: (inviteToOrg: boolean) => void;
  allUsersPartOfOrg?: boolean;
}) {
  const [inviteToOrg, setInviteToOrg] = useState(false);
  return (
    <div style={{ height: 195 }}>
      <Space height={10} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          disabled={allUsersPartOfOrg}
          checked={inviteToOrg}
          onChange={() => setInviteToOrg(!inviteToOrg)}
          color="primary"
        />
        <div style={{ marginTop: 20 }}>
          <Typography variant="subtitle1">
            Add members to organization
          </Typography>
          <Typography variant="caption">
            Will automatically add invited members to you organization.
          </Typography>
        </div>
      </div>
      <Button
        style={{
          marginRight: 20,
          float: "right",
          marginTop: 60,
        }}
        color="primary"
        variant="contained"
        onClick={() => inviteClicked(inviteToOrg)}
      >
        Invite
      </Button>
    </div>
  );
}

function AfterInviteState({
  url,
  darkMode,
}: {
  url: string;
  darkMode?: boolean;
}) {
  return (
    <div
      style={{
        height: 195,
        padding: 10,
      }}
    >
      <Space height={10} />
      <Typography>
        Users have been invited via email. You can also share the link below
        with them.
      </Typography>
      <Space height={10} />
      <SharableLink url={url} darkMode={darkMode} />
    </div>
  );
}

function LoadingState() {
  return (
    <div
      style={{
        height: 195,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress
        style={{ marginTop: 50, width: 50, height: 50 }}
        color="primary"
      />
      <Typography>Inviting people to Allting</Typography>
    </div>
  );
}

const getHelperText = (state: MeetingAccessEnum) => {
  switch (state) {
    case MeetingAccessEnum.public:
      return "Everyone on the internet with this link can join this meeting";
    case MeetingAccessEnum.private:
      return "Only people invited to this meeting can join";
    case MeetingAccessEnum.organization:
    default:
      return "Only members in your organization who has access to the link can join";
  }
};

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function AccessSelector({
  access,
  updateAccess,
  disabled,
  darkMode,
}: {
  access: MeetingAccessEnum;
  disabled?: boolean;
  updateAccess: (state: MeetingAccessEnum) => void;
  darkMode?: boolean;
}) {
  return (
    <FormControl>
      <Select
        disabled={disabled}
        value={access}
        disableUnderline
        onChange={(event: any) => updateAccess(event.target.value)}
        IconComponent={ArrowDown}
      >
        <MenuItem value={MeetingAccessEnum.public}>
          <ItemWrapper>
            <PublicIcon style={{ marginRight: 10 }} /> Public Link
          </ItemWrapper>
        </MenuItem>
        <MenuItem value={MeetingAccessEnum.organization}>
          <ItemWrapper>
            <OrgIcon style={{ marginRight: 10 }} /> Organization Link
          </ItemWrapper>
        </MenuItem>
        <MenuItem value={MeetingAccessEnum.private}>
          <ItemWrapper>
            <PrivateIcon style={{ marginRight: 10 }} /> Private Link
          </ItemWrapper>
        </MenuItem>
      </Select>
      <FormHelperText>{getHelperText(access)}</FormHelperText>
    </FormControl>
  );
}

function SharableLink({ url, darkMode }: { url: string; darkMode?: boolean }) {
  const [copyTip, setCopyTip] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Typography
        style={{
          backgroundColor: darkMode
            ? getDarkMode(currentContext).primary
            : colors.greyHighlight,
          borderRadius: 4,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxHeight: "1.7rem",
          maxWidth: 370,
          padding: 5,
          marginRight: 10,
        }}
        onClick={(event: any) => {
          let range = document.createRange();
          range.selectNodeContents(event.target);
          let sel = window.getSelection()!;
          sel.removeAllRanges();
          sel.addRange(range);
        }}
      >
        {url}
      </Typography>
      <Tooltip
        open={copyTip}
        onClose={() => setCopyTip(false)}
        disableFocusListener
        disableTouchListener
        title="Link copied to clipboard"
      >
        <Button
          onClick={async () => {
            copyTextToClipboard(url);
            setCopyTip(true);
          }}
          variant="contained"
          color="primary"
        >
          Copy Link
        </Button>
      </Tooltip>
    </div>
  );
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
