import { Avatar, Chip } from "@material-ui/core";
import React from "react";
import FaceIcon from "@material-ui/icons/Face";

export default function UserChip({
  alt,
  src,
  label,
  onDelete,
  color,
}: {
  alt?: string;
  color?: string;
  src?: string;
  label: string;
  onDelete?: () => void;
}) {
  return (
    <Chip
      variant="outlined"
      // color={color ? undefined : "primary"}
      style={color ? { color: color, borderColor: color } : undefined}
      avatar={
        <Avatar
          alt={alt}
          style={{ marginLeft: 0, width: 30, height: 30 }}
          src={src}
        >
          {alt == null && <FaceIcon />}
        </Avatar>
      }
      label={label}
      onDelete={onDelete}
    />
  );
}
