import React, { useState } from "react";
import { Step } from "react-joyride";
import useGetUser from "../useGetUser";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { phoneWidth } from "../../styles";
import Base, { tutorialCompleteMutation } from "./Base";
import { useMutation } from "react-apollo";

export default function ({ loadingDone }: { loadingDone: boolean }) {
  const { user, loading } = useGetUser();
  const smallScreen = useMediaQuery(phoneWidth);
  const [updateUser] = useMutation(tutorialCompleteMutation);

  const [steps] = useState<Step[]>([
    {
      target: "#share-button",
      content:
        "Need more people in the meeting? 👋 \n Not to worry, you can easily add them or get a sharable link from here.",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      // hideFooter: true,
      placement: "bottom",
      // spotlightClicks: true,
    },
    // { target: "#live-step2", content: "change to public/non public" },
    {
      target: "#energy-bar",
      content: "This bar will let you gauge the average energy level 🔋 in the room.",
      disableOverlayClose: true,
    },
    {
      target: "#length-button",
      content:
        "By setting an end time Allting will keep track of time for you. ⏰ \n Need more time? Simply click and change the end time here",
    },
    {
      target: "#flag-button",
      content:
        "Flags let you anonymously bring everyone’s attention to things you feel are important to reach a good meeting outcome. See them as your toolbox ⚒️ to help “nudge” the meeting to get better results. 📈",
    },
    {
      target: "#end-meeting-button",
      content:
        "You can end the Allting meeting session here. If you are the host this will end the Allting meeting session for all participants and allow for everyone to give feedback on the meeting 🌟️",
    },
  ]);

  if (user == null || user?.LiveTutorialCompleted || !loadingDone) {
    return null;
  }

  return (
    <Base
      steps={steps}
      onDone={() =>
        updateUser({
          variables: { attribute: "LiveTutorialCompleted", value: "true" },
        })
      }
    />
  );
}
