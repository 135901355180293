import React, { ReactNode, useContext, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return (
      // @ts-ignore
      <Slide direction="down" ref={ref} {...props} />
    );
  }
);

export const alertContext = React.createContext({
  setAlert: (options: AlertProps) => {},
});

export function useAlert(): (options: AlertProps) => void {
  const context = useContext(alertContext);
  return (options: AlertProps) => context.setAlert(options);
}

type AlertProps = {
  title: string;
  message: string;
};

export function AlertProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  return (
    <alertContext.Provider
      value={{
        setAlert: (options) => {
          setTitle(options.title);
          setMessage(options.message);
          setOpen(true);
        },
      }}
    >
      <AlertComp
        title={title}
        message={message}
        open={open}
        onClose={() => setOpen(false)}
      />
      {children}
    </alertContext.Provider>
  );
}

function AlertComp({
  message,
  title,
  open,
  onClose,
}: {
  title: string;
  message: string;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
