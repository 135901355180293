import React, { useEffect, useState } from "react";
import Joyride, { ACTIONS, EVENTS, Step } from "react-joyride";
import useGetUser from "../useGetUser";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { colors, phoneWidth } from "../../styles";
import { gql } from "apollo-boost";

export const tutorialCompleteMutation = gql`
  mutation TutorialComplete($attribute: userAttributesEnum!, $value: String!) {
    updateUser(attribute: $attribute, value: $value) {
      Id
    }
  }
`;

export default function ({
  steps,
  onDone,
}: {
  steps: Step[];
  onDone: () => void;
}) {
  const [run, setRun] = useState(false);
  // const [stepIndex, setStepIndex] = useState(0);

  // TODO: wrap each content with a div and style text align left

  useEffect(() => {
    if (!run) {
      const compExists = (id: string) => !!document.querySelector(id);
      for (const step of steps) {
        if (!compExists(step.target as string)) {
          return;
        }
      }
      setRun(true);
    }
  });

  return (
    <Joyride
      // stepIndex={stepIndex}
      steps={steps}
      showSkipButton={true}
      callback={(val) => {
        // if (
        //   ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
        //     val.type
        //   )
        // ) {
        //   console.log(val.type, val.action);
        //   const stepIndex = val.index + (val.action === ACTIONS.PREV ? -1 : 1);
        //   setStepIndex(stepIndex);
        // }
        // console.log(val);
        if (val.status === "finished" || val.status === "skipped") {
          onDone();
        }
      }}
      showProgress
      continuous
      styles={{
        options: {
          zIndex: 99999,
          primaryColor: colors.primary.main,
        },
      }}
      run={run}
    />
  );
}
