import { ReactComponent as AlltingLog } from "../images/raccoon_orange.svg";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { LogoFont } from "./LogoFont";

export default function SplashScreen({ children }: { children?: any }) {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f6f8f9",
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
      }}
    >
      <div style={{ width: 400, marginTop: 100 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            marginBottom: 60,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AlltingLog style={{ width: 60, height: 60 }} />
          <LogoFont>Allting</LogoFont>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
