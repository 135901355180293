import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { colors, phoneWidth } from "../styles";
import ConfirmationDialog from "../components/ConfirmationDialog";
import validateEmail from "../utils/validateEmail";
import { Typography } from "@material-ui/core";
import { UsersInvite } from "../components/UserSearch";
import { gql } from "apollo-boost";
import { useSnack } from "../components/Snack";

export function InviteExternalParticipantsDialog({
  onClose,
  memberEmails,
  open,
  onConfirm,
}: {
  onClose: () => void;
  open: boolean;
  memberEmails: string[];
  onConfirm: (emails: string[]) => void;
}) {
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
  const smallScreen = useMediaQuery(phoneWidth);
  const snack = useSnack();
  return (
    <ConfirmationDialog
      confirmButtonText="Add"
      description="Invite participants by selecting among members of your organization or type the emails of individuals outside of your organization"
      onClose={() => {
        onClose();
        setMembersToAdd([]);
      }}
      onConfirm={() => {
        onConfirm(membersToAdd);
        setMembersToAdd([]);
        console.log("creating snack");
        snack({ message: "Invite sent!" });
      }}
      open={open}
      title="Add Participant"
      disabled={
        membersToAdd.length === 0 ||
        membersToAdd.filter(validateEmail).length < membersToAdd.length
      }
    >
      <div style={{ width: smallScreen ? "100%" : 500 }}>
        {membersToAdd.filter(validateEmail).length < membersToAdd.length && (
          <Typography gutterBottom style={{ color: colors.error.main }}>
            One of the emails are invalid!
          </Typography>
        )}
        <UsersInvite
          chosenUserEmails={memberEmails}
          onChange={setMembersToAdd}
          selectedEmails={membersToAdd}
        />
      </div>
    </ConfirmationDialog>
  );
}

export const inviteExternalParticipants = gql`
  mutation InviteExternalParticipants($meetingId: ID!, $emails: [String!]!) {
    addExternalParticipantsToMeeting(emails: $emails, meetingId: $meetingId)
  }
`;
