import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "react-apollo";
import TeamIcon from "@material-ui/icons/PeopleAlt";
import TagIcon from "@material-ui/icons/LocalOffer";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SoundUploadIcon from "@material-ui/icons/LibraryMusic";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayIcon from "@material-ui/icons/PlayArrow";
import Select from "components/Select";
import { colors } from "../styles";
import { IconButton, TextField } from "@material-ui/core";
import DateTime from "components/DateTime";
import ConfirmationDialog from "../components/ConfirmationDialog";

import firebase, { storage } from "firebase";
import { TopBarContext } from "components/TopBar";

import {
  deleteMeetingMutation,
  scheduledMeetingQuery,
  updateMeeting,
} from "./Meeting/queries";
import {
  ScheduledMeetingQuery,
  ScheduledMeetingQueryVariables,
} from "./Meeting/__generated__/ScheduledMeetingQuery";
import ActionButton from "../components/ActionButton";
import { getUsersTeams } from "./__generated__/getUsersTeams";
import { gql } from "apollo-boost";
import {
  UpdateMeeting,
  UpdateMeetingVariables,
} from "./Meeting/__generated__/UpdateMeeting";
import UploadDialog from "../components/UploadDialog";
import { useAlert } from "../components/Alert";
import useRouter from "../utils/routerHook";
import {
  meetingAttributesEnum,
  StatusEnum,
} from "../__generated__/globalTypes";
import {
  deleteMeeting,
  deleteMeetingVariables,
} from "./Meeting/__generated__/deleteMeeting";
import { meetingsQuery } from "./Meetings/queries";
import {
  MeetingsQuery,
  MeetingsQueryVariables,
} from "./Meetings/__generated__/MeetingsQuery";
import UserChip from "../components/UserChip";
import {
  InviteExternalParticipants,
  InviteExternalParticipantsVariables,
} from "./__generated__/InviteExternalParticipants";
import {
  inviteExternalParticipants,
  InviteExternalParticipantsDialog,
} from "./InviteExternalParticipantsDialog";
import { useSnack } from "../components/Snack";

const Top = styled.div`
  background-color: ${colors.primary.main};
  height: 270px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const selectStyle = { paddingTop: 25, width: 330 };

export default function ScheduledMeeting(props: any) {
  useEffect(
    () =>
      firebase
        .analytics()
        .logEvent("page_view", { page_title: "Schedule Meeting" }),
    []
  );
  const snack = useSnack();
  const meetingId: string = props.match.params.id;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [team, setTeam] = useState<string>("");
  const [tag, setTag] = useState<string[]>([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [actionButton, setActionButton] = useState<boolean>(false);
  const alert = useAlert();
  const [schedule, setSchedule] = useState<number>(new Date().getTime());
  const [inviteParticipantsOpen, setInviteParticipantsOpen] = useState(false);

  const { history } = useRouter();
  const { data, loading, refetch } = useQuery<
    ScheduledMeetingQuery,
    ScheduledMeetingQueryVariables
  >(scheduledMeetingQuery, {
    variables: { id: meetingId },
    fetchPolicy: "cache-and-network",
  });
  const [update] = useMutation<UpdateMeeting, UpdateMeetingVariables>(
    updateMeeting
  );
  const [inviteExternal] = useMutation<
    InviteExternalParticipants,
    InviteExternalParticipantsVariables
  >(inviteExternalParticipants);

  const [deleteMeeting] = useMutation<deleteMeeting, deleteMeetingVariables>(
    deleteMeetingMutation
  );

  const { setOnBackPath } = useContext(TopBarContext);

  console.log(data?.meeting.Status);
  // If status is not correct push to other screen
  useEffect(() => {
    if (data?.meeting.Status === StatusEnum.live) {
      history.replace(`/live/${meetingId}`);
    } else if (
      [StatusEnum.processing, StatusEnum.done].includes(
        data?.meeting?.Status as any
      )
    ) {
      history.replace(`/meeting/${meetingId}`);
    }
  }, [data]);

  useEffect(() => {
    setOnBackPath("/meetings");
    return () => setOnBackPath("");
  }, [setOnBackPath]);

  useEffect(() => {
    setTeam(data?.meeting?.Team?.Id ?? "NA");
    console.log(data?.meeting?.StartTime);
    setTag(data?.meeting?.Tags ?? []);
    setSchedule(data?.meeting?.StartTime ?? new Date().valueOf());
  }, [data]);

  const participantList = data?.meeting?.Participants ?? [];

  function getFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e?.target?.files != null && e?.target?.files[0] != null) {
      const file = e.target.files[0];
      if (!file.type.includes("audio") && !file.type.includes("video")) {
        alert({
          title: "Upload Failed!",
          message: `Only audio files are accepted you uploaded type: ${file.type}`,
        });
      } else {
        setFile(file);
        setUploadFormOpen(true);
      }
    }
  }

  // TODO: add participants to firestore
  async function fileUpload(participants: number) {
    if (file != null) {
      const ref = storage()
        .ref()
        .child(`${meetingId}.${file.name.split(".")[1]}`);
      const uploadTask = ref.put(file);
      setUploadOpen(true);
      setUploadFormOpen(false);
      uploadTask.on(
        "state_changed",
        function (snapshot: {
          bytesTransferred: number;
          totalBytes: number;
          state: any;
        }) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // TODO: fix so we can run download while doing other things maybe set up a store wrapping things that shows upload in the background
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // Handle unsuccessful uploads
          throw error;
        },
        function () {
          setUploadOpen(false);
          update({
            variables: {
              attribute: meetingAttributesEnum.Completed,
              value: "true",
              id: meetingId,
            },
          });
          update({
            variables: {
              attribute: meetingAttributesEnum.Status,
              value: "processing",
              id: meetingId,
            },
          });
          update({
            variables: {
              attribute: meetingAttributesEnum.NumParticipants,
              value: `${participants}`,
              id: meetingId,
            },
          });
          update({
            variables: {
              attribute: meetingAttributesEnum.StartTime,
              value: `${schedule}`,
              id: meetingId,
            },
          });
          update({
            variables: {
              attribute: meetingAttributesEnum.FileUploaded,
              value: true.toString(),
              id: meetingId,
            },
          });

          history.push("/meetings");
        }
      );
    }
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Top>
        <div
          style={{
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          {data?.meeting.Name}
        </div>
        <div
          style={{
            paddingTop: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: 200,
          }}
        >
          Meeting Participants
          <IconButton
            style={{ marginRight: -40 }}
            onClick={() => setInviteParticipantsOpen(true)}
          >
            <EditOutlinedIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingTop: 5,
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {participantList.map((participant) => (
            <div style={{ margin: 3 }}>
              <UserChip
                color={"white"}
                label={participant.UserName}
                src={participant.ImageUrl ?? ""}
              />
            </div>
          ))}
          {(data?.meeting.PendingUsers ?? []).map((email) => (
            <div style={{ margin: 3 }}>
              <UserChip color={"white"} label={email} />
            </div>
          ))}
        </div>
        {(data?.meeting.ExternalParticipants ?? []).length > 0 && (
          <div
            style={{
              paddingTop: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontWeight: 200,
            }}
          >
            External Participants
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingTop: 5,
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {data?.meeting.ExternalParticipants?.map((email) => (
            <div style={{ margin: 3 }}>
              <UserChip color={"white"} label={email} src={""} />
            </div>
          ))}
        </div>
      </Top>
      <SelectWrapper>
        <Select
          style={selectStyle}
          multiple
          onChange={async (event: any) => {
            setTag(event.target.value);
            await update({
              variables: {
                id: meetingId,
                attribute: meetingAttributesEnum.Tags,
                value: JSON.stringify(event.target.value as string[]),
              },
            });
            snack({ message: "Saved tag change" });
          }}
          value={tag}
          label="Meeting Tag"
          options={[
            { label: "Workshop", value: "workshop" },
            { label: "Planning", value: "planning" },
            { label: "Progress check", value: "progress-check" },
            { label: "Idea generation", value: "idea-generation" },
            { label: "Problem Solving", value: "problem-solving" },
            { label: "Decision making", value: "decision-making" },
            { label: "Team alignment", value: "team-alignment" },
            { label: "One on One", value: "one-on-one" },
          ]}
          iconComponent={TagIcon}
        />
        <DateTime
          style={selectStyle}
          label="Schedule"
          value={schedule}
          onChange={async (date) => {
            setSchedule(date?.valueOf() ?? 0);
            await update({
              variables: {
                attribute: meetingAttributesEnum.StartTime,
                value: `${date?.valueOf()}`,
                id: meetingId,
              },
            });
            snack({ message: "Saved time change" });
          }}
        />
      </SelectWrapper>
      <div style={{ position: "absolute", right: 20, bottom: 30 }}>
        <input
          style={{ display: "none" }}
          type="file"
          ref={inputRef}
          onChange={(e) => getFile(e)}
        />
        <ActionButton
          open={actionButton}
          onOpen={() => setActionButton(true)}
          onClose={() => setActionButton(false)}
          actions={[
            {
              name: "Start",
              icon: <PlayIcon />,
              onClick: async () => {
                await update({
                  variables: {
                    attribute: meetingAttributesEnum.Status,
                    value: "live",
                    id: meetingId,
                  },
                });
                await update({
                  variables: {
                    attribute: meetingAttributesEnum.StartTime,
                    value: new Date().getTime().toString(),
                    id: meetingId,
                  },
                });
                await update({
                  variables: {
                    attribute: meetingAttributesEnum.Timezone,
                    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    id: meetingId,
                  },
                });
                await refetch();
                history.push(`/live/${meetingId}`);
              },
            },
            {
              name: "Upload",
              icon: <SoundUploadIcon />,
              onClick: () => {
                inputRef?.current?.click();
              },
            },
            // {
            //   name: "Record",
            //   icon: <MicIcon />,
            //   onClick: () => console.log("Start Recording"),
            // },
            {
              name: "Delete",
              icon: <DeleteIcon />,
              onClick: () => {
                setDeleteOpen(true);
                setActionButton(false);
              },
            },
          ]}
        />
      </div>
      <ConfirmationDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={async () => {
          setDeleteOpen(false);
          const { data: deleteData } = await deleteMeeting({
            variables: { id: meetingId },
            update: (cache) => {
              const existingMeetings = cache.readQuery<
                MeetingsQuery,
                MeetingsQueryVariables
              >({
                query: meetingsQuery,
                variables: {
                  completed: false,
                },
              });
              const newMeetings = existingMeetings?.meeting.filter(
                (m) => m.Id !== meetingId
              );
              cache.writeQuery({
                query: meetingsQuery,
                variables: {
                  completed: false,
                  orgId: data?.meeting.OrgId ?? "",
                },
                data: { meeting: newMeetings },
              });
            },
          });
          if (deleteData?.deleteMeeting) {
            history.push("/meetings");
          }
        }}
        confirmButtonText="Delete"
        description="This action is irreversible are you sure you want to do this?"
        title="Delete Meeting"
      />
      <UploadFileForm
        open={uploadFormOpen}
        onClose={() => {
          setUploadFormOpen(false);
          setFile(null);
        }}
        fileName={file?.name ?? "Error file missing"}
        onConfirm={(participants) => fileUpload(participants)}
        members={data?.meeting.Participants?.length ?? 2}
      />
      <UploadDialog
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        title="Upload in Progress"
        description="Uploading your file please wait"
        progress={progress}
      />
      <InviteExternalParticipantsDialog
        onClose={() => setInviteParticipantsOpen(false)}
        open={inviteParticipantsOpen}
        memberEmails={data?.meeting.Participants?.map((p) => p.Email) ?? []}
        onConfirm={async (emails) => {
          setInviteParticipantsOpen(false);
          await inviteExternal({ variables: { emails, meetingId } });
          snack({ message: "Saved participants update" });
          await refetch();
        }}
      />
    </div>
  );
}

export function UploadFileForm({
  open,
  onClose,
  fileName,
  onConfirm,
  members,
  noParticipantsAsk,
}: {
  open: boolean;
  noParticipantsAsk?: boolean;
  onClose: () => void;
  fileName: string;
  onConfirm: (participants: number) => void;
  members: number;
}) {
  const [participants, setParticipants] = useState(members);
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Upload File"
      description={`uploading file: "${fileName}". Only english speaking meetings supported.`}
      onConfirm={() => onConfirm(participants)}
      confirmButtonText="Upload"
    >
      <div style={{ width: "100%", minWidth: 350 }}>
        {!noParticipantsAsk && (
          <TextField
            style={{ width: "100%" }}
            label="Number of people who spoke during the meeting"
            type="number"
            value={participants}
            onChange={(event) =>
              setParticipants(parseFloat(event.target.value))
            }
          />
        )}
      </div>
    </ConfirmationDialog>
  );
}
