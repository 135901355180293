import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return (
      // @ts-ignore
      <Slide direction="up" ref={ref} {...props} />
    );
  }
);

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  progress: number;
};

export default function UploadDialog(props: Props): JSX.Element {
  const { open, onClose, title, description, progress } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
        <DialogContentText id="alert-dialog-slide-description">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgressWithLabel value={progress} />
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
