import React from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import {
  Typography,
  Icon,
  TableRow,
  TableBody,
  TableHead,
  Table as MuTable,
} from "@material-ui/core";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import { ArrowDropDown, ArrowDropUp, UnfoldMore } from "@material-ui/icons";

const Styles = styled.div`
  width: 100%;
  height: 100%;

  table {
    border-spacing: 0;
    width: 100%;
    tbody {
      tr {
        &:hover {
          cursor: pointer;
          background-color: rgb(247, 247, 247);
        }
      }
    }
  }
`;

const TableHeader = styled.th`
  margin: 0;
  text-align: ${(props: any) => (props.right ? "right" : "left")};
  margin-top: 15px;
  font-size: 16px;
  padding-right: 10px;
`;

const TableDivision = styled.td`
  text-align: ${(props: any) => (props.right ? "right" : "left")};
  margin-right: 20px;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

// TODO: find proper props
type Props = {
  columns: any;
  data: any;
  defaultSorted?: { id: string; desc?: boolean }[];
  onRowClick?: (rowData: any) => void;
};

export default function Table({
  columns,
  data,
  onRowClick,
  defaultSorted,
}: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // @ts-ignore
        sortBy: defaultSorted ?? [],
      },
    },
    useSortBy
  );

  // @ts-ignore
  return (
    <Styles>
      {/* 
        // @ts-ignore */}
      <MuTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{ "&:hover": "{ backgroundColor: black }" } as any}
            >
              {headerGroup.headers.map((column: any, index: number) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <TableHeader
                  key={column.index}
                  right={column.right}
                  sortedDesc={column.isSortedDesc}
                  sortedAsc={column.isSorted && !column.isSortedDesc}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 10,
                      marginTop: 10,
                      paddingLeft: 15,
                    }}
                  >
                    <Typography
                      // variant="caption"
                      style={{
                        color: "#828282",
                        fontSize: 16,
                        fontWeight: 400,
                        width: "100%",
                        display: "flex",
                        justifyContent: column.right
                          ? "flex-end"
                          : "flex-start",
                      }}
                      color="primary"
                    >
                      {column.render("Header")}

                      {column.isSortedDesc ? (
                        <ArrowDropDown />
                      ) : column.isSorted ? (
                        <ArrowDropUp />
                      ) : (
                        <UnfoldMore />
                      )}
                    </Typography>
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {/* 
        // @ts-ignore */}
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                {/* 
                  // @ts-ignore */}
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => (onRowClick ? onRowClick(row.original) : null)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableDivision
                        {...cell.getCellProps()}
                        //@ts-ignore
                        right={cell?.column?.cellRight}
                      >
                        {cell.render("Cell")}
                      </TableDivision>
                    );
                  })}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </MuTable>
    </Styles>
  );
}
