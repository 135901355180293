import React, { useState } from "react";
import { Step } from "react-joyride";
import useGetUser from "../useGetUser";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { phoneWidth } from "../../styles";
import Base, { tutorialCompleteMutation } from "./Base";
import { useMutation } from "react-apollo";

export default function ({ loadingDone }: { loadingDone: boolean }) {
  const { user, loading } = useGetUser();
  const smallScreen = useMediaQuery(phoneWidth);
  const [updateUser] = useMutation(tutorialCompleteMutation);

  const [steps] = useState<Step[]>([
    {
      target: "#meetings",
      content:
        "Here is where you’ll find all your meetings,\n" +
        "past and upcoming. 🤩",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#invite-to-org",
      content: "Click here to invite your colleagues to Allting. 🙆",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#create-meeting",
      content:
        "Time for a new meeting? 💻 Create one here and and invite participants.",
      disableBeacon: true,
      disableOverlayClose: true,
    },
  ]);

  if (
    user == null ||
    user?.IntroTutorialCompleted ||
    smallScreen ||
    !loadingDone
  ) {
    return null;
  }

  return (
    <Base
      steps={steps}
      onDone={() =>
        updateUser({
          variables: { attribute: "IntroTutorialCompleted", value: "true" },
        })
      }
    />
  );
}
