import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import Space from "../../components/Space";
import { gql } from "apollo-boost";
import {
  GlobalInsightsQuery,
  GlobalInsightsQuery_globalInsights,
  GlobalInsightsQuery_globalInsights_Conditions,
} from "./__generated__/GlobalInsightsQuery";
import {
  UpdateGlobalInsightsMutation,
  UpdateGlobalInsightsMutationVariables,
} from "./__generated__/UpdateGlobalInsightsMutation";
import { conditionTypesEnum } from "../../__generated__/globalTypes";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {feedbackQuestionsQuery, globalInsightsQuery} from "../Meeting/queries";
import {FeedbackQuestionsQuery} from "../Meeting/__generated__/feedbackQuestionsQuery";

const mutation = gql`
  mutation UpdateGlobalInsightsMutation($insights: [GlobalInsightInput!]!) {
    updateGlobalInsights(insights: $insights)
  }
`;

export default function InsightsEditor(props: any) {
  const { data, loading, refetch } = useQuery<GlobalInsightsQuery>(
    globalInsightsQuery,
    {}
  );
  const [updateInsights, { loading: mutationLoading }] = useMutation<
    UpdateGlobalInsightsMutation,
    UpdateGlobalInsightsMutationVariables
  >(mutation);
  const { data: questionData, loading: questionLoading, error } = useQuery<FeedbackQuestionsQuery>(
      feedbackQuestionsQuery
  );
  return (
    <div>
      <CollapsibleTable
        questions={questionData?.getFeedbackQuestions.Questions ?? []}
        onUpdate={async (insights) => {
          const insightCopy = JSON.parse(
            JSON.stringify(insights)
          ) as GlobalInsightsQuery_globalInsights[];
          await updateInsights({
            variables: {
              insights: insightCopy.map((val) => {
                delete val.__typename;
                val.Conditions = val.Conditions.map((c) => {
                  delete c.__typename;
                  delete c.Question;
                  return c;
                });
                return val;
              }),
            },
          });
          refetch();
        }}
        rows={data?.globalInsights ?? []}
      />
    </div>
  );
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function EditCoaching({
  coaching,
  onUpdate,
  onCancel,
}: {
  coaching: string[];
  onUpdate: (val: string[]) => void;
  onCancel: () => void;
}) {
  const [coach, setCoach] = useState(coaching);
  return (
    <>
      {coach.map((c, i) => (
        <TableRow key={i}>
          <TableCell>
            <TextField
              multiline
              style={{ width: "100%" }}
              value={c}
              onChange={(event) => {
                const temp = [...coach];
                temp[i] = event.target.value;
                setCoach(temp);
              }}
            />
          </TableCell>
          <TableCell>
            <Button
              color="secondary"
              onClick={() => setCoach(coach.filter((v) => v !== coach[i]))}
            >
              Delete
            </Button>
          </TableCell>
        </TableRow>
      ))}
      <div
        style={{
          marginTop: 20,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="primary"
          style={{ marginLeft: 15 }}
          variant="contained"
          onClick={() => setCoach([...coach, ""])}
        >
          Add Field
        </Button>
        <div>
          <Button
            color="secondary"
            style={{ marginLeft: 15 }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            style={{ marginLeft: 15 }}
            onClick={() => onUpdate(coach)}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

function EditCondition({
  conditions,
  onUpdate,
  onCancel,
  questions,
}: {
  conditions: GlobalInsightsQuery_globalInsights_Conditions[];
  questions: any[];
  onUpdate: (val: GlobalInsightsQuery_globalInsights_Conditions[]) => void;
  onCancel: () => void;
}) {
  const [conds, setConditions] = useState(conditions);
  return (
    <>
      {conds.map((c, i) => (
        <TableRow key={i}>
          <TableCell>
            <Select
              value={c.QuestionId}
              onChange={(event) => {
                const temp = JSON.parse(
                  JSON.stringify(conds)
                ) as GlobalInsightsQuery_globalInsights_Conditions[];
                const id = event.target.value as string;
                temp[i].QuestionId = id;
                temp[i].Question = questions.find((q) => q.Id === id);
                setConditions(temp);
              }}
            >
              {questions.map((q) => (
                <MenuItem value={q.Id}>{q.Name}</MenuItem>
              ))}
              {Object.keys(extraTypes).map((key) => (
                <MenuItem value={key}>{extraTypes[key]}</MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <Select
              value={c.Type}
              onChange={(event) => {
                const temp = [...conds];
                const id = event.target.value as string;
                // @ts-ignore
                temp[i].Type = id;
                setConditions(temp);
              }}
            >
              {Object.keys(conditionTypesEnum).map((key) => (
                <MenuItem value={key}>{key}</MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <TextField
              style={{ width: "100%" }}
              value={c.NormalizedValue}
              type="number"
              onChange={(event) => {
                const temp = [...conds];
                temp[i].NormalizedValue = parseFloat(event.target.value);
                setConditions(temp);
              }}
            />
          </TableCell>
          <TableCell>
            <Button
              color="secondary"
              onClick={() =>
                setConditions(
                  conds.filter((c) => c.QuestionId !== conds[i].QuestionId)
                )
              }
            >
              Delete
            </Button>
          </TableCell>
        </TableRow>
      ))}
      <div
        style={{
          marginTop: 20,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            setConditions([
              ...conds,
              {
                QuestionId: "",
                Question: null,
                NormalizedValue: 0,
                Type: conditionTypesEnum.smaller,
                __typename: "Condition",
              },
            ])
          }
        >
          Add Field
        </Button>
        <div>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() =>
              onUpdate(
                conds.map((val) => {
                  delete val.__typename;
                  return val;
                })
              )
            }
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

function Row(props: {
  row: GlobalInsightsQuery_globalInsights;
  insights: GlobalInsightsQuery_globalInsights[];
  onUpdate: (insights: GlobalInsightsQuery_globalInsights[]) => void;
  questions: any[];
  index: number;
  onDeleteRow: (id: string) => void;
}) {
  const { row, insights, onUpdate, questions, index, onDeleteRow } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [coachEdit, setCoachEdit] = useState(false);
  const [conditionEdit, setConditionEdit] = useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Insight}
        </TableCell>
        <TableCell align="right">
         <span> {row.Coaching.length} coaching tips</span>
        </TableCell>
        <TableCell align="right">
          {row.Conditions.map((val) =>
            val?.Question?.Name ? (
              <>
                {val?.Question?.Name}
                <br />
              </>
            ) : null
          ).filter(Boolean)}
        </TableCell>
        <TableCell>
          <Button color="secondary" onClick={() => onDeleteRow(row.Insight)}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Space height={40} />

              <Button
                color="primary"
                variant="outlined"
                style={{float: "right"}}
                onClick={() => setConditionEdit(true)}
              >
                Edit Conditions
              </Button>
              <Table size="small" aria-label="conditions">
                <TableHead>
                  <TableRow>
                    <TableCell>Condition</TableCell>
                    <TableCell>type</TableCell>
                    <TableCell align="right">percent value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conditionEdit ? (
                    <EditCondition
                      conditions={row.Conditions}
                      questions={questions}
                      onUpdate={(conditions) => {
                        const temp = [...insights];
                        temp[index].Conditions = conditions;
                        onUpdate(temp);
                        setConditionEdit(false);
                      }}
                      onCancel={() => setConditionEdit(false)}
                    />
                  ) : (
                    row.Conditions.map((condition) => (
                      <TableRow key={condition.QuestionId}>
                        <TableCell component="th" scope="row">
                          {condition?.Question?.Name ??
                            extraTypes[condition.QuestionId]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {condition?.Type}
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          {condition?.NormalizedValue}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <Space height={40} />
              <Button
                color="primary"
                variant="outlined"
                style={{float: "right"}}
                onClick={() => setCoachEdit(true)}
              >
                Edit Coachings
              </Button>
              <Table size="small" aria-label="coaching_tips">
                <TableHead>
                  <TableRow>
                    <TableCell>Coaching Tip</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coachEdit ? (
                    <EditCoaching
                      coaching={row.Coaching}
                      onUpdate={(coaching) => {
                        const temp = [...insights];
                        temp[index].Coaching = coaching;
                        onUpdate(temp);
                        setCoachEdit(false);
                      }}
                      onCancel={() => setCoachEdit(false)}
                    />
                  ) : (
                    row.Coaching.map((coachRow) => (
                      <TableRow key={coachRow}>
                        <TableCell component="th" scope="row">
                          {coachRow}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <Space height={60} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  rows,
  onUpdate,
  questions,
}: {
  rows: GlobalInsightsQuery_globalInsights[];
  questions: any[];
  onUpdate: (insights: GlobalInsightsQuery_globalInsights[]) => void;
}) {
  const [addRow, setAddRow] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [insight, setInsight] = useState("");
  const [insightToDeleteId, setInsightToDeleteId] = useState("");
  return (
    <TableContainer component={Paper}>
      <Table style={{ width: 800 }} aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Insight</TableCell>
            <TableCell>Coaching</TableCell>
            <TableCell>Conditions</TableCell>
            <TableCell align="right">Delete Row</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row
              index={i}
              questions={questions}
              key={row.Insight}
              insights={rows}
              onUpdate={onUpdate}
              row={row}
              onDeleteRow={(id) => {
                setInsightToDeleteId(id);
                setDeleteRow(true);
              }}
            />
          ))}
        </TableBody>
      </Table>
      <Button
        style={{ margin: 20 }}
        color="primary"
        variant="contained"
        onClick={() => setAddRow(true)}
      >
        Add Insight
      </Button>
      <ConfirmationDialog
        open={addRow}
        onClose={() => {
          setAddRow(false);
          setInsight("");
        }}
        title="Add Row"
        description=""
        onConfirm={() => {
          // @ts-ignore
          onUpdate([
            ...rows,
            {
              Conditions: [],
              Coaching: [],
              Insight: insight,
            },
          ]);
          setAddRow(false);
          setInsight("");
        }}
        confirmButtonText="Add Insight Row"
      >
        <TextField
          style={{ width: 400 }}
          value={insight}
          multiline
          onChange={(event) => setInsight(event.target.value)}
        />
      </ConfirmationDialog>
      <ConfirmationDialog
        open={deleteRow}
        onClose={() => setDeleteRow(false)}
        title="Delete Insight"
        description="This will remove this insight and all coaching tips in it"
        onConfirm={() => {
          onUpdate(rows.filter((value) => value.Insight !== insightToDeleteId));
          setDeleteRow(false);
          setInsightToDeleteId("");
        }}
        confirmButtonText="Delete"
      />
    </TableContainer>
  );
}

const extraTypes: Record<string, string> = {
  SpeakerDistribution: "Speaker Distribution",
  Hour: "Hour of day",
  NumParticipants: "Number of Participants",
  Length: "Meeting Length in Minutes",
};
