import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { UserProfile, UserProfile_profile } from "./__generated__/UserProfile";

const query = gql`
  query UserProfile {
    profile {
      Id
      UserName
      Email
      IntroTutorialCompleted
      LiveTutorialCompleted
      PostMeetingTutorialCompleted
      Requests {
        Id
        Label
        Type
      }
      Org {
        Id
        LogoUrl
        Name
      }
      Role
      Membership
    }
  }
`;

export default function useGetUser(): {
  user: undefined | UserProfile_profile;
  loading: boolean;
  refetch: any;
} {
  const { data, loading, error, refetch } = useQuery<UserProfile>(query, {
    fetchPolicy: "cache-and-network",
    pollInterval: 45000, // Every 45 seconds
  });
  if (error) throw error;
  return { user: data?.profile, loading, refetch };
}
