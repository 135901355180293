import { useQuery } from "react-apollo";
import { meetingsQuery } from "./queries";

import {
  MeetingsQuery,
  MeetingsQuery_meeting,
} from "./__generated__/MeetingsQuery";
import { StatusEnum } from "../../__generated__/globalTypes";

export function useGetMeetings(
  completed = true,
  nonLive = false
): {
  data: MeetingsQuery_meeting[];
  loading: boolean;
  refetch: () => void;
} {
  const { loading, error, data, refetch } = useQuery<MeetingsQuery>(
    meetingsQuery,
    {
      variables: { completed },
      fetchPolicy: "cache-and-network",
      pollInterval: 10000,
    }
  );
  if (error) {
    console.error("Error in useGetMeetings", error);
  }
  function fetchAgain() {
    refetch({ completed });
  }
  return {
    data:
      data != null
        ? nonLive
          ? data.meeting.filter((value) => value.Status !== StatusEnum.live)
          : data.meeting
        : [],
    loading,
    refetch: fetchAgain,
  };
}
