import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio as RadioComp,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "react-apollo";
import { useHistory, useLocation } from "react-router-dom";
import { SetUpProfile } from "./__generated__/SetUpProfile";
import Space from "../components/Space";
import { ReactComponent as AlltingLog } from "../images/raccoon_orange.svg";
import { LogoFont } from "../components/LogoFont";
import { colors, phoneWidth } from "../styles";
import { useAlert } from "../components/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import firebase from "firebase";
import { UpdateUser, UpdateUserVariables } from "./__generated__/UpdateUser";
import { userAttributesEnum } from "../__generated__/globalTypes";
import LoadingScreen from "../components/LoadingScreen";
import { getProjectId } from "../config";
import { useSnack } from "../components/Snack";

const PROJECT_ID = getProjectId();

const userQuery = gql`
  query SetUpProfile {
    profile {
      Id
      UserName
      Email
      ImageUrl
      Name
      Gender
      Org {
        Id
        LogoUrl
        Name
      }
    }
  }
`;

const userMutation = gql`
  mutation UpdateUser($attribute: userAttributesEnum!, $value: String!) {
    updateUser(attribute: $attribute, value: $value) {
      Id
      UserName
    }
  }
`;

const StyledControl = styled(FormControl)`
  margin-top: 50px;
  width: 250px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${colors.text};
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

export default function NewUser() {
  const { search } = useLocation();

  const { data, refetch, loading: dataLoading } = useQuery<SetUpProfile>(
    userQuery,
    { fetchPolicy: "network-only" }
  );

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(
    userMutation
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [imageUrl, setImageUrl] = useState<null | string>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [tries, setTries] = useState(0);
  const alert = useAlert();
  const history = useHistory();
  const editMode = history.location.pathname === "/edit";
  const snack = useSnack();

  useEffect(() => {
    if (data != null) {
      if (data.profile.Email == null && tries < 5) {
        setTimeout(function () {
          refetch().then(() => setTries(tries + 1));
        }, 1000);
      } else {
        setName(data.profile.Name ?? "");
        setUsername(data.profile.UserName ?? "");
        setImageUrl(data.profile.ImageUrl);
        setGender(data.profile.Gender ?? "");
      }
    } else if (data == null && tries < 5) {
      setTimeout(function () {
        console.log("after 1 sec timeout");
        refetch().then(() => setTries(tries + 1));
      }, 1000);
    }
  }, [data, dataLoading, tries]);

  function getImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (e?.target?.files != null && e?.target?.files[0] != null) {
      const file = e.target.files[0];
      if (!file.type.includes("image")) {
        alert({
          title: "Upload Failed!",
          message: `Only image files are accepted you uploaded type: ${file.type}`,
        });
      } else if (file.size > 200000) {
        alert({
          title: "Upload Failed!",
          message: `File is to large file must be smaller than 200 Kb`,
        });
      } else {
        const bucket = `gs://${PROJECT_ID}-image-upload`;
        const filePath = `${btoa(data?.profile.Email ?? "").slice(0, -1)}.${
          file.name.split(".")[1]
        }`;
        console.log(filePath);
        const ref = firebase.app().storage(bucket).ref().child(filePath);
        const uploadTask = ref.put(file);
        uploadTask.on(
          "state_changed",
          null,
          function (error) {
            //TODO: Handle unsuccessful uploads
            throw error;
          },
          async function () {
            const imageUrl = `https://storage.googleapis.com/${PROJECT_ID}-image-upload/${filePath}`;
            console.log(imageUrl);
            setImageUrl(imageUrl);
            await updateUser({
              variables: {
                attribute: userAttributesEnum.ImageUrl,
                value: imageUrl,
              },
            });
          }
        );
      }
    }
  }

  if (tries >= 5 && data == null) {
    return (
      <div>
        <div style={{ width: "100%", display: "flex", marginTop: 200 }}>
          Something has gone wrong! Please contact support@alltinglabs.com
        </div>
      </div>
    );
  }

  if (loading || data == null || dataLoading || data.profile.Email == null) {
    return (
      <div>
        <LoadingScreen>Setting up your profile</LoadingScreen>
      </div>
    );
  }

  return (
    <div>
      <Space height={40} />
      {!editMode && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AlltingLog style={{ width: 60, height: 60 }} />
          <LogoFont>Allting</LogoFont>
        </div>
      )}
      <form
        onSubmit={async (val) => {
          val.preventDefault();
          if (
            username != "" &&
            name != null &&
            username.length >= 3 &&
            name.length >= 3
          ) {
            setLoading(true);
            await updateUser({
              variables: {
                attribute: userAttributesEnum.UserName,
                value: username,
              },
            });
            await updateUser({
              variables: { attribute: userAttributesEnum.Name, value: name },
            });
            if (gender !== "") {
              await updateUser({
                variables: {
                  attribute: userAttributesEnum.Gender,
                  value: gender,
                },
              });
            }
            editMode
              ? history.push("/profile")
              : history.push(
                  search != null && search !== ""
                    ? search.split("?redirect=")[1]
                    : "/meetings"
                );
            setLoading(false);
            snack({ message: "Updated profile" });
          } else {
            setSubmitFailed(true);
          }
        }}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <StyledControl>
          <Title>Personal Information</Title>
          <Avatar
            style={{
              height: 120,
              width: 120,
              position: "relative",
              margin: "auto",
            }}
            src={imageUrl ?? undefined}
            alt={username}
          />
          <input
            style={{ display: "none" }}
            type="file"
            ref={inputRef}
            onChange={(e) => getImage(e)}
          />
          <Space height={10} />
          <Button color="primary" onClick={() => inputRef?.current?.click()}>
            Change Image
          </Button>
          <Space height={30} />
          <FormLabel required>Full Name</FormLabel>
          <TextField
            required
            error={submitFailed && name.length < 3}
            helperText={
              name.length < 3
                ? "Username needs to be at least 3 characters"
                : ""
            }
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Space height={20} />
          <FormLabel required>Display name</FormLabel>
          <TextField
            error={submitFailed && username.length < 3}
            helperText={
              username.length < 3
                ? "Display name needs to be at least 3 characters"
                : ""
            }
            required
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <Space height={20} />
          <FormLabel>Gender</FormLabel>
          <Space height={10} />
          <RadioButtons
            value={gender}
            onChange={(gender) => setGender(gender)}
          />
        </StyledControl>
        <Space height={40} />
        {editMode && (
          <Button
            style={{ marginBottom: 10, color: colors.error.main }}
            variant="outlined"
            color="inherit"
            onClick={() => history.push("profile")}
          >
            Cancel
          </Button>
        )}
        <Button variant="contained" color="primary" type="submit">
          Complete
        </Button>
        <Space height={20} />
      </form>
    </div>
  );
}

function RadioButtons({
  value,
  onChange,
}: {
  value: string;
  onChange: (gender: string) => void;
}) {
  return (
    <RadioGroup value={value} onChange={(_, val) => onChange(val)}>
      <FormControlLabel
        value="male"
        control={<RadioComp color="primary" />}
        label="Male"
      />
      <FormControlLabel
        value="female"
        control={<RadioComp color="primary" />}
        label="Female"
      />
      <FormControlLabel
        value=""
        control={<RadioComp color="primary" />}
        label="None"
      />
    </RadioGroup>
  );
}
