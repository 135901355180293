import React, { ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {colors, getDarkMode, phoneWidth} from "../styles";
import {currentContext} from "../App";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return (
      // @ts-ignore
      <Slide direction="up" ref={ref} {...props} />
    );
  }
);

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  description: ReactNode;
  children?: ReactNode;
  darkMode?: boolean;
  disabled?: boolean;
};

export default function DialogComp(props: Props): JSX.Element {
  const { open, onClose, title, description, children, darkMode } = props;

  const smallScreen = useMediaQuery(phoneWidth);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={
        darkMode
          ? {
              style: {
                backgroundColor: getDarkMode(currentContext).light,
              },
            }
          : {}
      }
      disableBackdropClick={props.disabled}
      disableEscapeKeyDown={props.disabled}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        style={darkMode ? { color: "white" } : {}}
        id="alert-dialog-slide-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            maxWidth: smallScreen ? 300 : 500,
            color: darkMode ? colors.darkMode.text : colors.text,
          }}
          id="alert-dialog-slide-description"
        >
          {darkMode}
          {description}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button disabled={props.disabled} onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
