import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import styled from "styled-components";
import { colors, phoneWidth } from "styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import TopBar, { TopBarContext } from "./components/TopBar";
import BottomNav from "./components/BottomNav";
import MobileMain from "./views/Meeting";
import ScheduledMeeting from "./views/ScheduledMeeting";
import Team from "./views/Team";
import Teams from "./views/Teams";
import Meetings from "./views/Meetings";
import NewUser from "./views/NewUser";
import Live from "./views/Live/Live";
import TeamsConfig from "./teams/TeamsConfig";
import LogIn from "./views/LogIn";
import GiveFeedback, { ExternalGiveFeedback } from "./views/GiveFeedback";
import Transcription from "./views/Meeting/Transcription";
import Record from "./views/Record";
import CreateMeeting from "./views/CreateMeeting";
import SideNavigation from "./SideNavigation";
import ViewWrapper from "components/Wrappers";
import LoadingScreen from "components/LoadingScreen";
import firebase from "firebase";
import ProfileComp from "./views/Profile";
import { getUrl } from "./config";
import { AlertProvider } from "./components/Alert";
import moment from "moment";
import InsightsEditor from "./views/admin/InsightsEditor";
import NotFound from "./views/NotFound";
import MeetingsIntro from "./views/Intro/MeetingsIntro";
import * as teams from "@microsoft/teams-js";
import { SnackProvider } from "./components/Snack";
import TeamsAuth from "./views/auth/Teams";

const url = getUrl();

const client = new ApolloClient({
  uri: `${url}/graphql`,
  request: async (operation) => {
    const user = firebase.auth().currentUser;
    if (user) {
      // User is signed in.
      const token = await user.getIdToken();
      operation.setContext({
        headers: {
          authorization: token,
        },
      });
    } else {
      // No user is signed in.
      console.log("no user");
    }
  },
});

const theme = createMuiTheme({
  palette: {
    text: {
      primary: colors.text,
    },
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
  },
});

const Background = styled.div`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  background-color: ${colors.background};
`;

export let currentUser: {
  id: string;
  name?: string | null;
  photoUrl?: string | null;
  email: string | null;
  claims?: claims | null;
};

export let currentContext: "teams" | "zoom" | "chrome-ext" | null = null;
export let initializedTeams = false;
export type claims = { accountLevel: number };

export function excludeNavigation(paths: string[]) {
  return (
    paths.includes("external-feedback") ||
    paths.includes("new-user") ||
    paths.includes("teams-meetings") ||
    paths.includes("teams-profile") ||
    paths.includes("teams-auth") ||
    paths.includes("teams-meeting") ||
    paths.includes("live") ||
    paths.join(" ").includes("zoom") ||
    currentContext === "teams"
  );
}

const App: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loadingFirebase, setLoadingFirebase] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const smallScreen = useMediaQuery(phoneWidth);
  const [onBackPath, setOnBackPath] = useState<string>("");
  const autoMinimize = useMediaQuery("(max-width:1430px)");
  const [minimized, setMinimize] = useState(false);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchContext = urlSearchParams.get("context");
    if (searchContext != null) {
      //@ts-ignore
      currentContext = searchContext;
    }
    const paths = window.location.pathname.split("/");
    if (
      paths.includes("teams-meetings") ||
      paths.includes("teams-profile") ||
      paths.includes("teams-auth") ||
      paths.includes("teams-meeting")
    ) {
      currentContext = "teams";
    }
    teams.initialize(() => {
      currentContext = "teams";
      initializedTeams = true;
    });
  }, []);
  useEffect(() => setMinimize(autoMinimize), [autoMinimize]);

  // Waiting for firebase to initialize
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        currentUser = {
          id: user.uid,
          name: user.displayName,
          photoUrl: user.photoURL,
          email: user.email,
        };
        user.getIdTokenResult().then((res) => {
          currentUser.claims = res.claims as claims;
        });

        // User is signed in.
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoadingFirebase(false);
    });
  }, []);
  if (loadingFirebase) return <LoadingScreen />;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const redirectQuery = urlSearchParams.get("redirect");

  return (
    <div className="App">
      <Background />
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <TopBarContext.Provider
                value={{
                  onBackPath,
                  setOnBackPath,
                }}
              >
                <AlertProvider>
                  <SnackProvider>
                    <Switch>
                      <Route path="/login" component={LogIn} />
                      <Route
                        path="/teams-auth"
                        component={() => (
                          <TeamsAuth
                            isLoggedIn={isLoggedIn}
                            redirectUrl={redirectQuery ?? undefined}
                          />
                        )}
                      />
                      <Route
                        path="/teams-config"
                        component={() =>
                          isLoggedIn ? (
                            <TeamsConfig />
                          ) : (
                            <TeamsAuth redirectUrl={"/teams-config"} />
                          )
                        }
                      />
                      <Route
                        path="/teams-meetings"
                        component={(props: any) =>
                          isLoggedIn ? (
                            <Meetings {...props} />
                          ) : (
                            <TeamsAuth redirectUrl={"/teams-meetings"} />
                          )
                        }
                      />
                      <Route
                        path="/teams-profile"
                        component={(props: any) =>
                          isLoggedIn ? (
                            <ProfileComp {...props} />
                          ) : (
                            <TeamsAuth redirectUrl={"/teams-profile"} />
                          )
                        }
                      />
                      <Route
                        render={(props) => (
                          <div>
                            {!excludeNavigation(
                              props.location.pathname.split("/")
                            ) &&
                              !smallScreen && (
                                <SideNavigation
                                  minimized={minimized}
                                  setMinimize={setMinimize}
                                  history={props.history}
                                  path={
                                    props.location.pathname != null &&
                                    props.location.pathname !== "/"
                                      ? props.location.pathname.split("/")[1]
                                      : ""
                                  }
                                />
                              )}
                            {smallScreen && (
                              <>
                                {currentContext !== "teams" && (
                                  <TopBar
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                  />
                                )}
                                {!excludeNavigation(
                                  props.location.pathname?.split("/")
                                ) && (
                                  <BottomNav
                                    path={
                                      props.location.pathname != null &&
                                      props.location.pathname !== "/"
                                        ? props.location.pathname.split("/")[1]
                                        : ""
                                    }
                                    onChange={(newPath) =>
                                      props.history.push(newPath)
                                    }
                                  />
                                )}
                              </>
                            )}
                            <ViewWrapper
                              minimized={minimized}
                              exclude={excludeNavigation(
                                props.location.pathname?.split("/")
                              )}
                            >
                              <Switch>
                                <Route
                                  exact
                                  path="/external-feedback/:encodedReq"
                                  render={(props) => (
                                    <ExternalGiveFeedback {...props} />
                                  )}
                                />
                                {/*<Route*/}
                                {/*  exact*/}
                                {/*  path="/zoom"*/}
                                {/*  render={(props) => <Zoom {...props} />}*/}
                                {/*/>*/}
                                <Route
                                  exact
                                  path="/live/:id"
                                  render={(props) =>
                                    currentContext === "teams" &&
                                    !isLoggedIn ? (
                                      <TeamsAuth />
                                    ) : (
                                      <Live
                                        {...props}
                                        isLoggedIn={isLoggedIn}
                                      />
                                    )
                                  }
                                />
                                {isLoggedIn ? (
                                  <Switch>
                                    <Route
                                      path="/meeting/:id"
                                      component={MobileMain}
                                    />
                                    <Route
                                      path="/transcription/:id"
                                      component={Transcription}
                                    />
                                    <Route
                                      path="/scheduled-meeting/:id"
                                      component={ScheduledMeeting}
                                    />

                                    <Route
                                      path={["/new-user", "/edit"]}
                                      component={NewUser}
                                    />
                                    <Route path="/teams" component={Teams} />
                                    <Route path="/team/:id" component={Team} />
                                    <Route
                                      path="/profile"
                                      component={ProfileComp}
                                    />
                                    <Route
                                      exact
                                      path="/record/:id"
                                      render={(props) => <Record {...props} />}
                                    />
                                    <Route
                                      path="/create-meeting"
                                      component={CreateMeeting}
                                    />
                                    <Route
                                      exact
                                      path="/feedback/:id"
                                      render={(props) => (
                                        <GiveFeedback {...props} />
                                      )}
                                    />
                                    <Route
                                      exact
                                      path="/insight-editor"
                                      render={() => <InsightsEditor />}
                                    />
                                    <Route
                                      exact
                                      path={[
                                        "/meetings",
                                        "/meetings/invite-to-org",
                                        "/meetings/join",
                                      ]}
                                      component={Meetings}
                                    />
                                    <Redirect exact path="/" to="/meetings" />
                                    <Route component={NotFound} />
                                  </Switch>
                                ) : currentContext === "teams" ? (
                                  <Redirect
                                    to={{
                                      pathname: "/teams-auth",
                                      search:
                                        window.location.pathname != null &&
                                        window.location.pathname !==
                                          "/teams-auth"
                                          ? `?redirect=${window.location.pathname}`
                                          : "",
                                    }}
                                  />
                                ) : (
                                  <Redirect
                                    to={{
                                      pathname: "/login",
                                      search:
                                        window.location.pathname != null &&
                                        window.location.pathname !== "/login"
                                          ? `?redirect=${window.location.pathname}`
                                          : "",
                                    }}
                                  />
                                )}
                              </Switch>
                            </ViewWrapper>
                          </div>
                        )}
                      />
                    </Switch>
                  </SnackProvider>
                </AlertProvider>
              </TopBarContext.Provider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
};

export default App;
