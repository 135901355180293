import { getUrl } from "../config";

const url = getUrl();

export async function uploadAudio(
  file: Blob,
  data: {
    meetingId: string;
    count: number;
    userId: string;
    startTime: string;
    participants: number;
    done: boolean;
  }
) {
  console.log(url);
  // Default options are marked with *

  const formData = new FormData();
  formData.append("test", file, "test.wav");
  formData.append("body", JSON.stringify(data));
  return await fetch(`${url}/upload_audio`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    // mode: "cors", // no-cors, *cors, same-origin
    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: "same-origin", // include, *same-origin, omit
    headers: {
      //   "Content-Type": "application/json"
      //   "Content-Type": "multipart/form-data"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData, // body data type must match "Content-Type" header
  }); // parses JSON response into native JavaScript objects
}

export async function uploadFile(
  file: Blob,
  data: {
    meetingId: string;
    userId: string;
  }
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("body", JSON.stringify(data));
  return await fetch(`${url}/upload-file`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    // mode: "cors", // no-cors, *cors, same-origin
    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: "same-origin", // include, *same-origin, omit
    body: formData, // body data type must match "Content-Type" header
  }); // parses JSON response into native JavaScript objects
}
