import { Tooltip } from "@material-ui/core";
import React from "react";

export function TooltipWrapper({
  active,
  children,
  tip,
}: {
  active: boolean;
  children: any;
  tip: string;
}) {
  if (active)
    return (
      <Tooltip placement="top" title={tip}>
        <span>{children}</span>
      </Tooltip>
    );
  return <>{children}</>;
}
