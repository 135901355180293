import React, { ReactNode } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { colors } from "../../styles";
import { FlagEnum } from "../../__generated__/globalTypes";
import Dialog from "../../components/Dialog";
// import ElaborateIcon from "@material-ui/icons/HelpOutline";
// import LikeIcon from "@material-ui/icons/ThumbUp";
import { ReactComponent as LikeIcon } from "images/flags-dark/like.svg";
import { ReactComponent as LikeLight } from "images/flags-light/like.svg";
import { ReactComponent as ElaborateIcon } from "images/flags-dark/elaborate.svg";
import { ReactComponent as ElaborateLight } from "images/flags-light/elaborate.svg";
import { ReactComponent as ParkIcon } from "images/flags-dark/park-discussion.svg";
import { ReactComponent as ParkLight } from "images/flags-light/park-discussion.svg";
import { ReactComponent as MoveOnIcon } from "images/flags-dark/move-on.svg";
import { ReactComponent as MoveOnLight } from "images/flags-light/move-on.svg";
import { ReactComponent as OffTopicIcon } from "images/flags-dark/off-topic.svg";
import { ReactComponent as OffTopicLight } from "images/flags-light/off-topic.svg";
import { ReactComponent as CheckIcon } from "images/flags-dark/decision.svg";
import { ReactComponent as CheckLight } from "images/flags-light/decision.svg";
// import ParkIcon from "@material-ui/icons/PanTool";
// import WrapUpIcon from "@material-ui/icons/Schedule";
// import OffTopicIcon from "@material-ui/icons/Directions";
// import CheckIcon from "@material-ui/icons/Check";

export function GetComponentFromFlag({
  flag,
  onClick,
  darkMode,
}: {
  flag: FlagEnum;
  onClick: () => void;
  darkMode: boolean;
}) {
  switch (flag) {
    case FlagEnum.elaborate:
      return (
        <IconComp
          title="Elaborate"
          icon={<GetFlagIcon darkMode={darkMode} flag={flag} />}
          onClick={() => onClick()}
        />
      );
    case FlagEnum.like:
      return (
        <IconComp
          title="Like"
          icon={<GetFlagIcon darkMode={darkMode} flag={flag} />}
          onClick={() => onClick()}
        />
      );
    case FlagEnum.park_discussion:
      return (
        <IconComp
          title="Park discussion"
          icon={<GetFlagIcon darkMode={darkMode} flag={flag} />}
          onClick={() => onClick()}
        />
      );
    case FlagEnum.move_on:
      return (
        <IconComp
          title="Move on"
          icon={<GetFlagIcon darkMode={darkMode} flag={flag} />}
          onClick={() => onClick()}
        />
      );
    case FlagEnum.off_topic:
      return (
        <IconComp
          title="Off Topic"
          icon={<GetFlagIcon darkMode={darkMode} flag={flag} />}
          onClick={() => onClick()}
        />
      );
    case FlagEnum.decision:
      return (
        <IconComp
          title="Decision"
          icon={<GetFlagIcon darkMode={darkMode} flag={flag} />}
          onClick={() => onClick()}
        />
      );
    case FlagEnum.feedback:
    default:
      throw Error(`missing flag ${flag}`);
  }
}

export function GetFlagIcon({
  flag,
  darkMode,
}: {
  flag: FlagEnum;
  darkMode: boolean;
}) {
  const style = {
    color: darkMode ? colors.darkMode.text : colors.text,
    width: 35,
    height: 30,
  };
  switch (flag) {
    case FlagEnum.elaborate:
      return darkMode ? (
        <ElaborateIcon style={{ ...style, width: 35, marginRight: -5 }} />
      ) : (
        <ElaborateLight style={{ ...style, width: 35, marginRight: -8 }} />
      );
    case FlagEnum.like:
      return darkMode ? (
        <LikeIcon style={style} />
      ) : (
        <LikeLight style={style} />
      );
    case FlagEnum.park_discussion:
      return darkMode ? (
        <ParkIcon style={style} />
      ) : (
        <ParkLight style={style} />
      );
    case FlagEnum.move_on:
      return darkMode ? (
        <MoveOnIcon style={style} />
      ) : (
        <MoveOnLight style={style} />
      );
    case FlagEnum.off_topic:
      return darkMode ? (
        <OffTopicIcon style={style} />
      ) : (
        <OffTopicLight style={style} />
      );
    case FlagEnum.decision:
      return darkMode ? (
        <CheckIcon style={{ ...style, marginRight: -10 }} />
      ) : (
        <CheckLight style={{ ...style, marginRight: -10 }} />
      );
    case FlagEnum.feedback:
    default:
      throw Error(`missing flag ${flag}`);
  }
}

const flagList: FlagEnum[] = [
  FlagEnum.like,
  FlagEnum.elaborate,
  FlagEnum.park_discussion,
  FlagEnum.move_on,
  FlagEnum.off_topic,
  FlagEnum.decision,
];

export function FlagDialog({
  open,
  onClose,
  onUpdate,
}: {
  open: boolean;
  onClose: () => void;
  onUpdate: (flag: FlagEnum) => void;
}) {
  return (
    <Dialog
      darkMode
      open={open}
      onClose={() => onClose()}
      description=""
      title="Flag"
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {flagList.map((f) => (
          <div
            style={{
              flex: "1 0 40%",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <GetComponentFromFlag
              darkMode
              flag={f}
              onClick={() => onUpdate(f)}
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
}

export function IconComp({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IconButton
        style={{ color: colors.darkMode.text }}
        onClick={() => onClick()}
      >
        {icon}
      </IconButton>
      <Typography
        variant="body2"
        style={{
          color: colors.darkMode.text,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </div>
  );
}
