import React, { useEffect, useState } from "react";
import * as teams from "@microsoft/teams-js";
import { getFrontEndUrl } from "../../config";
import { useHistory } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import { currentContext, initializedTeams } from "../../App";
import { Button, Typography } from "@material-ui/core";
import SplashScreen from "../../components/SplashScreen";

export default function TeamsAuth({
  redirectUrl,
  isLoggedIn,
}: {
  redirectUrl?: string;
  isLoggedIn?: boolean;
}) {
  const [loginFailed, setLoginFailed] = useState(true);
  const [retry, setRetry] = useState(0);
  const history = useHistory();
  useEffect(() => {
    if (initializedTeams && currentContext == "teams" && retry > 0) {
      teams.authentication.authenticate({
        url: `${window.location.origin}/login?context=teams"`,
        width: 400,
        height: 800,
        failureCallback: (reason) => {
          console.log("Login failed ", reason);
          setLoginFailed(true);
        },
        successCallback: () =>
          redirectUrl ? history.push(redirectUrl) : history.goBack(),
      });
    }
  }, [currentContext, initializedTeams, retry]);

  useEffect(
    () =>
      isLoggedIn ? history.push(redirectUrl ?? "/teams-meetings") : undefined,
    []
  );
  return (
    <div>
      {loginFailed ? (
        <SplashScreen>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {retry > 0 ? (
              <Typography variant="h5" style={{ marginTop: 20 }}>
                Log in failed. You can always try again or contact support.
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                style={{ marginTop: 20, textAlign: "center", fontSize: 18 }}
              >
                Welcome to our Teams app!
                <br /> You are only a few clicks away from having great meetings
              </Typography>
            )}
            <Button
              style={{ marginTop: 20, width: 100 }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLoginFailed(false);
                setRetry(retry + 1);
              }}
            >
              Sign in
            </Button>
          </div>
        </SplashScreen>
      ) : (
        <LoadingScreen>Connecting your to your teams account</LoadingScreen>
      )}
    </div>
  );
}
