import { useQuery } from "react-apollo";
import {
  hasExternalGivenFeedbackQuery,
  hasGivenFeedbackQuery,
} from "./queries";
import { SpeakersQuery_meeting_Speakers as speakersQuery_meeting_Speakers } from "./__generated__/speakersQuery";
import { hasGivenFeedbackQuery as hasGivenFeedbackQueryType } from "./__generated__/hasGivenFeedbackQuery";
import {
  hasExternalGivenFeedback,
  hasExternalGivenFeedbackVariables,
} from "./__generated__/hasExternalGivenFeedback";

export type UserType = {
  Id: string;
  UserName: string;
  ImageUrl?: string;
  Tag: number;
  _typeName?: string;
};

export function useHasGivenFeedback(meetingId: string) {
  const { data, loading, error, refetch } = useQuery<hasGivenFeedbackQueryType>(
    hasGivenFeedbackQuery,
    {
      fetchPolicy: "network-only",
      variables: { meetingId },
    }
  );
  if (error) throw error;
  return { data: data == null ? null : data.hasGivenFeedback, loading, refetch };
}

export function useHasExternalGivenFeedback(meetingId: string, email: string) {
  const { data, loading, error } = useQuery<
    hasExternalGivenFeedback,
    hasExternalGivenFeedbackVariables
  >(hasExternalGivenFeedbackQuery, {
    fetchPolicy: "network-only",
    variables: { meetingId, email },
  });
  if (error) throw error;
  return { data: data == null ? null : data.hasExternalGivenFeedback, loading };
}

export type UserSpeaker = {
  UserName: string;
  ImageUrl?: string;
  Id: string;
  Tag: number;
  WordsSpoken: number;
};

export function userSpeakersJoin(
  users: UserType[],
  speakers: speakersQuery_meeting_Speakers[]
): UserSpeaker[] {
  return speakers
    .map((speaker) => {
      const user = users.find((el) => speaker.User?.Id === el.Id);
      return {
        Tag: speaker.Tag,
        UserName:
          user != null && user.UserName != null ? user.UserName : speaker.Name,
        ImageUrl: user != null ? user.ImageUrl : undefined,
        Id: user != null && user.Id != null ? user.Id : "",
        WordsSpoken: speaker.WordsSpoken,
      };
    })
    .filter(Boolean);
}

export type FeedbackResponses = {
  Id: string;
  Type: string;
  Description: string;
  Label?: string;
  Results: { name: string; value: any }[];
};