import React from "react";
import { ReactComponent as NotFoundImg } from "../images/raccons/not_found.svg";
import { Button, Typography } from "@material-ui/core";
import { LogoFont } from "../components/LogoFont";
import { useHistory } from "react-router-dom";
import Space from "../components/Space";

export default function NotFound() {
  const history = useHistory();
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 350, marginTop: 100 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            marginBottom: 60,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NotFoundImg style={{ width: 200, height: 200 }} />
          <Space height={20} />
          <LogoFont>Allting</LogoFont>
          <Typography
            style={{
              font: "NexaLightText",
              marginTop: 50,
              marginBottom: 20,
              textAlign: "center",
            }}
          >
            404 the page was not found.
          </Typography>
          <Button
            onClick={() => history.push("/meetings")}
            variant="contained"
            color="primary"
          >
            Back to home
          </Button>
        </div>
      </div>
    </div>
  );
}
