import React from "react";
import { FlagEnum } from "../../__generated__/globalTypes";
import { animated, useSpring } from "react-spring";
import { ReactComponent as AlltingLogo } from "../../images/raccoon_orange.svg";
import { Avatar, Typography } from "@material-ui/core";
import { colors, getDarkMode } from "../../styles";
import styled from "styled-components";
import { GetFlagIcon } from "./Flag";
import { currentContext } from "../../App";

const Item = styled.div<{ context: any }>`
  background-color: ${(props) => getDarkMode(props.context).light};
  border-radius: 8px;
  padding: 5px;
`;

export function LiveItem({
  flag,
  children,
  title,
  time,
  hide,
  logo,
}: {
  name?: string;
  children: React.ReactNode;
  image?: string;
  title: string;
  time: number;
  hide?: boolean;
  logo?: string;
  flag?: FlagEnum;
}) {
  const props = useSpring({ opacity: hide ? 0 : 1 });
  return (
    <animated.div style={props}>
      <Item context={currentContext}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {flag == null ? (
                logo != null ? (
                  <Avatar src={logo} alt="" />
                ) : (
                  <AlltingLogo
                    style={{ width: 40, height: 40, marginLeft: 5 }}
                  />
                )
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <GetFlagIcon darkMode flag={flag} />
                </div>
              )}
              <Typography
                style={{ color: colors.darkMode.text, marginLeft: 5 }}
                variant="subtitle2"
              >
                {title}
              </Typography>
            </div>
            <Typography
              style={{ color: colors.darkMode.text, marginRight: 5 }}
              variant="body2"
            >
              {new Date(time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </div>
        </div>
        <div style={{ margin: 5, marginTop: 10 }}>{children}</div>
      </Item>
    </animated.div>
  );
}
