import styled from "styled-components";
import {colors} from "../styles";

const Top = styled.div`
  background-color: ${colors.primary.main};
  height: 270px;
  width: 100%;
  border-radius: 0 0 10px 10px;
`;

export default Top