import React, { useMemo } from "react";
import Insight from "../../components/Insight";
import { GlobalInsightsQuery_globalInsights } from "../admin/__generated__/GlobalInsightsQuery";
import { Error } from "@material-ui/icons";
import { PostMeetingQuery_postMeeting_Questions } from "./__generated__/postMeetingQuery";
import { conditionTypesEnum } from "../../__generated__/globalTypes";

function getIconPerInsight(insight: string): React.ReactNode {
  switch (insight) {
    default:
      return <Error />;
  }
}

export default function GlobalInsight({
  insight,
  hideText,
}: {
  insight: GlobalInsightsQuery_globalInsights;
  hideText?: boolean;
}) {
  const randIdx = useMemo(
    () => Math.round(Math.random() * (insight.Coaching.length - 1)),
    []
  );
  return (
    <Insight
      title={insight.Insight}
      text={hideText ? "" : insight.Coaching[randIdx]}
      color="info"
      icon={getIconPerInsight(insight.Conditions[0].QuestionId)}
    />
  );
}

function specialFeatures(questionId: string): string {
  switch (questionId) {
    case "Hour":
      return "meeting is held later during the day";
    case "Weekday":
      return "meeting is later in the week";
    case "SpeakerDistribution":
      return "speaker distribution is more even";
    case "NumParticipants":
      return "number of participants are higher";
    case "Length":
      return "the length of the meeting is longer";
    default:
      return questionId;
  }
}

function getMagnitude(magnitude: number): string {
  const mag = Math.abs(magnitude);
  if (mag < 0.3) {
    return "slightly";
  } else if (mag < 0.8) {
    return "moderately";
  }
  return "significantly";
}

export function getTopInsights(
  feedbackSeries: PostMeetingQuery_postMeeting_Questions[],
  globalInsights: GlobalInsightsQuery_globalInsights[]
): GlobalInsightsQuery_globalInsights[] {
  const conditionsDict: {
    QuestionId: string;
    Type: conditionTypesEnum;
    NormalizedValue: number;
    Insight: GlobalInsightsQuery_globalInsights;
    Question: any;
  }[] = [];

  for (const insight of globalInsights) {
    insight.Conditions.forEach((cond) => {
      conditionsDict.push({ ...cond, Insight: insight });
    });
  }

  let topInsights: GlobalInsightsQuery_globalInsights[] = [];
  for (const feedback of feedbackSeries) {
    const val = conditionsDict
      .filter(
        (c) =>
          c.QuestionId === feedback.Id &&
          isValid(c.Type, feedback, c.NormalizedValue)
      )
      .map((value) => value.Insight);
    topInsights = [...topInsights, ...val];
  }
  return topInsights;
}

export function isValid(
  type: conditionTypesEnum,
  feedback: PostMeetingQuery_postMeeting_Questions,
  conditionValue: number
): boolean {
  const normalizedVal =
    (feedback.Scores.reduce((a, b) => a + b + 1, 0) /
      (feedback.Scores?.length ?? 1) /
      2) *
    100;
  switch (type) {
    case conditionTypesEnum.larger:
      return normalizedVal > conditionValue;
    case conditionTypesEnum.smaller:
      return normalizedVal < conditionValue;
    default:
      return false;
  }
}
