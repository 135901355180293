import React from "react";
import { ReactComponent as LogoSvg } from "./images/raccoon_orange.svg";
import { styled, ThemeProvider } from "@material-ui/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import {
  Divider,
  Drawer,
  IconButton,
  Portal,
  Typography,
} from "@material-ui/core";
import { colors } from "styles";
import PlusIcon from "@material-ui/icons/Add";
import MeetingIcon from "@material-ui/icons/Description";
import ProfileIcon from "@material-ui/icons/Person";
import SupportIcon from "@material-ui/icons/ContactSupport";

import useGetUser from "./views/useGetUser";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";
import { TooltipWrapper } from "./components/TooltipWrapper";

const innerTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      paper: colors.primary.dark,
    },
    primary: {
      light: colors.primary.light,
      main: colors.primary.light,
      dark: colors.primary.main,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.light,
      dark: colors.secondary.main,
    },
  },
});

export default function SideNavigation({
  history,
  path,
  minimized,
  setMinimize,
}: {
  history: any;
  minimized: boolean;
  path: string;
  setMinimize: (val: boolean) => void;
}) {
  const selectedId = path;
  const { user } = useGetUser();

  const noOrg = user?.Org?.Id == null || user?.Org?.Id == "";
  const iconStyle = {
    marginRight: minimized ? 30 : 10,
    marginLeft: minimized ? -3 : 10,
    width: 30,
    height: 30,
  };
  return (
    <ThemeProvider theme={innerTheme}>
      <Drawer variant="permanent" anchor="left">
        <div
          style={{
            width: minimized ? 50 : 200,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: 50,
              backgroundColor: colors.primary.dark,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                color: "white",
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LogoSvg
                style={{
                  width: 30,
                  height: 30,
                  marginRight: minimized ? 0 : 7,
                }}
              />
              {minimized ? "" : "Allting"}
            </Typography>
            <MiniControl minimized={minimized} setMinimize={setMinimize} />
          </div>
          {/* <Section
          id="dashboard"
          name="Dashboard"
          selectedId={selectedId}
          icon={<HomeIcon className={classes.iconStyle} />}
          onSelect={(id: string) => {
            setSelectedId(id);
            history.push(`/${id}`);
            onClose();
          }}
        /> */}
          <Section
            id="meetings"
            name="Meetings"
            selectedId={selectedId}
            icon={<MeetingIcon style={iconStyle} />}
            onSelect={(id: string) => {
              history.push(`/${id}`);
            }}
            // subChoices={[
            //   {
            //     id: "meetings/your-meetings",
            //     name: "Your Meetings"
            //   }
            // ]}
          />
          {/* <Section
          id="company"
          name="Company"
          selectedId={selectedId}
          icon={<CompanyIcon className={classes.iconStyle} />}
          onSelect={(id: string) => {
            setSelectedId(id);
            history.push(`/${id}`);
            onClose();
          }}
        /> */}
          {/*<Section*/}
          {/*  id="teams"*/}
          {/*  name="Teams"*/}
          {/*  selectedId={selectedId}*/}
          {/*  disabled={noOrg}*/}
          {/*  icon={<TeamsIcon style={iconStyle} />}*/}
          {/*  onSelect={(id: string) => {*/}
          {/*    history.push(`/${id}`);*/}
          {/*  }}*/}
          {/*/>*/}
          <Section
            id="profile"
            name="Profile"
            selectedId={selectedId}
            icon={<ProfileIcon style={iconStyle} />}
            onSelect={(id: string) => {
              history.push(`/${id}`);
            }}
          />
          <div style={{ marginTop: 100 }}>
            <Divider />

            <Section
              id="invite-to-org"
              name="Add Users"
              disabled={noOrg}
              selectedId={selectedId}
              icon={<PersonAddIcon style={iconStyle} />}
              onSelect={(id: string) => {
                history.push(`/meetings/${id}`);
              }}
            />
            <Section
              id="create-meeting"
              name="New Meeting"
              disabled={noOrg}
              selectedId={selectedId}
              icon={<PlusIcon style={iconStyle} />}
              onSelect={(id: string) => {
                history.push(`/${id}`);
              }}
            />
          </div>
          <div
            style={{
              alignSelf: "flex-end",
              marginTop: 15,
              marginBottom: 15,
              marginLeft: 5,
            }}
          >
            <SectionWrapper
              style={
                {
                  // borderLeft: `5px solid ${colors.primary.dark}`,
                }
              }
              selected={false}
              href="mailto:support@alltinglabs.com"
            >
              <SupportIcon style={iconStyle} />
              <Typography>Support</Typography>
            </SectionWrapper>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

interface SectionWrapperProps {
  selected: boolean;
}

const SectionWrapper = styled(
  ({
    selected,
    ...other
  }: SectionWrapperProps & Omit<ButtonProps, keyof SectionWrapperProps>) => (
    <Button {...other} />
  )
)({
  color: "white",
  width: "100%",
  justifyContent: "flex-start",
  textTransform: "none",
  height: 50,
  borderRadius: 0,
});

interface SectionType {
  id: string;
  name: string;
  icon?: any;
  selectedId: string;
  onSelect: (id: string) => void;
  subChoices?: { id: string; name: string }[];
  disabled?: boolean;
}

function Section(props: SectionType) {
  const { id, name, icon, selectedId, onSelect, subChoices } = props;

  const choices = subChoices ? subChoices.map((val) => val.id) : [];
  const ids = [id, ...choices];
  return (
    <TooltipWrapper
      tip="You need to be a part of an organization to be able to do this."
      active={props.disabled ?? false}
    >
      <div
        id={id}
        style={{
          marginTop: 15,
          marginBottom: 15,
          borderLeft: ids.includes(selectedId)
            ? `5px solid ${colors.secondary.main}`
            : `5px solid ${colors.primary.dark}`,
        }}
      >
        <SectionWrapper
          disabled={props.disabled}
          onClick={() => (props.disabled ? null : onSelect(id))}
          selected={id === selectedId}
        >
          {icon} <Typography>{name}</Typography>
        </SectionWrapper>
        <div>
          {subChoices != null &&
            subChoices.map((el) => (
              <SectionWrapper
                onClick={() => (props.disabled ? null : onSelect(el.id))}
                selected={el.id === selectedId}
                style={{ paddingLeft: 60 }}
              >
                <Typography>{el.name}</Typography>
              </SectionWrapper>
            ))}
        </div>
      </div>
    </TooltipWrapper>
  );
}

function MiniControl({
  minimized,
  setMinimize,
}: {
  minimized: boolean;
  setMinimize: (val: boolean) => void;
}) {
  if (minimized) {
    return (
      <Portal>
        <IconButton
          onClick={() => setMinimize(!minimized)}
          style={{
            position: "fixed",
            left: 40,
            top: 0,
            color: colors.grey.main,
          }}
        >
          <ChevronRight />
        </IconButton>
      </Portal>
    );
  }

  return (
    <IconButton onClick={() => setMinimize(!minimized)}>
      {minimized ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
}
