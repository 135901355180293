import { FlagEnum, VoteResultEnum } from "../../__generated__/globalTypes";
import {
  liveFeedQuery_liveMeeting_LiveFeed,
  liveFeedQuery_liveMeeting_LiveFeed_VoteOptions,
} from "./__generated__/liveFeedQuery";
import React, { useMemo } from "react";
import gql from "graphql-tag";
import { Button, Typography } from "@material-ui/core";
import { colors } from "../../styles";
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts";
import { useMutation } from "react-apollo";
import {
  LiveFlagVote,
  LiveFlagVoteVariables,
} from "./__generated__/LiveFlagVote";
import { currentContext, currentUser } from "../../App";

const voteMutation = gql`
  mutation LiveFlagVote(
    $meetingId: ID!
    $voteResult: VoteResultEnum!
    $itemId: ID!
  ) {
    liveFeedbackFlagVote(
      meetingId: $meetingId
      voteResult: $voteResult
      itemId: $itemId
    )
  }
`;

export function VoteContent({
  itemId,
  voteOptions,
  description,
  meetingId,
}: {
  itemId: string;
  voteOptions: liveFeedQuery_liveMeeting_LiveFeed_VoteOptions[];
  description: string;
  meetingId: string;
}) {
  const [vote, { data }] = useMutation<LiveFlagVote, LiveFlagVoteVariables>(
    voteMutation
  );
  if (data) {
    return null;
  }
  return (
    <div>
      <Typography style={{ color: colors.darkMode.text }} variant="body1">
        {description}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: 20,
        }}
      >
        {voteOptions.map(({ Value, Label }) => (
          <Button
            color={getColor(Value)}
            onClick={() =>
              vote({ variables: { meetingId, itemId, voteResult: Value } })
            }
          >
            {Label}
          </Button>
        ))}
      </div>
    </div>
  );
}

export function VotedContent({
  voteResults,
  description,
  voteOptions,
}: {
  voteResults: VoteResultEnum[];
  voteOptions: liveFeedQuery_liveMeeting_LiveFeed_VoteOptions[];
  description: string;
}) {
  // Counting unique instances of each type hmmm wait do I need to do this??
  const data = useMemo(() => {
    const retData: {
      Count?: number;
      Label: string;
      Value: VoteResultEnum;
    }[] = JSON.parse(JSON.stringify(voteOptions));
    voteResults.forEach((value) => {
      const item = retData.find((v) => value == v.Value);
      if (item == null) {
        throw Error("item not found should not be possible!");
      }
      if (item.Count != null) {
        item.Count += 1;
      } else {
        item.Count = 1;
      }
    });
    return retData;
  }, [voteResults]);
  return (
    <div>
      <Typography style={{ color: colors.darkMode.text }} variant="body1">
        {description}
      </Typography>
      <BarChart
        barCategoryGap={10}
        layout="vertical"
        width={currentContext === "teams" ? 240 : 300}
        height={175}
        data={data}
      >
        <XAxis type="number" tickLine={false} axisLine={false} tick={false} />
        <YAxis
          dataKey="Label"
          type="category"
          stroke="white"
          tickLine={false}
          axisLine={false}
        />
        <Tooltip cursor={{ fill: "transparent" }} />
        <Bar dataKey="Count">
          {voteOptions.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getColorHex(entry.Value)} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
}

export function ItemContent({
  item,
  meetingId,
}: {
  item: liveFeedQuery_liveMeeting_LiveFeed;
  meetingId: string;
}) {
  if (item.Flag == null) {
    return null;
  }
  switch (item.Flag) {
    case FlagEnum.elaborate:
    case FlagEnum.like:
      return (
        <Typography style={{ color: colors.darkMode.text }} variant="body1">
          {item.Description}
        </Typography>
      );
    case FlagEnum.off_topic:
    case FlagEnum.move_on:
    case FlagEnum.decision:
    case FlagEnum.park_discussion:
      if (item.Completed || item.UsersVoted?.includes(currentUser.id)) {
        return (
          <VotedContent
            voteResults={item.VoteResults ?? []}
            voteOptions={item.VoteOptions ?? []}
            description={item.Description ?? ""}
          />
        );
      }
      return (
        <VoteContent
          itemId={item.Id}
          voteOptions={item.VoteOptions ?? []}
          meetingId={meetingId}
          description={item.Description ?? ""}
        />
      );
    default:
      throw Error(`Missng flag type ${item.Flag}`);
  }
}

export function getColor(
  res: VoteResultEnum
): "default" | "secondary" | "primary" {
  switch (res) {
    case VoteResultEnum.absent:
    case VoteResultEnum.dont_know:
      return "default";
    case VoteResultEnum.no:
      return "secondary";
    case VoteResultEnum.yes:
      return "primary";
    default:
      return "default";
  }
}

export function getColorHex(res: string): string {
  switch (res) {
    case VoteResultEnum.absent:
    case VoteResultEnum.dont_know:
      return colors.grey.main;
    case VoteResultEnum.no:
      return colors.error.main;
    case VoteResultEnum.yes:
      return colors.success.main;
    default:
      return colors.grey.main;
  }
}
